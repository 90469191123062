import React from "react";
import {
  CAlert,
  CButton,
  CButtonGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { ModalIdVPaymentUIProps } from "../../models/entity.models";

const ModalIdVPaymentUI = ({
  entityName,
  showModal,
  provider,
  paymentHandler,
  closeModal,
  showError,
}: ModalIdVPaymentUIProps): JSX.Element => {
  return (
    <CModal
      show={showModal}
      onClose={closeModal}
      closeOnBackdrop={false}
      size="lg"
      centered
      backdrop={true}
    >
      <CModalHeader className="bg-primary">
        <CModalTitle>ID Verification Payment For {entityName}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        This payment is being made for the purpose of ID Verification for {entityName} using the
        services provided by {provider}.
      </CModalBody>
      <CModalFooter>
        <CButtonGroup className="float-right">
          <CButton color="primary" onClick={closeModal}>
            Cancel
          </CButton>
          <CButton color="primary" onClick={paymentHandler}>
            Buy
          </CButton>
        </CButtonGroup>
      </CModalFooter>
      {showError && (
        <CAlert color="danger">We had troubles process your payment please try again later</CAlert>
      )}
    </CModal>
  );
};

export default ModalIdVPaymentUI;
