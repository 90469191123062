/* eslint-disable no-unused-vars */
const HandleVendorSolicitorSection = ({ currentFile }) => {
  let solicitorObject = {};
  if (currentFile?.createFile?.fileType === "Purchase") {
    const actingFor = currentFile?.fileOpen?.purchaseActingFor;
    solicitorObject = {
      firmName:
        actingFor === "purchaserOnly"
          ? currentFile?.vendor?.solicitorTable?.[0]?.solicitor?.lawFirm || ""
          : currentFile?.vendor?.solicitor?.lawFirm || "",
      firstName:
        actingFor === "purchaserOnly"
          ? currentFile?.vendor?.solicitorTable?.[0]?.solicitor?.firstName || ""
          : currentFile?.vendor?.solicitor?.firstName || "",
      lastName:
        actingFor === "purchaserOnly"
          ? currentFile?.vendor?.solicitorTable?.[0]?.solicitor?.lastName || ""
          : currentFile?.vendor?.solicitor?.lastName || "",
    };
  }
  return solicitorObject;
};

export default HandleVendorSolicitorSection;
