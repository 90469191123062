/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
import _ from "lodash";
import GenerateFullNameFromPartOfName from "src/reusable/GenerateFullNameFromPartOfName";

import TransformLLCAddressObjectToLCSAddressObject from "./TransformLLCAddressObjectToLCSAddressObject";

const HandleMappingOfGuarantorsSection = ({ LLCData, currentLLCMortgageOfThisTransactionId }) => {
  if (LLCData?.guarantors && Array.isArray(LLCData.guarantors) && !_.isEmpty(LLCData?.guarantors))
    return {
      guarantorsUsed: "yes",
      guarantorsTable: HandleMappingOfGuarantorsTable({
        LLCData,
        currentLLCMortgageOfThisTransactionId,
      }),
    };
  return {
    guarantorsUsed: "no",
  };
};
const HandleMappingOfGuarantorsTable = ({ LLCData, currentLLCMortgageOfThisTransactionId }) => {
  const LLCTransactionId = LLCData?.transactionId || "";
  const lenderCode = LLCData?.lenderCode || "";
  let finalGuarantorsTable = [];
  let currentNewMortgageGuarantors = currentLLCMortgageOfThisTransactionId?.guarantorsTable || [];
  const LLCGuarantorsArray =
    Array.isArray(LLCData?.guarantors) &&
    LLCData?.guarantors?.map((guarantorObject) => {
      let curentGuarantorEntity = currentNewMortgageGuarantors?.find((e) => {
        return (
          e?.LLC?.LLC_ID === guarantorObject?.id &&
          e?.LLC?.LLCTransactionId === LLCData?.transactionId
        );
      });
      return TransformLLCGuarantorToLCSGuarantor({
        guarantorObject,
        LLCTransactionId,
        curentGuarantorEntity,
        lenderCode,
      });
    });
  finalGuarantorsTable = finalGuarantorsTable?.concat(LLCGuarantorsArray) || [];
  return finalGuarantorsTable;
};
const TransformLLCGuarantorToLCSGuarantor = ({
  guarantorObject,
  LLCTransactionId,
  curentGuarantorEntity,
  lenderCode,
}) => {
  const clientType =
    guarantorObject?.guarantorType?.toLowerCase() === "person"
      ? "person"
      : guarantorObject?.guarantorType?.toLowerCase() === "business"
      ? "corporation"
      : "";
  const clientFullName = GenerateFullNameFromPartOfName({
    firstName: guarantorObject?.firstName,
    middleName: guarantorObject?.middleName,
    lastName: guarantorObject?.lastName,
  });
  return {
    ...curentGuarantorEntity,
    clientType: clientType || "",

    firstName: clientType === "person" ? guarantorObject?.firstName || "" : "",
    lastName: clientType === "person" ? guarantorObject?.lastName || "" : "",
    middleName: clientType === "person" ? guarantorObject?.middleName || "" : "",
    name: clientType === "corporation" ? guarantorObject?.companyName || "" : "",
    nameOnFile:
      clientType === "person"
        ? clientFullName || ""
        : clientType === "corporation"
        ? guarantorObject?.companyName || ""
        : "",
    primaryPhoneNumber:
      clientType === "person"
        ? guarantorObject?.phone || ""
        : clientType === "corporation"
        ? guarantorObject?.businessPhone || ""
        : "",
    ...(clientType === "person" && {
      numberFields: [
        {
          numberFieldsAdditionalNumber: guarantorObject?.businessPhone,
          numberFieldsAdditionalNumberExtension: "",
        },
      ],
    }),
    addressInfo: TransformLLCAddressObjectToLCSAddressObject({
      LLCAddressObject: guarantorObject?.address,
    }),

    id: Math.random(),
    contactId: Math.random(),

    LLC: {
      LLCData: true,
      LLCTransactionId,
      LLC_ID: guarantorObject?.id,
      lenderCode: lenderCode || "",
    },
  };
};
export default HandleMappingOfGuarantorsSection;
