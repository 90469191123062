import HnHHandleDate from "src/reusable/HnHHandleDate";
import TransformLCSAddressToLLCAddress from "./TransformLCSAddressToLLCAddress";

/* eslint-disable no-unused-vars */
const HandleFireInsurancePolicySection = ({ currentFile }) => {
  const llcFireCompanyLLCAddress = TransformLCSAddressToLLCAddress({
    address: currentFile?.fireInsurance?.fireInsuranceCompanyComponent?.[0]?.addressInfo,
  });
  const fireInsuranceObject = {
    insuranceCompanyName:
      currentFile?.fireInsurance?.fireInsuranceCompanyComponent?.[0]?.name || "",
    phone:
      currentFile?.fireInsurance?.fireInsuranceCompanyComponent?.[0]?.primaryPhoneNumber?.slice(
        0,
        15,
      ) || "",
    fax: currentFile?.fireInsurance?.fireInsuranceCompanyComponent?.[0]?.faxNumber || "",
    policyNumber: currentFile?.fireInsurance?.insurancePolicyDetailsPolicyNumber || "",
    policyActiveDate: HnHHandleDate(currentFile?.createFile?.closingDate) || "",
    expiryDate: HnHHandleDate(currentFile?.fireInsurance?.insurancePolicyDetailsExpiryDate) || "",
    insuranceAmount: currentFile?.fireInsurance?.insurancePolicyDetailsCoverageAmount || "",
    broker: currentFile?.fireInsurance?.FireInsuranceBrokerageComponent?.[0]?.brokerage?.name || "",
    agentFirstName:
      currentFile?.fireInsurance?.FireInsuranceBrokerageComponent?.[0]?.broker?.firstName || "",
    agentLastName:
      currentFile?.fireInsurance?.FireInsuranceBrokerageComponent?.[0]?.broker?.lastName || "",
    brokerPhone:
      currentFile?.fireInsurance?.FireInsuranceBrokerageComponent?.[0]?.broker?.primaryPhoneNumber?.slice(
        0,
        15,
      ) || "",
    unitNumber: llcFireCompanyLLCAddress?.unitNumber || "",
    streetNumber: llcFireCompanyLLCAddress?.streetNumber || "",
    address: llcFireCompanyLLCAddress?.streetAddress1 || "",
    address2: llcFireCompanyLLCAddress?.streetAddress2 || "",
    city: llcFireCompanyLLCAddress?.city || "",
    province: llcFireCompanyLLCAddress?.province || "",
    postalCode: llcFireCompanyLLCAddress?.postalCode || "",
    country: llcFireCompanyLLCAddress?.country || "",
    guaranteedReplacementCoverage:
      currentFile?.fireInsurance?.insurancePolicyDetailsReplacementCoverage === "yes",
  };
  return fireInsuranceObject;
};
export default HandleFireInsurancePolicySection;
