import { CButton, CCol, CContainer, CFormGroup } from "@coreui/react";

/* error, componentStack, resetErrorBoundary */
export default function ErrorFallback() {
  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer className="justify-content-center">
        <CFormGroup row>
          <CCol md="12">
            <div className="clearfix">
              <h1 className="display-1">Error 404</h1>
            </div>
          </CCol>
          <CCol md="12">
            <div className="clearfix">
              <h4 className="pt-3 display-3">Please click here to refresh the page.</h4>
            </div>
          </CCol>
          <CCol md="12" className="pt-3">
            <CButton onClick={() => window.location.reload()} color="danger" size="lg">
              Refresh page
            </CButton>
          </CCol>
        </CFormGroup>
      </CContainer>
    </div>
  );
}
