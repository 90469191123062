const GenerateListOfGuarantorsCorrespondingToLLCTransactionId = ({
  currentFile,
  LLCTransactionId,
}) => {
  const newMortgageCorrespondingToCurrentLLCTransactionId =
    currentFile?.newMortgagesFile?.newMortgages &&
    currentFile?.newMortgagesFile?.newMortgages?.find(
      (e) => e.LLC?.LLCTransactionId === LLCTransactionId,
    );
  return newMortgageCorrespondingToCurrentLLCTransactionId?.guarantorsTable || [];
};

export default GenerateListOfGuarantorsCorrespondingToLLCTransactionId;
