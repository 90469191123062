import _ from "lodash";
const HandleMappingOfClientUnitsTable = ({ LLCData, currentFile }) => {
  const currentClientUnits = currentFile?.condominiums?.clientUnits || [];
  let filteredClientUnitsTable = currentClientUnits?.filter((e) => _.isEmpty(e?.LLC));

  let newClientUnit = [];
  if (
    LLCData?.properties?.[0]?.condoLevel !== null &&
    LLCData?.properties?.[0]?.condoUnitNumber !== null
  ) {
    let currentClientUnit = currentClientUnits?.find((e) => e?.LLC?.LLCData === true) || {};
    filteredClientUnitsTable = filteredClientUnitsTable.concat({
      ...currentClientUnit,
      levelNo: LLCData?.properties?.[0]?.condoLevel || "",
      type: "Condo unit",
      unitNo: LLCData?.properties?.[0]?.condoUnitNumber || "",
      id: Math.random(),
      LLC: {
        LLCData: true,
        LLCTransactionId: LLCData?.transactionId,
      },
    });
  }
  return filteredClientUnitsTable?.concat(newClientUnit);
};
const HandleMappingOfCondominiumsSection = ({ LLCData, currentFile }) => {
  return {
    ...currentFile?.condominiums,
    clientUnits: HandleMappingOfClientUnitsTable({
      LLCData,
      currentFile,
    }),
    planNumber:
      LLCData?.properties?.[0]?.condoPlanNumber || currentFile?.condominiums?.planNumber || "",
  };
};

export default HandleMappingOfCondominiumsSection;
