import { collection, deleteDoc, doc, getDoc, getDocs } from "firebase/firestore";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFirestore } from "reactfire";

const useCollections = () => {
  const db = useFirestore();
  const lawFirmID = useSelector((state) => state?.old?.lcsUser?.lawFirmID);
  const { id: urlParamFileID } = useParams();

  const [collectionData, setCollectionData] = useState([]);
  const [finishedGettingCollection, setFinishedGettingCollection] = useState([false]);

  const DeleteDocumentFromCollection = async ({
    nameOfCollection,
    locationOfCollection,
    documentId,
  }) => {
    const collectionReference = _getReference({
      nameOfCollection,
      locationOfCollection,
      documentId,
    });
    await deleteDoc(collectionReference).catch((error) => {
      console.error("Error removing document: ", error);
    });
  };
  const GetCollection = async ({ nameOfCollection, locationOfCollection, documentId, fileId }) => {
    setFinishedGettingCollection(false);
    const collectionReference = _getReference({
      nameOfCollection,
      locationOfCollection,
      documentId,
      passedFileId: fileId,
    });
    const data = documentId
      ? (await getDoc(collectionReference)).data()
      : await getDocs(collectionReference).then((quereySnapShot) => {
          return quereySnapShot.docs.map((docRef) => {
            return docRef.data();
          });
        });
    setCollectionData(data);
    setFinishedGettingCollection(true);
    return data;
  };
  const _getReference = ({ nameOfCollection, locationOfCollection, documentId, passedFileId }) => {
    const fileID = urlParamFileID;
    const lawfirmReference = `lawFirm/${lawFirmID}`;
    const fileReference = `files/${passedFileId || fileID}`;

    let reference = "";
    if (locationOfCollection === "global")
      reference = documentId
        ? doc(db, `${nameOfCollection}/${documentId}`)
        : collection(db, `${nameOfCollection}`);
    else if (locationOfCollection === "currentFirm")
      reference = documentId
        ? doc(db, `${lawfirmReference}/${nameOfCollection}/${documentId}`)
        : collection(db, `${lawfirmReference}/${nameOfCollection}`);
    else if (locationOfCollection === "currentFile")
      reference = documentId
        ? doc(db, `${lawfirmReference}/${fileReference}/${nameOfCollection}/${documentId}`)
        : collection(db, `${lawfirmReference}/${fileReference}/${nameOfCollection}`);
    return reference;
  };
  return {
    GetCollection,
    DeleteDocumentFromCollection,
    collectionData,
    finishedGettingCollection,
    setFinishedGettingCollection,
  };
};

export default useCollections;
