const GenerateFullNameFromPartOfName = ({
  firstName,
  middleName,
  lastName,
}) => {
  let fullName = `${firstName} ${
    middleName ? middleName + " " : ""
  }${lastName}`;
  return fullName;
};

export default GenerateFullNameFromPartOfName;
