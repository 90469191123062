import { format } from "date-fns";

const HnHHandleDate = (datePassed, returnType) => {
  let finalDate = "";
  if (datePassed === undefined || datePassed === "" || datePassed === null) {
    return "";
  }
  if (typeof datePassed === "string" && new Date(datePassed)) {
    finalDate = new Date(datePassed);
  } else if (datePassed?.seconds) {
    finalDate = new Date(datePassed?.seconds * 1000);
  } else if (datePassed?._seconds) {
    finalDate = new Date(datePassed?._seconds * 1000);
  } else if (datePassed instanceof Date) {
    finalDate = datePassed;
  } else if (new Date(datePassed)) {
    finalDate = new Date(datePassed);
  }
  let dateToReturn = "";
  if (finalDate !== "") {
    if (returnType === "string") {
      try {
        dateToReturn = format(finalDate, "MMMM d, yyyy");
      } catch (error) {
        dateToReturn = "";
      }
    } else if (returnType === "dashes") {
      try {
        dateToReturn = format(finalDate, "yyyy-MM-dd");
      } catch (error) {
        dateToReturn = "";
      }
    } else {
      dateToReturn = finalDate;
    }
  } else {
    return dateToReturn;
  }
  return dateToReturn;
};

export default HnHHandleDate;
