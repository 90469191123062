import React from "react";
import { CButton, CCol, CRow, CTooltip, CAlert } from "@coreui/react";
import ModalUpdateEntityInfo from "src/features/idv/components/modal-update-entity-info/modal-update-entity-info";
import ModalIdVPayment from "../modal-idv-payment/modal-idv-payment";
import OptionsDropdown from "src/shared/ui/right-sidebar/modular-dropdown-selection";
import { EntityEngagementUIProps } from "src/features/idv/models/entity.models";
import { FaBars, FaPaperPlane, FaPlusCircle } from "react-icons/fa";
const EntityEngagementUI = ({
  showUpdateEntityModal,
  setShowUpdateEntityModal,
  showPaymentModal,
  setShowPaymentModal,
  lawFirmId,
  fileId,
  entity,
  Icon,
  hasEmailAndPhone,
  getDropdownItems,
  expiryDates,
  provider,
  loading,
  setLoading,
  error,
}: EntityEngagementUIProps) => (
  <>
    {showUpdateEntityModal && (
      <ModalUpdateEntityInfo
        setShowModal={setShowUpdateEntityModal}
        entityData={entity}
        showModal={showUpdateEntityModal}
        lawFirmId={lawFirmId}
        fileId={fileId}
      />
    )}
    {showPaymentModal && (
      <ModalIdVPayment
        setShowModal={setShowPaymentModal}
        entityData={entity}
        showModal={showPaymentModal}
        provider={provider}
        setLoading={setLoading}
      />
    )}
    <CCol className="mb-4">
      <CRow className="d-flex justify-content-between align-items-center">
        <div className="d-flex flex-column">
          <div>
            {Icon}
            {entity.name}
          </div>
          <div>{expiryDates(entity)}</div>
        </div>
        <div>
          {hasEmailAndPhone ? (
            <OptionsDropdown
              entity={entity}
              toggleIcon={!entity.reportStatus ? <FaPaperPlane /> : <FaBars />}
              dropdownItems={getDropdownItems(entity)}
              toolTip="Send"
              loading={loading}
            />
          ) : (
            <CTooltip content="Phone number and email address are required" placement="top">
              <CButton
                color="primary"
                onClick={() => setShowUpdateEntityModal(true)}
                className="d-flex align-items-center justify-content-center"
                style={{ width: "2.5rem", height: "2.5rem" }}
              >
                <FaPlusCircle />
              </CButton>
            </CTooltip>
          )}
        </div>
      </CRow>
      {error && (
        <CAlert color="danger" className="mt-2 text-center">
          {error}
        </CAlert>
      )}
    </CCol>
  </>
);

export default EntityEngagementUI;
