import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import useSaveDocumentToCollection from "src/reusable/firestoreHooks/useSaveDocumentToCollection";
import ChecklistBodyUi from "./checklist-body.ui";
import { useSelector } from "react-redux";
import useCollections from "src/reusable/firestoreHooks/useCollections";

interface OldState {
  old: {
    showCheckList?: boolean;
    darkMode?: boolean;
    checkListFileId?: string;
    checkListFileType?: string;
    lcsUser?: {
      lawFirmID?: string;
    };
  };
}

interface CheckListBodyProps {
  showCheckList: boolean | undefined;
}

const ChecklistBody = ({ showCheckList }: CheckListBodyProps): JSX.Element => {
  const {
    GetCollection,
    collectionData: defaultValues,
    finishedGettingCollection,
  } = useCollections();

  const fileType = useSelector((state: OldState) => state?.old?.checkListFileType);
  const fileId = useSelector((state: OldState) => state?.old?.checkListFileId);

  const defaultField = (field: string) => (defaultValues as any)?.[field] || "";

  useEffect(() => {
    if (fileId && finishedGettingCollection) callGetCheckList();
    // eslint-disable-next-line
  }, [fileId, showCheckList]);

  const callGetCheckList = async () => {
    await GetCollection({
      nameOfCollection: "checkList",
      locationOfCollection: "currentFile",
      documentId: "fileClosing",
      fileId,
    });
  };

  const methods = useForm();
  const { watch, reset } = methods;

  useEffect(() => {
    const initialValues: any = {
      dealClosed: defaultField("dealClosed") || "no",
      dealClosedDate: defaultField("dealClosedDate"),
      clientReport: defaultField("clientReport") || "no",
      clientReportDate: defaultField("clientReportDate"),
      undertakings: defaultField("undertakings") || "no",
      undertakingsDate: defaultField("undertakingsDate"),
      fileStillActive: defaultField("fileStillActive") || "yes",
      fileInactiveDate: defaultField("fileInactiveDate"),
      transactionClosed: defaultField("transactionClosed") || "no",
    };

    if (fileType !== "Sale") {
      Object.assign(initialValues, {
        changeOfOwnership: defaultField("changeOfOwnership") || "no",
        changeOfOwnershipDate: defaultField("changeOfOwnershipDate"),
        titleInsurancePolicy: defaultField("titleInsurancePolicy") || "no",
        titleInsurancePolicyDate: defaultField("titleInsurancePolicyDate"),
        lenderReport: defaultField("lenderReport") || "no",
        lenderReportDate: defaultField("lenderReportDate"),
      });
    }

    reset(initialValues);
    //eslint-disable-next-line
  }, [defaultValues, fileType, reset]);

  const theForm = watch();

  const { saveFileToCollection } = useSaveDocumentToCollection("checkList");

  const saveData = async () => {
    await saveFileToCollection({
      formData: theForm,
      currentFileId: fileId,
      documentId: "fileClosing",
      saveToFirm: false,
    });
  };

  useEffect(() => {
    if (showCheckList && fileId && finishedGettingCollection) {
      saveData();
    }
    // eslint-disable-next-line
  }, [theForm]);

  return (
    <>
      {finishedGettingCollection && (
        <ChecklistBodyUi methods={methods} fileType={fileType} saveData={saveData} />
      )}
    </>
  );
};

export default ChecklistBody;
