import * as yup from "yup";

const phonePattern = /^(\(\d{3}\)|\d{3})(-)?\d{3}(-)?\d{4}$/;

export const schema = yup.object().shape({
  email: yup.string().nullable().email("Invalid email format").notRequired(),
  phone: yup
    .string()
    .nullable()
    .test("is-valid-phone", "Must be only digits and at least 8 digits long", (value) => {
      if (!value) return true;
      return phonePattern.test(value) && value.length >= 8;
    })
    .notRequired(),
});
