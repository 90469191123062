import CIcon from "@coreui/icons-react";
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from "@coreui/react";
import Calendar from "../views/dashboard/calendar/Calendar";

function TheHeaderDropdownCalendar() {
  // const itemsCount = 1;
  return (
    <CDropdown inNav className="c-header-nav-item mx-2">
      <CDropdownToggle className="c-header-nav-link" caret>
        <CIcon name="cil-calendar" />
        {/* <CBadge shape="pill" color="danger">
          {itemsCount}
        </CBadge> */}
      </CDropdownToggle>
      <CDropdownMenu style={{ width: "900px" }} placement="bottom-end" className="pt-0">
        <CDropdownItem>
          <Calendar />
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
}

export default TheHeaderDropdownCalendar;
