import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "src/new-folder-structure/shared/config/firebase";

// TODO: fix congnitive complexity linting issue here

export default function FirebaseFirestoreBaseQuery() {
  return async ({ path, isCollection, isDocument, method, data }, { dispatch, getState }) => {
    try {
      if (isCollection) {
        const collectionRef = collection(db, path);
        if (method === "getDocs") {
          const querySnapshot = await getDocs(collectionRef);

          const collectionData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          return { data: collectionData };
        }
        if (method === "addDoc") {
          const documentReference = await addDoc(collectionRef);

          const documentAddedData = {
            id: documentReference.id,
            // ...documentReference.data(),
          };
          return { data: documentAddedData };
        }
        if (method === "query") {
          // TODO: implement
          return { data: null };
        }
      }
      if (isDocument) {
        const documentReference = doc(db, path);
        if (method === "getDoc") {
          const documentSnapshot = await getDoc(documentReference);

          const DocumentData = {
            id: documentSnapshot.id,
            ...documentSnapshot.data(),
          };
          return { data: DocumentData };
        }
        // completely overwrite the entire document data
        if (method === "setDoc") {
          await setDoc(documentReference, data);
          return { data: {} };
        }
        // partially update document data based on the fields passed in data object
        if (method === "updateDoc") {
          await updateDoc(documentReference, data);

          return { data: {} };
        }
        if (method === "delete") {
          await deleteDoc(documentReference);
          return { data: {} };
        }
      }
    } catch (firebaseFirestoreError) {
      return { error: firebaseFirestoreError };
    }
    return {
      error: {
        message:
          "Firestore base query error: none of the conditions met, are you passing firestore base query the correct parameters?",
      },
    };
  };
}
