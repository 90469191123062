import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetLCSFileQuery } from "src/new-folder-structure/entities/lcs-file/api";
import { useEffect } from "react";
import {
  offerAPISlice,
  useLazyGetAllTransactionsLenderChangeStatusesQuery,
} from "src/new-folder-structure/entities/offer/model";
import _ from "lodash";

export const useSendLLCData = () => {
  const location = useLocation();
  const fileID = location.pathname.split("/")[3];
  const [getLenderChangesFlag, { data: lenderChangesFlag, isLoadingLenderChangesFlag }] =
    useLazyGetAllTransactionsLenderChangeStatusesQuery({ fileId: fileID });
  const lawFirmID = useSelector((state) => state.old.lcsUser.lawFirmID);
  const { data: currentLCSFile, isLoading: isLoadingCurrentFile } = useGetLCSFileQuery({
    lawFirmID,
    fileID,
  });
  const [sendLLCData] = offerAPISlice.endpoints.sendLLCData.useLazyQuery();

  useEffect(() => {
    if (fileID)
      getLenderChangesFlag(
        {
          fileID: fileID,
        },
        false,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    (async () => {
      if (
        !_.isString(fileID) ||
        fileID === "" ||
        isLoadingLenderChangesFlag ||
        isLoadingCurrentFile
      )
        return;
      await getLenderChangesFlag({
        fileID: fileID,
      });
      lenderChangesFlag?.lenderChangesExistFlag === false &&
        currentLCSFile?.LLCData?.forEach((LLCOffer) => {
          sendLLCData({ fileID, transactionId: LLCOffer?.LLCTransactionId });
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lenderChangesFlag,
    fileID,
    isLoadingCurrentFile,
    isLoadingLenderChangesFlag,
    lawFirmID,
    location,
    currentLCSFile,
  ]);
};
