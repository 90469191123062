import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { lCSFileAPISlice } from "src/new-folder-structure/entities/lcs-file/api";
import { offerAPISlice } from "src/new-folder-structure/entities/offer/model";
import { offerSlice } from "src/new-folder-structure/entities/offer/model";
import { lLCAPISlice } from "src/new-folder-structure/shared/api/llc";
import { usersAPISlice } from "src/new-folder-structure/entities/users/model/api";
import { LCSFileSlice } from "src/new-folder-structure/entities/lcs-file/api/slice";
import oldReducer from "../../reducers";
import { FctOrderAPISlice } from "src/new-folder-structure/entities/fct-order/model/api";

const rootReducer = combineReducers({
  old: oldReducer,
  [lLCAPISlice.reducerPath]: lLCAPISlice.reducer,
  [offerAPISlice.reducerPath]: offerAPISlice.reducer,
  [lCSFileAPISlice.reducerPath]: lCSFileAPISlice.reducer,
  [usersAPISlice.reducerPath]: usersAPISlice.reducer,
  [FctOrderAPISlice.reducerPath]: FctOrderAPISlice.reducer,
  [LCSFileSlice.name]: LCSFileSlice.reducer,
  [offerSlice.name]: offerSlice.reducer,
});
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    // TODO: refactor to better way to add multiple middlewares
    // TODO: turn on immutable middleware and fix all issues later
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat(
      lLCAPISlice.middleware,
      offerAPISlice.middleware,
      lCSFileAPISlice.middleware,
      usersAPISlice.middleware,
      FctOrderAPISlice.middleware,
    ),
});

// enable listener behavior for the store
setupListeners(store.dispatch);

export default store;
