import React from "react";
import { IdVProviderListProps, ProviderOptions } from "src/features/idv/models/entity.models";
import IdVProviderListUI from "./idv-provider-list.ui";

const IdVProviderList = ({ setProviderSelection }: IdVProviderListProps): JSX.Element => {
  const handleCompanyClick = (company: ProviderOptions) => {
    setProviderSelection(company);
  };

  return (
    <IdVProviderListUI
      companyEnum={Object.values(ProviderOptions)}
      handleCompanyClick={handleCompanyClick}
    />
  );
};

export default IdVProviderList;
