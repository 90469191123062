import React, { useState } from "react";
import ModalIdVPaymentUI from "./modal-idv-payment.ui";
import { ModalIdVPaymentProps } from "src/features/idv/models/entity.models";

const ModalIdVPayment = ({
  entityData,
  provider,
  showModal,
  setShowModal,
  setLoading,
}: ModalIdVPaymentProps): JSX.Element => {
  const [showError, setShowError] = useState("");
  const { name: entityName, id: entityId } = entityData;

  const paymentHandler = () => {
    setLoading(true);
    try {
      console.log("do something", entityId);
    } catch (error) {
      setShowError(
        `We had troubles process your payment please try again later \n {error.message}`,
      );
    }
    setLoading(false);
  };

  return (
    <ModalIdVPaymentUI
      showModal={showModal}
      entityName={entityName}
      provider={provider}
      closeModal={() => setShowModal(false)}
      showError={showError}
      paymentHandler={paymentHandler}
    />
  );
};

export default ModalIdVPayment;
