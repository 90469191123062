import React from "react";
import { CButton, CCol } from "@coreui/react";
import SidebarContainer from "src/shared/ui/right-sidebar/container-sidebar-shared-ui";
import { IdVProviderListUIProps, ProviderOptions } from "src/features/idv/models/entity.models";

const IdVProviderListUI = ({ companyEnum, handleCompanyClick }: IdVProviderListUIProps) => {
  return (
    <SidebarContainer>
      <CCol className="mt-5">
        {companyEnum.map((companyName: ProviderOptions, index: number) => (
          <CCol key={companyName}>
            <CButton
              className="btn btn-lg btn-block"
              color="primary"
              onClick={() => handleCompanyClick(companyName)}
            >
              Verify IDs with {companyName}
            </CButton>
            {index !== companyEnum.length - 1 && <hr />}
          </CCol>
        ))}
      </CCol>
    </SidebarContainer>
  );
};

export default IdVProviderListUI;
