import { createApi } from "@reduxjs/toolkit/query/react";
// need to decouple from offers entity
import {
  lenderCodesAdapter,
  lenderCodesInitialState,
  LLCOffersAdapter,
  LLCOffersInitialState,
} from "src/new-folder-structure/entities/offer/model/entity-adapter";
import { lCSFileAPISlice } from "src/new-folder-structure/entities/lcs-file/api";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../config/firebase";
import { selectCurrentFileID } from "src/new-folder-structure/entities/lcs-file/api/slice";
import { usersAPISlice } from "src/new-folder-structure/entities/users/model/api";

// ================================================================
// extended API slices, another name for api is extendedLLCFunctionsAPISlice
// ================================================================
// Note: I'm using best practices to pass a single param/prop as an object with multiple fields
export const lLCAPISlice = createApi({
  reducerPath: "lLCAPI",
  baseQuery: async ({ functionName, baseModule, data }, { getState, dispatch }) => {
    let _data = data;
    let lcsFile = null;
    const state = getState();
    const currentFileID = selectCurrentFileID(state);
    const lawFirmID = state.old?.lcsUser?.lawFirmID;
    try {
      lcsFile = await dispatch(
        lCSFileAPISlice.endpoints.getLCSFile.initiate({ lawFirmID, fileID: currentFileID }),
      ).unwrap();

      let LLCUserName = null;
      if (lcsFile?.id !== "undefined" && lcsFile?.id !== undefined) {
        if (lcsFile.createFile?.fileType === "Purchase")
          LLCUserName = lcsFile.purchaser?.solicitor?.LLCUserName;
        if (lcsFile.createFile?.fileType === "Mortgage")
          LLCUserName = lcsFile.mortgagor?.solicitor?.LLCUserName;

        const users = await dispatch(
          usersAPISlice.endpoints.getUsersByLawFirmId.initiate({ lawFirmID }),
        ).unwrap();

        const lawyerOnFileUserID = users?.find((user) => user?.LLCUserName === LLCUserName)?.id;

        _data = lawyerOnFileUserID ? { ...data, lawyerOnFileUserID } : data;
      }
    } catch (e) {
      console.error("lLCAPISlice> failed to getLCSFile");
    }

    const functionNameTR = baseModule ? `${baseModule}-${functionName}` : functionName;

    const httpCallableFunction = httpsCallable(functions, functionNameTR);

    try {
      const response = await httpCallableFunction(_data);
      return { data: response.data };
    } catch (error) {
      // the error structure is following
      // { code, name}
      return { error };
    }
  },
  tagTypes: [
    "lenderChanges",
    "transactionStatus",
    "LLCLookupTables",
    "LLCOffers",
    "LenderChangeFlag",
  ],
  endpoints: (builder) => ({
    getLLCLookupTables: builder.query({
      providerTag: "LLCLookupTables",
      query: () => ({ functionName: "ReturnsPlatformMetadata", baseModule: "llc" }),
      transformResponse: (response) =>
        lenderCodesAdapter.setAll(lenderCodesInitialState, response.financialInstitutions),
    }),
    getReturnsPlatformMetadata: builder.query({
      query: () => ({ functionName: "ReturnsPlatformMetadata", baseModule: "llc" }),
    }),
    getLLCOffers: builder.query({
      providesTags: ["LLCOffers"],
      query: () => ({ functionName: "GetNewMortgageInstructions", baseModule: "llc" }),
      transformResponse: (response) =>
        LLCOffersAdapter.setAll(LLCOffersInitialState, response.newTransactions),
    }),
    getLenderChanges: builder.query({
      providesTags: ["lenderChanges"],
      query: ({ transactionId }) => ({
        functionName: "GetLenderChanges",
        baseModule: "llc",
        data: { transactionId },
      }),
      transformResponse: (response) => {
        return {
          lenderRequestId: response.lenderRequestId.toString(),
          lenderChanges: response.lenderChanges,
        };
      },
    }),
    getTransactionStatus: builder.query({
      // providerTag: "transactionStatus",
      invalidatesTags: ["LenderChangeFlag"],
      query: ({ transactionId }) => ({
        functionName: "GetTransactionStatus",
        baseModule: "llc",
        data: { transactionId },
      }),
    }),
    getTransactionData: builder.query({
      query: ({ transactionId, lawyerOnFileUserID }) => ({
        functionName: "GetTransactionInfo",
        baseModule: "llc",
        data: { transactionId, lawyerOnFileUserID },
      }),
    }),
    acceptOffer: builder.mutation({
      query: ({ transactionId, lawyerOnFileUserID }) => ({
        functionName: "UpdatedTransactionStatus",
        baseModule: "llc",
        data: { transactionId, status: "ACTIVE", lawyerOnFileUserID },
      }),
    }),
    declineOffer: builder.mutation({
      invalidatesTags: ["LLCOffers"],
      query: ({ transactionId, declineReason, lawyerOnFileUserID }) => ({
        functionName: "UpdatedTransactionStatus",
        baseModule: "llc",
        data: { transactionId, status: "DECLINED", declineReason, lawyerOnFileUserID },
      }),
    }),
    getLLCPortalDealURL: builder.query({
      query: ({ fctUrn, isReadOnly }) => ({
        functionName: "GetLLCUrl",
        baseModule: "llc",
        data: {
          fctUrn,
          language: "ENGLISH",
          context: isReadOnly ? "LLCDEALRO" : "LLCDEAL",
        },
      }),
    }),
    getLLCProfileUrl: builder.query({
      query: ({ lawyerOnFileUserID }) => ({
        functionName: "GetLLCUrl",
        baseModule: "llc",
        data: {
          fctUrn: " ",
          language: "ENGLISH",
          context: "USERPROFILE",
          lawyerOnFileUserID,
        },
      }),
    }),
    updateTransactionData: builder.mutation({
      query: ({ transactionData }) => ({
        functionName: "UpdateTransactionData",
        baseModule: "llc",
        data: { ...transactionData },
      }),
    }),
    authenticateLLCUser: builder.query({
      query: ({ username, password, userID }) => ({
        functionName: "AuthenticateUser",
        baseModule: "llc",
        data: { username, password },
      }),
    }),
    updateLenderChanges: builder.mutation({
      invalidatesTags: ["lenderChanges", "LenderChangeFlag"],
      query: (data) => ({
        functionName: "UpdateLenderChanges",
        baseModule: "llc",
        data,
      }),
      transformResponse: (response) => {
        return response;
      },
    }),
    // TODO: remove in production
    addTestTransactionAsLender: builder.query({
      query: ({ transactionData }) => ({
        functionName: "addNewMortgageTransaction",
        baseModule: "llc",
        data: { ...transactionData },
      }),
    }),
    getDocumentList: builder.query({
      query: ({ transactionId }) => ({
        functionName: "GetTransactionDocuments",
        baseModule: "llc",
        data: { transactionId },
      }),
    }),
    getSingleDocument: builder.query({
      query: ({ transactionId, documentId }) => ({
        functionName: "GetTransactionDocumentDetail",
        baseModule: "llc",
        data: { transactionId, documentId },
      }),
    }),
    getFCTUserProfile: builder.query({
      query: () => ({
        functionName: "GetFCTPortalURL",
        baseModule: "llc",
        data: {
          dealUrn: " ",
          context: "USERPROFILE",
          language: "english",
        },
      }),
    }),
    getAllTransactionsLenderChangeStatuses: builder.query({
      providesTags: ["LenderChangeFlag"],
      queryFn: async ({ fileID }, { getState, dispatch }) => {
        try {
          const state = getState();
          const fileData = await dispatch(
            lCSFileAPISlice.endpoints.getLCSFile.initiate({
              lawFirmID: state.old.lcsUser.lawFirmID,
              fileID: fileID,
            }),
          ).unwrap();
          const listOfTransactionIds = fileData?.LLCData?.map((e) => {
            return e?.LLCTransactionId;
          });

          const listOfPromises = listOfTransactionIds?.map((e) => {
            return dispatch(
              lLCAPISlice.endpoints.getLenderChanges.initiate(
                { transactionId: e },
                { forceRefetch: true },
              ),
            ).unwrap();
          });

          const listOfLenderChanges = await Promise.all(listOfPromises);
          const lenderChangesExistFlag = listOfLenderChanges?.some(
            (e) => Array.isArray(e?.lenderChanges) && e?.lenderChanges?.length > 0,
          );
          const listOfPromisesForStatus = listOfTransactionIds?.map((e) => {
            return dispatch(
              lLCAPISlice.endpoints.getTransactionStatus.initiate({
                transactionId: e,
              }),
            ).unwrap();
          });
          const listOfStatusChanges = await Promise.all(listOfPromisesForStatus);
          const lenderNoteExistFlag = listOfStatusChanges?.some((e) => e?.lenderNotes === true);
          let flagsToReturn = { lenderChangesExistFlag, lenderNoteExistFlag };

          return { data: flagsToReturn };
        } catch (error) {
          console.error("getAllTransactionsLenderChangeStatuses: ", error);
          return { error };
        }
      },
    }),
  }),
});

export const { useGetFCTUserProfileQuery } = lLCAPISlice;
