import _ from "lodash";
import GenerateFullNameFromPartOfName from "src/reusable/GenerateFullNameFromPartOfName";
import HnHHandleDate from "src/reusable/HnHHandleDate";
import getMortgagorId from "./getMortgagorId";

import HandleIdentificationsSection from "./HandleIdentificationSection";

const GenerateArrayOfLLCAttorneyObjectsFromLCSPowerOfAttorneyEntity = ({
  entity,
  LLCTransactionId,
}) => {
  if (_.isEmpty(entity)) return [];
  const finalArrayOfLLCAttorneyObjects = entity?.attorney?.map((attorney) => {
    const fullName = GenerateFullNameFromPartOfName({
      firstName: attorney?.firstName || "",
      middleName: attorney?.middleName || "",
      lastName: attorney?.lastName || "",
    });
    const birthDate = HnHHandleDate(attorney?.dateOfBirth, "dashes");
    const mortgagorId = getMortgagorId({ mortgagor: entity, LLCTransactionId: LLCTransactionId });

    return {
      mortgagorId: mortgagorId,
      fullName: fullName || "",
      birthdate: birthDate || null,
      occupation: attorney?.idVerification?.occupation || null,
      identifications: HandleIdentificationsSection({
        fullName,
        birthDate,
        idVerificationSection: attorney?.idVerification,
      }),
    };
  });
  return finalArrayOfLLCAttorneyObjects || [];
};

export default GenerateArrayOfLLCAttorneyObjectsFromLCSPowerOfAttorneyEntity;
