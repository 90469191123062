/* eslint-disable no-nested-ternary */
import HnHHandleDate from "src/reusable/HnHHandleDate";

import GetMortgagePriority from "./GetMortgagePriority";
import HandleExistingMortgagesSection from "./HandleExistingMortgagesSection";
import HandleFireInsurancePolicySection from "./HandleFireInsurancePolicySection";
import HandleLegalDescription from "./HandleLegalDescription";
import HandleNumberOfUnits from "./HandleNumberOfUnits";
import HandlePropertyIdentificationNumbers from "./HandlePropertyIdentificationNumbers";
import HandleRegistrationOffice from "./HandleRegistrationOffice";
import TransformLCSAddressToLLCAddress from "./TransformLCSAddressToLLCAddress";

/* eslint-disable no-unused-vars */
const HandleMappingOfPropertiesSection = ({ currentFile, LLCTransactionId, LLCPropertyId }) => {
  const LLCMortgage = currentFile?.newMortgagesFile?.newMortgages?.find(
    (mortgage) => mortgage?.LLC?.LLCTransactionId === LLCTransactionId,
  );

  return [
    {
      id: LLCPropertyId,
      legalDescription: HandleLegalDescription({ currentFile }) || null,
      condoLevel:
        currentFile?.condominiums?.clientUnits?.find((unit) => unit?.LLC?.LLCData === true)
          ?.levelNo || null,
      condoUnitNumber:
        currentFile?.condominiums?.clientUnits?.find((unit) => unit?.LLC?.LLCData === true)
          ?.unitNo || null,
      occupancyType:
        currentFile?.property?.propertyRentalProperty === "yes" ? "RENTAL" : "OWNER OCCUPIED",
      numberOfUnits: HandleNumberOfUnits({ currentFile }) || null,
      condoPlanNumber: currentFile?.condominiums?.planNumber || null,
      mortgagePriority: GetMortgagePriority({ currentFile, LLCMortgage }),

      lenderCollectsPropertyTaxes: LLCMortgage?.propertyTaxesPaidThroughMortgage === "yes",
      estateType:
        currentFile?.property?.interestInLand === "feeSimple"
          ? "FEE SIMPLE"
          : currentFile?.property?.interestInLand === "leasehold"
          ? "LEASEHOLD"
          : currentFile?.property?.interestInLand === "freehold"
          ? "FREEHOLD"
          : currentFile?.property?.interestInLand === "other"
          ? "OTHER"
          : null,
      otherEstateTypeDescription: currentFile?.property?.interestInLandDescription || "",
      isCondominium: currentFile?.property?.propertyPurchaseType === "condominium",
      newHomeWarranty: currentFile?.property?.newBuild === "yes",
      newConstruction:
        currentFile?.property?.newBuild === "yes" ||
        currentFile?.property?.propertyHasBeenRenovated === "yes",
      municipality: currentFile?.Municipality?.municipality?.label,

      instrumentNumber: LLCMortgage?.registrationNumber || null,
      registrationDate: HnHHandleDate(LLCMortgage?.mortgageRegistrationDate, "dashes") || null,
      registryOffice: HandleRegistrationOffice({ currentFile }),
      lroNumber: HandleRegistrationOffice({ currentFile, returnNumber: true }),
      address: TransformLCSAddressToLLCAddress({
        address: {
          AddressLine1: currentFile?.createFile?.fileAddressLine1?.trim() || "",
          AddressLine2: currentFile?.createFile?.fileAddressLine2?.trim() || "",
          Province: "ON",
          PostalCode: currentFile?.createFile?.filePostalCode?.trim() || "",
          Country: currentFile?.createFile?.fileCountry?.trim() || "",
          City: currentFile?.createFile?.fileCity?.trim() || "",
          UnitNumber: currentFile?.createFile?.fileUnitNumber?.trim() || "",
        },
      }),
      propertyIdentificationNumbers: HandlePropertyIdentificationNumbers({ currentFile }) || null,
      existingMortgages: HandleExistingMortgagesSection({ currentFile, LLCTransactionId }) || null,

      assessmentRollNumbers: currentFile?.property?.rollNumber
        ? [currentFile?.property?.rollNumber]
        : null,
      fireInsurancePolicy: HandleFireInsurancePolicySection({ currentFile }) || null,
    },
  ];
};

export default HandleMappingOfPropertiesSection;
