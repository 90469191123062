import React, { ComponentType } from "react";
import CIcon from "@coreui/icons-react";
import {
  CNav,
  CNavItem,
  CNavLink,
  CSidebar,
  CSidebarClose,
  CTabContent,
  CTabPane,
  CTabs,
} from "@coreui/react";
import { FaShieldAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import IdVFlowController from "../features/idv/components/idv-flow-controller/idv-flow-controller";
import ChecklistBody from "src/features/check-list/checklist-body/checklist-body";

interface OldState {
  old: {
    showCheckList?: boolean;
    darkMode?: boolean;
    checkListFileId?: string;
    checkListFileType?: string;
    lcsUser?: {
      lawFirmID?: string;
    };
  };
}

type TabItemProps = {
  IconName: string | ComponentType<{ color?: string }>;
  darkMode?: boolean;
  tabName: string;
  iconType?: string;
};

const TabItem = ({ IconName, darkMode, tabName, iconType }: TabItemProps) => (
  <CNavItem>
    <CNavLink data-tab={tabName}>
      {iconType === "ci" && typeof IconName === "string" ? (
        <CIcon name={IconName} style={darkMode ? { color: "white" } : {}} />
      ) : (
        <IconName color={darkMode ? "white" : undefined} />
      )}
    </CNavLink>
  </CNavItem>
);

function TheRightSidebar() {
  const dispatch = useDispatch();
  const setState = (state: boolean) => dispatch({ type: "set", payload: { showCheckList: state } });
  const showCheckList = useSelector((state: OldState) => state?.old?.showCheckList);
  const darkMode = useSelector((state: OldState) => state?.old?.darkMode);

  return (
    <CSidebar
      aside
      colorScheme="light"
      size="xl"
      show={showCheckList}
      onShowChange={(state: boolean) => setState(state)}
    >
      <CSidebarClose onClick={() => setState(false)} />
      <CTabs activeTab="checkList">
        <CNav variant="tabs" className="nav-underline nav-underline-primary">
          <TabItem IconName="cil-list" darkMode={darkMode} tabName="checkList" iconType="ci" />
          <TabItem IconName={FaShieldAlt} darkMode={darkMode} tabName="verifySystem" />
        </CNav>
        <CTabContent>
          <CTabPane data-tab="checkList">
            <ChecklistBody showCheckList={showCheckList} />
          </CTabPane>
          <CTabPane data-tab="verifySystem">
            <IdVFlowController />
          </CTabPane>
        </CTabContent>
      </CTabs>
      <hr />
    </CSidebar>
  );
}

export default React.memo(TheRightSidebar);
