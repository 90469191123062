import _ from "lodash";

const HandleRegistrationOffice = ({ currentFile, returnNumber = false }) => {
  const performingTitleSearch = currentFile?.fileOpen?.performingTitleSearch;
  let registrationOffice = "";
  if (performingTitleSearch === "yes" && currentFile?.TeranetAPIData) {
    let listOfTeranetData = Object.values(currentFile?.TeranetAPIData);
    listOfTeranetData = _.flatten(listOfTeranetData);
    let primaryPin = listOfTeranetData?.find((e) => e?.isPrimaryPin === true);
    if (returnNumber) {
      registrationOffice = isNaN(primaryPin?.landRegistrationOffice)
        ? Number(primaryPin?.landRegistrationOffice?.replace(/\D+/g, ""))
        : primaryPin?.landRegistrationOffice;
    } else {
      registrationOffice = primaryPin?.landRegistrationOffice;
    }
  } else {
    registrationOffice = currentFile?.ManualTitleSearches?.landRegistryOfficeNumber;
  }
  return registrationOffice;
};

export default HandleRegistrationOffice;
