import _ from "lodash";
import GenerateFullNameFromPartOfName from "src/reusable/GenerateFullNameFromPartOfName";
import HnHHandleDate from "src/reusable/HnHHandleDate";

import HandleIdentificationsSection from "./HandleIdentificationSection";

const HandleSignatoriesSection = ({ entity }) => {
  if (_.isEmpty(entity?.signingOfficer)) return [];
  const signatoriesArray =
    entity?.signingOfficer?.map((officer) => {
      const fullName =
        GenerateFullNameFromPartOfName({
          firstName: officer?.firstName,
          middleName: officer?.middleName,
          lastName: officer?.lastName,
        }) || "";
      const returnValueOfHandleIdentification =
        HandleIdentificationsSection({
          fullName,
          birthDate: officer?.birthDate,
          idVerificationSection: officer?.idVerification,
        }) || null;
      return {
        firstName: officer?.firstName || null,
        middleName: officer?.middleName || null,
        lastName: officer?.lastName || null,
        birthdate: HnHHandleDate(officer?.birthDate, "dashes") || null,
        occupation: officer?.title || null,
        identifications: returnValueOfHandleIdentification || null,
      };
    }) || [];
  return signatoriesArray;
};

export default HandleSignatoriesSection;
