import React from "react";
import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import "./polyfill";

import { icons } from "./assets/icons";
import * as serviceWorker from "./serviceWorker";
import App from "src/app";

// TODO: investigate this ts error
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
React.icons = icons;

ReactDOM.render(React.createElement(App), document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
