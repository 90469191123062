import { CCard, CCardBody, CCol, CContainer, CProgress, CProgressBar, CRow } from "@coreui/react";
import PropTypes from "prop-types";

export default function LoadingSpinner({ displayInCard, loadingText, loadingProgress }) {
  return (
    <div className="d-flex justify-content-center flex-column" style={{ height: "100vh" }}>
      {displayInCard ? (
        <CCard className="align-self-center">
          <CCardBody className="d-flex justify-content-center flex-column">
            <div className="spinner-border text-primary align-self-center" role="status">
              <span className="sr-only">{loadingText || "loading..."}</span>
            </div>
            <div className="align-self-center">{loadingText}</div>
          </CCardBody>
        </CCard>
      ) : (
        <>
          <div className="spinner-border text-primary align-self-center" role="status">
            <span className="sr-only">{loadingText || "loading..."}</span>
          </div>
          <div className="align-self-center">{loadingText}</div>
        </>
      )}
      {loadingProgress ? (
        <CContainer>
          <CRow>
            <CCol className="col-md-6 offset-md-3 col-xs-10 offset-xs-1 col-sm-8 offset-sm-2">
              {/* <CCard className="flex-fill">
              <CCardBody> */}
              <CProgress>
                <CProgressBar value={loadingProgress} />
              </CProgress>
              {/* </CCardBody>
            </CCard> */}
            </CCol>
          </CRow>
        </CContainer>
      ) : (
        ""
      )}
    </div>
  );
}

LoadingSpinner.propTypes = {
  displayInCard: PropTypes.bool,
  loadingText: PropTypes.string,
  loadingProgress: PropTypes.number,
};

LoadingSpinner.defaultProps = {
  displayInCard: false,
  loadingText: "",
  loadingProgress: 0,
};
