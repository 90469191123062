/* eslint-disable no-unused-vars */

import currency from "currency.js";
import _ from "lodash";

const HandleExistingMortgagesSection = ({ currentFile, LLCTransactionId }) => {
  if (_.isEmpty(currentFile) || !LLCTransactionId) return [];
  let existingMortgages = [];
  const existingEncumbrancesThatMatchLLCTransactionId =
    currentFile?.existingEncumbrances?.encumbrances?.filter(
      (encumbrance) => encumbrance?.LLC?.LLCTransactionId === LLCTransactionId,
    );
  if (
    Array.isArray(existingEncumbrancesThatMatchLLCTransactionId) &&
    existingEncumbrancesThatMatchLLCTransactionId.length > 0
  ) {
    existingMortgages = existingEncumbrancesThatMatchLLCTransactionId?.map((mortgage) => {
      return {
        amount: currency(mortgage?.amountDueForPayout).value || null,
        action: mortgage?.encumbranceIsTo === "be discharged" ? "DISCHARGE" : "REMAIN IN PRIORITY",
        mortgagee:
          mortgage?.encumbrancesTable?.find((e) => e?.LLC?.LLCTransactionId === LLCTransactionId)
            ?.name || null,
      };
    });
  }
  return existingMortgages;
};

export default HandleExistingMortgagesSection;
