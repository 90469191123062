import { httpsCallable } from "firebase/functions";
import { functions } from "src/new-folder-structure/shared/config/firebase";

// this is a factory function that returns a paramtized function
/*
 * @param {baseModule} The base module in our backend (functions) to collect all endpoints or serverless functions under an alias
 */
export default function FirebaseFunctionsBaseQuery() {
  return async ({ functionName, baseModule, data }) => {
    const functionNameTR = baseModule ? `${baseModule}-${functionName}` : functionName;

    const httpCallableFunction = httpsCallable(functions, functionNameTR);
    try {
      const response = await httpCallableFunction(data);
      return { data: response.data };
    } catch (error) {
      // the error structure is following
      // { code, name}
      return { error };
    }
  };
}
