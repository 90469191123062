export function base64ToBlob(base64Data, mimeType) {
  // Decode the Base64 string
  const decodedData = atob(base64Data);

  // Create an ArrayBuffer and view for the decoded data
  const buffer = new ArrayBuffer(decodedData.length);
  const uintArray = new Uint8Array(buffer);

  // Copy the decoded data into the Uint8Array
  for (let i = 0; i < decodedData.length; i++) {
    uintArray[i] = decodedData.charCodeAt(i);
  }

  // Create a Blob object from the ArrayBuffer
  return new Blob([uintArray], { type: mimeType });
}
export function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}
export function Base64toBlob(base64) {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {
    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  });
  return blob;
}
