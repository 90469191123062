import _ from "lodash";

const TransformLLCAddressObjectToLCSAddressObject = ({ LLCAddressObject }) => {
  if (_.isEmpty(LLCAddressObject)) return {};
  return {
    AddressLine1: `${LLCAddressObject?.streetNumber || ""} ${
      LLCAddressObject?.streetAddress1 || ""
    }`,
    AddressLine2: `${LLCAddressObject?.streetAddress2 || ""}`,
    UnitNumber: LLCAddressObject?.unitNumber || "",
    Province: LLCAddressObject?.province || "",
    PostalCode: LLCAddressObject?.postalCode || "",
    Country: LLCAddressObject?.country || "",
    City: LLCAddressObject?.city || "",
  };
};

export default TransformLLCAddressObjectToLCSAddressObject;
