/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { TheLayout, TheLayoutWithoutSideBar } from ".";
import { useSendLLCData } from "src/new-folder-structure/features/llc-send-data/llc-send-data.hook";
import { setCurrentFileID } from "src/new-folder-structure/entities/lcs-file/api/slice";

function TemplatedRoute() {
  useSendLLCData();

  const location = useLocation();
  const dispatch = useDispatch();
  const lawFirmID = useSelector((state) => state?.old?.lcsUser?.lawFirmID);

  useEffect(() => {
    loadFile();
  }, [location, lawFirmID]);

  const loadFile = async () => {
    const fileId = location.pathname.split("/")[3];
    const fileType = location.pathname.split("/")[2];
    // const ref = doc(getFirestore(), `lawFirm/${lawFirmID}/files/${fileId}`);
    // const file = (await getDoc(ref)).data();
    // const checkList = file?.fileClosingChecklist;

    dispatch(setCurrentFileID(fileId));

    dispatch({
      type: "set",
      payload: {
        checkListFileId: fileId,
        checkListFileType: fileType,
        // checkListFile: checkList
      },
    });
  };
  if (location.pathname.split("/")[1] === "files") {
    return <TheLayout />;
  }
  return <TheLayoutWithoutSideBar />;
}

export default TemplatedRoute;
