import React, { ReactNode } from "react";

const SidebarContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div
      className="ml-4 mr-4"
      style={{
        height: "100vh",
        maxHeight: "85vh",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      {children}
    </div>
  );
};

export default SidebarContainer;
