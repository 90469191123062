import { useSelector, useDispatch } from "react-redux";

import {
  CHeader,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CToggler,
  CHeaderBrand,
  CImg,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CDropdown,
} from "@coreui/react";

import CIcon from "@coreui/icons-react";
import { TheHeaderDropdown } from ".";
import { useHistory } from "react-router-dom";
import IsAdminUser from "src/reusable/IsAdminUser";

export default function TheHeaderNoSideBar() {
  const history = useHistory();
  const darkMode = useSelector((state) => state?.old?.darkMode);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.old?.lcsUser);
  return (
    <CHeader withSubheader>
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        <CImg src="LawyerConveyance.svg" alt="LawyerConvenyancerBlack" height={25} />
      </CHeaderBrand>
      <CHeaderNav className="mr-auto">
        <CHeaderNavItem className="px-3">
          <CHeaderNavLink to="/dashboard">Dashboard</CHeaderNavLink>
        </CHeaderNavItem>
        <CHeaderNavItem className="px-3">
          <CHeaderNavLink to="/contacts">Contacts</CHeaderNavLink>
        </CHeaderNavItem>
        <>
          {IsAdminUser({ user }) ? (
            <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
              <CDropdownToggle className="c-header-nav-link" caret>
                <div>Dev</div>
              </CDropdownToggle>
              <CDropdownMenu className="pt-0" placement="bottom-end">
                <CDropdownItem onClick={() => history.push("/lcs-file-data")}>
                  LCS File Data
                </CDropdownItem>
                {/* <CDropdownItem onClick={() => history.push("/uploadLCSTemplates")}>
                  Upload LCS Templates
                </CDropdownItem> */}
                <CDropdownItem onClick={() => history.push("/globalMunicipality")}>
                  Global Municipality
                </CDropdownItem>
                <CDropdownItem onClick={() => history.push("/testingHasan")}>
                  Generating Hasan
                </CDropdownItem>
                <CDropdownItem onClick={() => history.push("/testingJohn")}>
                  Generating John
                </CDropdownItem>
                <CDropdownItem onClick={() => history.push("/testingDocx")}>
                  Generating Docx
                </CDropdownItem>
                <CDropdownItem onClick={() => history.push("/stewart-data-mapping")}>
                  Stewart Mapping
                </CDropdownItem>
                <CDropdownItem onClick={() => history.push("/qa-llc-integrator")}>
                  LLC Lender Offer
                </CDropdownItem>
                <CDropdownItem onClick={() => history.push("/llc-data-mapping")}>
                  LLC To LCS Data Mapping
                </CDropdownItem>
                <CDropdownItem onClick={() => history.push("/lcs-data-mapping")}>
                  LCS To LLC Data Mapping
                </CDropdownItem>
                <CDropdownItem onClick={() => history.push("/fct-data-mapping")}>
                  LCS To FCT Mapping
                </CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          ) : (
            ""
          )}
          {IsAdminUser({ user }) ? (
            <CHeaderNavItem className="px-3">
              <CHeaderNavLink to="/uploadLetterHead">Upload Letterhead</CHeaderNavLink>
            </CHeaderNavItem>
          ) : (
            ""
          )}
        </>
      </CHeaderNav>

      <CHeaderNav className="px-3">
        <CToggler
          inHeader
          className="ml-3 d-md-down-none c-d-legacy-none"
          onClick={() => dispatch({ type: "set", payload: { darkMode: !darkMode } })}
          title="Toggle Light/Dark Mode"
        >
          <CIcon name="cil-moon" className="c-d-dark-none" alt="CoreUI Icons Moon" />
          <CIcon name="cil-sun" className="c-d-default-none" alt="CoreUI Icons Sun" />
        </CToggler>
        {/* <TheHeaderDropdownCalendar /> */}
        {/* <TheHeaderDropdownNotif /> */}
        <TheHeaderDropdown />
        {/* <CToggler
          inHeader
          className="d-md-down-none"
          onClick={() =>
            dispatch({ type: "set", payload: { asideShow: !asideShow } })
          }
        >
          <CIcon className="mr-2" size="lg" name="cil-applications-settings" />
        </CToggler> */}
      </CHeaderNav>

      {/* <CSubheader className="px-3 justify-content-between">
        <CBreadcrumbRouter
          className="border-0 c-subheader-nav m-0 px-0 px-md-3"
          routes={routes}
        />
      </CSubheader> */}
    </CHeader>
  );
}
