let dummyFilesLawFirmID = "";

if (
  process.env.NODE_ENV === "production" /*production build using npm run build */ &&
  process.env.REACT_APP_TARGET_DEPLOYMENT ===
    "prod" /* and specified prod in the environment variable*/
) {
  dummyFilesLawFirmID = "61eigVcdNYgpwbHGRPpf";
} else {
  dummyFilesLawFirmID = "dX01ywGKu0N70B2NIJeG";
}

export { dummyFilesLawFirmID };
