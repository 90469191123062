import React, { useState } from "react";
import { useSelector } from "react-redux";
import { UseSelectorState } from "src/features/idv/models/entity.models";
import { trpc } from "src/shared/utils/trpc";
import IdVFlowControllerUI from "./idv-flow-controller.ui";
import { ProviderOptions } from "src/features/idv/models/entity.models";

const IdVFlowController = (): JSX.Element => {
  const util = trpc.useContext();
  const fileId = useSelector((state: UseSelectorState) => state?.old?.checkListFileId);
  const lawFirmId = useSelector((state: UseSelectorState) => state?.old?.lcsUser?.lawFirmID);
  const shouldFetch = Boolean(fileId && lawFirmId);

  //eslint-disable-next-line
  const { data, isLoading, isError, isSuccess } = trpc.entityIdv.findEntities.useQuery(
    {
      lawFirmId,
      fileId,
    },
    {
      enabled: shouldFetch,
    },
  );

  const refreshHandler = () => {
    util.entityIdv.findEntities.invalidate({ lawFirmId, fileId });
  };

  const [providerSelection, setProviderSelection] = useState<ProviderOptions | null>(null);

  return (
    <IdVFlowControllerUI
      providerSelection={providerSelection}
      setProviderSelection={setProviderSelection}
      refreshHandler={refreshHandler}
      isLoading={isLoading}
      isError={isError}
      isSuccess={isSuccess}
    />
  );
};

export default IdVFlowController;
