import React, { Suspense, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { CContainer, CFade } from "@coreui/react";

// routes config
import { useSelector } from "react-redux";
import LoadingSpinner from "src/new-folder-structure/shared/ui/loading-spinner";
import routes from "../routes";

function TheContent({ fluidContainer }) {
  const currentUserRole = useSelector((state) => state?.old?.lcsUser?.role);
  const signedOut = useSelector((state) => state?.old?.signedOut);
  // this doesn;t work because this component doesn't rerender on route change, instead the <Switch> is only rerendered
  // useEffect(() => {
  //   console.log("useEffect scroll up!");
  //   window.scrollTo(0, 0);
  // }, []);

  // window.onscroll = (e) => console.log("scrolling", e);

  return (
    <main className="c-main">
      <CContainer fluid={fluidContainer}>
        <Suspense fallback={<LoadingSpinner loadingText="Loading page..." />}>
          <Switch>
            {routes.map((route, idx) => {
              const RouteComponentWithScrollUp = withScrollUp(route.component);
              return (
                route.component && (
                  <Route key={idx} path={route.path} exact={route.exact} name={route.name}>
                    {signedOut && route.role && route.role !== currentUserRole ? (
                      <CFade>
                        <Redirect to="/dashboard" />
                      </CFade>
                    ) : (
                      <CFade>
                        <RouteComponentWithScrollUp />
                      </CFade>
                    )}
                  </Route>
                )
              );
            })}
            <Redirect from="/" to="/dashboard" />
          </Switch>
        </Suspense>
      </CContainer>
    </main>
  );
}

const withScrollUp = (WrappedComponent) =>
  function () {
    useEffect(() => {
      // console.log("useEffect scroll up!");
      window.scrollTo(0, 0);
    }, []);
    return <WrappedComponent />;
  };

export default React.memo(TheContent);
