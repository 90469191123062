import { CCol, CLabel } from "@coreui/react";
import { collection } from "firebase/firestore";
import { useFormContext } from "react-hook-form";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

import { dummyFilesLawFirmID } from "src/config/my-templates-config";
import jp from "jsonpath";

const DummyFiles = ({ name, required }) => {
  const db = useFirestore();
  const userFilesRef = collection(db, `lawFirm/${dummyFilesLawFirmID}/files`);
  const { data: userFiles } = useFirestoreCollectionData(userFilesRef);
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = jp.value(errors, `$.${name}`);

  return (
    <>
      <CCol md="2">
        <CLabel>Test file</CLabel>
      </CCol>
      <CCol md="4">
        <select
          className={`form-control ${error ? "is-invalid" : ""}`}
          name={name}
          {...register(name, { required: required || false })}
        >
          <option value="">Select file</option>
          {userFiles &&
            userFiles.length > 0 &&
            userFiles.map((item, index) => (
              <option key={index} value={item.NO_ID_FIELD}>
                {item.createFile.nameOfFile}
              </option>
            ))}
        </select>
        {error && <div className="invalid-feedback">This field is required</div>}
      </CCol>
    </>
  );
};
export default DummyFiles;
