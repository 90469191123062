// TODO: implement this entity fully
import { createApi } from "@reduxjs/toolkit/query/react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "src/new-folder-structure/shared/config/firebase";
import FirebaseFunctionsBaseQuery from "src/new-folder-structure/shared/lib/redux-base-query/redux-firebase-functions-base-query";
import FirebaseFirestoreBaseQuery from "src/new-folder-structure/shared/lib/redux-base-query/redux-firestore-base-query";

// TODO: add file entity adapter

export const lCSFileAPISlice = createApi({
  reducerPath: "fileAPI",
  baseQuery: FirebaseFirestoreBaseQuery(),
  tagTypes: ["file"],
  endpoints: (builder) => ({
    getLCSFile: builder.query({
      query: ({ lawFirmID, fileID }) => ({
        providesTags: ["file"],
        path: `lawFirm/${lawFirmID}/files/${fileID}`,
        method: "getDoc",
        isDocument: true,
      }),
      async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;
          const docReference = doc(db, `lawFirm/${arg.lawFirmID}/files/${arg.fileID}`);
          const unsub = onSnapshot(docReference, (doc) => {
            updateCachedData(() => ({ id: doc.id, ...doc.data() }));
          });
          // cacheEntryRemoved will resolve when the cache subscription is no longer active
          await cacheEntryRemoved;
          // perform cleanup steps once the `cacheEntryRemoved` promise resolves
          unsub();
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
      },
    }),
    updateLCSFile: builder.mutation({
      query: ({ lawFirmID, fileID, dataToUpdate }) => ({
        path: `lawFirm/${lawFirmID}/files/${fileID}`,
        method: "updateDoc",
        isDocument: true,
        data: dataToUpdate,
      }),
      invalidatesTags: ["file"],
    }),
    setLCSFile: builder.mutation({
      query: ({ lawFirmID, fileID, dataToUpdate }) => ({
        path: `lawFirm/${lawFirmID}/files/${fileID}`,
        method: "setDoc",
        isDocument: true,
        data: dataToUpdate,
      }),
      invalidatesTags: ["file"],
    }),
    deleteFileSubCollections: builder.mutation({
      queryFn: async ({ fileId }) => {
        const functionsBaseQuery = FirebaseFunctionsBaseQuery();
        return await functionsBaseQuery({
          functionName: "deleteFileSubcollections",
          baseModule: "lcsFile",
          data: { fileId },
        });
      },
    }),
    deleteFile: builder.mutation({
      queryFn: async ({ fileId }) => {
        const functionsBaseQuery = FirebaseFunctionsBaseQuery();
        return await functionsBaseQuery({
          functionName: "deleteFile",
          baseModule: "lcsFile",
          data: { fileId },
        });
      },
    }),
    getLCSFilesByLawFirmID: builder.query({
      query: ({ lawFirmID }) => ({
        path: `lawFirm/${lawFirmID}/files`,
        method: "getDocs",
        isCollection: true,
      }),
    }),
  }),
});

export const {
  useGetLCSFileQuery,
  useLazyGetLCSFileQuery,
  useUpdateLCSFileMutation,
  useSetLCSFileMutation,
  useDeleteFileSubCollectionsMutation,
  useGetLCSFilesByLawFirmIDQuery,
  useDeleteFileMutation
} = lCSFileAPISlice;

export const selectLCSFileByID = (state, fileID) => {
  const lawFirmID = state?.old?.lcsUser?.lawFirmID;
  const apiResultSelector = lCSFileAPISlice.endpoints.getLCSFile.select({
    lawFirmID,
    fileID,
  });
  const apiResult = apiResultSelector(state);
  return apiResult?.data ?? {};
};
