/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
import TransformLLCMortgagorToLCSMortgagor from "./TransformLLCMortgagorToLCSMortgagor";
const HandleMappingOfPurchaserSection = ({
  LLCData,
  currentFile,
  solicitorObject,
  typeOfCurrentFile,
}) => {
  const isPurchaseFile = typeOfCurrentFile === "Purchase";
  const resultOfHandleMappingOfPurchaserSection = {
    ...(currentFile?.purchaser || ""),
    solicitor: isPurchaseFile === true ? solicitorObject || "" : "",
    lawyerOnFile:
      isPurchaseFile === true ? `${solicitorObject?.firstName}${solicitorObject?.lastName}` : "",
    purchasers: HandleMappingOfPurchasersTable({
      currentFile,
      LLCData,
      isPurchaseFile,
    }),
  };
  return resultOfHandleMappingOfPurchaserSection;
};
const HandleMappingOfPurchasersTable = ({ currentFile, LLCData, isPurchaseFile }) => {
  const currentPurchasersTable = currentFile?.purchaser?.purchasers || [];
  if (isPurchaseFile === false) return currentPurchasersTable;
  const currentMortgagorTableWithoutLLCMortgagors =
    currentPurchasersTable?.filter((mortgagor) => !mortgagor?.LLC) || [];

  let finalPurchasersTable = currentMortgagorTableWithoutLLCMortgagors || [];

  let LLC_Mortgagors =
    Array.isArray(LLCData?.mortgagors) &&
    LLCData?.mortgagors?.map((mortgagorObject) => {
      let currentMortgagorData =
        currentPurchasersTable?.find((e) => {
          return e?.LLCLookUp?.some((x) => {
            return (
              String(x?.LLC_ID) === String(mortgagorObject?.id) &&
              String(x?.LLCTransactionId) === String(LLCData?.transactionId)
            );
          });
        }) || {};

      return TransformLLCMortgagorToLCSMortgagor({
        mortgagorObject,
        LLCData,
        currentMortgagorData,
      });
    });
  finalPurchasersTable = finalPurchasersTable?.concat(LLC_Mortgagors) || [];
  return finalPurchasersTable;
};
export default HandleMappingOfPurchaserSection;
