import { createAsyncThunk } from "@reduxjs/toolkit";
import { doc, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { usersAPISlice } from "src/new-folder-structure/entities/users/model/api";
import { lLCAPISlice } from "src/new-folder-structure/shared/api/llc";
import { db, functions } from "src/new-folder-structure/shared/config/firebase";
import TransformLLCDataToLCS from "src/new-folder-structure/shared/lib/LLC/mapping/TransformLLCDataToLCS/TransformLLCDataToLCS";
import HnHHandleDate from "src/reusable/HnHHandleDate";
import { lCSFileAPISlice, selectLCSFileByID } from "../../lcs-file/api";
import { offerAPISlice } from "./api";
import { SLICE_NAME } from "./constants";
import {
  selectLenderChangesStatus,
  selectLockStatus,
  selectLockStatusFromDB,
  selectSameUser,
} from "./selectors";
import { base64ToBlob } from "src/reusable/base64ToBlob";

// ================================================================
// Thunk Actions
// ================================================================

/* Standard naming convention to add "Async" at the end of the thunk action name
 * const dispatch = useDispatch();
 * const { isFetching } = offerModel.acceptOfferAsync()(dispatch);
 */
// TODO: can't we just use RTKQ instead of custom thunk? how do we access getState and dispatch in RTKQ?
export const acceptOfferAsync = createAsyncThunk(
  `${SLICE_NAME}/acceptOffer`,
  async (
    {
      transactionId,
      additionalInfoFromUser,
      alreadyAccepted,
      lawyerOnFileUserID,
      lenderName,
      mortgageeName,
      lenderCodes,
    },
    thunkAPI,
  ) => {
    const { getState, dispatch } = thunkAPI;

    alreadyAccepted !== true &&
      (await dispatch(
        lLCAPISlice.endpoints.acceptOffer.initiate({ transactionId, lawyerOnFileUserID }),
      ).unwrap());

    const state = getState();
    const LLCData = await dispatch(
      lLCAPISlice.endpoints.getTransactionData.initiate({ transactionId, lawyerOnFileUserID }),
    ).unwrap();

    const {
      /* currentFile, */ lawFirm,
      lcsUser: { lawFirmID },
    } = state.old;
    const users = await dispatch(
      usersAPISlice.endpoints.getUsersByLawFirmId.initiate({ lawFirmID }),
    ).unwrap();

    const mappedLCSDataFromLLC = TransformLLCDataToLCS({
      nameOnFile: additionalInfoFromUser?.createFile?.nameOfFile,
      fileNumber: additionalInfoFromUser?.createFile?.fileNumber,
      typeOfFilePassedByTheUser: additionalInfoFromUser?.createFile?.fileType,
      principalAmountPassedByTheUser: additionalInfoFromUser?.principalAmount,
      closingDate: additionalInfoFromUser?.createFile?.closingDate,
      LLCData,
      users,
      lawFirm,
      lenderName: lenderName,
      mortgageeName: mortgageeName,
      lenderCodes: lenderCodes,
    });

    const purchaseFile = httpsCallable(functions, "stripe-purchaseFile");

    const result = await purchaseFile(mappedLCSDataFromLLC.createFile);

    // eslint-disable-next-line no-unused-vars
    const [fileID, _invoiceData, fileData] = result.data;

    // create and store PDF of invoice
    await updateDoc(doc(db, `lawFirm/${lawFirmID}/files/${fileID}`), {
      ...mappedLCSDataFromLLC,
      createFile: {
        ...mappedLCSDataFromLLC.createFile,
        office: additionalInfoFromUser?.office,
      },
    });

    // //TODO: need to refactor this to be able to include in synchronized way here
    // // add field in llc-offers subcollection under law firm to track which file this LLC transaction is assigned to
    await dispatch(
      offerAPISlice.endpoints.assignLLCOfferToLCSFile.initiate({
        lawFirmID,
        fileID,
        transactionId,
      }),
    ).unwrap();

    // //TODO: standarized what we return from thunk if it's needed to be called in synchronous way
    return { data: result?.data || null };
  },
);

export const getFileLockStatusAsync = createAsyncThunk(
  `${SLICE_NAME}/getFileLockStatus`,
  async ({ fileID, lawFirmID, transactionId }, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const state = getState();
    // const { /* currentFile, */ lcsUser } = state.old;
    // await dispatch(offerAPISlice.endpoints.setFileLock.initiate({ transactionId })).unwrap();
    await dispatch(lCSFileAPISlice.endpoints.getLCSFile.initiate({})).unwrap();
    const fileData = selectLCSFileByID(state, fileID);

    const { fileLockStatus: fileLock } = fileData;

    if (fileLock && fileLock?.userId && HnHHandleDate(fileLock?.lockExpiry) <= new Date()) {
      const documentReference = doc(db, `lawFirm/${lawFirmID}/files/${fileID}`);

      await updateDoc(documentReference, {
        fileLockStatus: { isLocked: false, lcsUserName: null, lockExpiry: null, userId: null },
      });

      return { isLocked: false, lcsUserName: null, lockExpiry: null, userId: null };
    }
    // if (fileLock && fileLock?.userId && fileLock?.userId !== lcsUser?.userID) {
    if (fileLock && fileLock?.userId) {
      return { ...fileLock };
    }
    return { ...fileLock, isLocked: false };
  },
);

export const unlockFileAsync = createAsyncThunk(
  `${SLICE_NAME}/lockFile`,
  async ({ transactionId }, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const state = getState();
    const {
      currentFile: { NO_ID_FIELD },
      lcsUser: { lawFirmID },
    } = state?.old;
    const lenderChangesStatus = selectLenderChangesStatus(state, NO_ID_FIELD, transactionId);
    if (!lenderChangesStatus) {
      await dispatch(
        offerAPISlice.endpoints.setFileLock.initiate({
          lawFirmID: lawFirmID,
          fileID: NO_ID_FIELD,
          lockData: {
            isLocked: false,
            lockExpiry: null,
            lcsUserName: null,
            userId: null,
          },
        }),
      ).unwrap();
    }
  },
);

export const openLLCPortalAsync = createAsyncThunk(
  `${SLICE_NAME}/openLLCPortal`,
  async ({ transactionId }, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const state = getState();
    const {
      /* currentFile, */
      currentFile: { NO_ID_FIELD },
      lcsUser,
    } = state.old;

    const lockStatus = selectLockStatus(state, NO_ID_FIELD, transactionId);
    const fileLockStatus = selectLockStatusFromDB(state);
    const lenderChangesStatus = selectLenderChangesStatus(state, NO_ID_FIELD, transactionId);

    const isSameUser = selectSameUser(state);

    const data = await dispatch(
      lLCAPISlice.endpoints.getLLCPortalDealURL.initiate(
        {
          fctUrn: transactionId,
          isReadOnly: lenderChangesStatus || (fileLockStatus?.isLocked && !isSameUser),
        },
        { forceRefetch: true },
      ),
    ).unwrap();

    if (!lockStatus) {
      const currentDate = new Date();
      currentDate.setMinutes(currentDate.getMinutes() + 5);
      await dispatch(
        offerAPISlice.endpoints.setFileLock.initiate({
          lawFirmID: lcsUser?.lawFirmID,
          fileID: NO_ID_FIELD,
          lockData: {
            isLocked: true,
            lockExpiry: currentDate,
            lcsUserName: lcsUser?.nameOnFile,
            userId: lcsUser?.userID,
          },
        }),
      ).unwrap();
    }
    // dispatch(setLLCPortalStatus({ showModal: true, llcUrl: data?.url }));
    return data?.url;
  },
);

export const closeLLCPortalAsync = createAsyncThunk(
  `${SLICE_NAME}/closeLLCPortal`,
  async ({ transactionId }, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const state = getState();
    const fileLockStatus = selectLockStatusFromDB(state);
    const {
      /* currentFile, */
      currentFile: { NO_ID_FIELD },
      lcsUser,
    } = state.old;

    if (lcsUser?.userID === fileLockStatus?.userId) {
      await dispatch(
        offerAPISlice.endpoints.setFileLock.initiate({
          lawFirmID: lcsUser?.lawFirmID,
          fileID: NO_ID_FIELD,
          lockData: {
            isLocked: false,
            lockExpiry: null,
            lcsUserName: null,
            userId: null,
          },
        }),
      ).unwrap();
    }
    await dispatch(lLCAPISlice.endpoints.getTransactionStatus.initiate({ transactionId })).unwrap();
  },
);

export const refreshLLCDataAsync = createAsyncThunk(
  `${SLICE_NAME}/refreshLLCData`,
  async ({ transactionId }, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;

    const LLCData = await dispatch(
      lLCAPISlice.endpoints.getTransactionData.initiate({ transactionId }, { forceRefetch: true }),
    ).unwrap();
    const state = getState();
    const {
      currentFile,
      lawFirm,
      lcsUser: { lawFirmID },
    } = state.old;
    const users = await dispatch(
      usersAPISlice.endpoints.getUsersByLawFirmId.initiate({ lawFirmID }),
    ).unwrap();
    const { NO_ID_FIELD: fileID } = currentFile;
    const mappedLCSDataFromLLC = TransformLLCDataToLCS({
      LLCData,
      currentFile,
      users,
      lawFirm,
    });
    await updateDoc(doc(db, `lawFirm/${lawFirmID}/files/${fileID}`), mappedLCSDataFromLLC);

    return { data: mappedLCSDataFromLLC };
  },
);

// export const getTransactionIdAsync = createAsyncThunk(
//   `${SLICE_NAME}/getTransactionId`,
//   async ({ lawFirmID, fileID }, thunkAPI) => {
//     const { dispatch } = thunkAPI;
//     const fileData = await dispatch(
//       lCSFileAPISlice.endpoints.getLCSFile.initiate({ lawFirmID, fileID }),
//     ).unwrap();
//     const { newMortgagesFile } = fileData;
//     let transactionIds = [];
//     if (newMortgagesFile?.newMortgages && newMortgagesFile?.newMortgages.length > 0) {
//       transactionIds = newMortgagesFile.newMortgages?.map((item) => item?.LLC?.LLCTransactionId);
//     }
//     return { transactionIds };
//   },
// );

// export const getDocumentListAsync = createAsyncThunk(
//   `${SLICE_NAME}/getDocumentListAsync`,
//   async ({ transactionId }, thunkAPI) => {
//     const { dispatch } = thunkAPI;
//     const promise = dispatch(
//       lLCAPISlice.endpoints.getDocumentList.initiate(
//         {
//           transactionId,
//         },
//         { forceRefetch: true },
//       ),
//     );
//     const resolvedValue = await promise.unwrap();
//     let data = resolvedValue;
//     return { data, transactionId };
//   },
// );

export const getDocumentListAsync = createAsyncThunk(
  `${SLICE_NAME}/getDocumentListAsync`,
  async ({ transactionIds }, thunkAPI) => {
    const { dispatch } = thunkAPI;
    let documentList = [];
    if (transactionIds.length === 0) {
      return { data: [] };
    }
    const promises = await transactionIds.map((transactionId) => {
      return dispatch(
        lLCAPISlice.endpoints.getDocumentList.initiate(
          {
            transactionId,
          },
          { forceRefetch: true },
        ),
      );
    });
    const resolvedValues = await Promise.all(
      promises.map(async (p) => {
        return await p.unwrap();
      }),
    );
    transactionIds.forEach((transactionId, index) => {
      // eslint-disable-next-line array-callback-return
      resolvedValues[index].map((item) => {
        documentList.push({ ...item, transactionId });
      });
    });
    return { data: documentList };
  },
);

export const getSingleDocumentAsync = createAsyncThunk(
  `${SLICE_NAME}/getDocumentData`,
  async ({ transactionId, documentId, documentName, isFinalized = false }, thunkAPI) => {
    const { dispatch } = thunkAPI;

    const result = await dispatch(
      lLCAPISlice.endpoints.getSingleDocument.initiate({
        transactionId,
        documentId,
      }),
    ).unwrap();
    if (isFinalized) {
      return base64ToBlob(result?.data, "application/pdf");
    } else {
      var a = document.createElement("a"); //Create <a>
      a.href = "data:application/pdf;base64," + result?.data; //Image Base64 Goes here
      a.download = `${documentName}`; //File name Here
      a.click();
    }

    // return { data: result?.data };

    // return { data: mappedLCSDataFromLLC, isLoading, isError };
  },
);
