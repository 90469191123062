import { Dispatch, SetStateAction } from "react";

export enum EntityType {
  Person = "person",
  EstateTrustee = "estate trustee",
  PowerOfAttorney = "power of attorney",
  SigningOfficer = "signing officer",
}

export enum ProviderOptions {
  TreeFort = "treefort",
}

export interface UseSelectorState {
  old: {
    showCheckList: boolean;
    darkMode: boolean;
    checkListFileId: string;
    checkListFileType: string;
    lcsUser: {
      email: string;
      nameOnFile: string;
      firstName: string;
      lastName: string;
      phone: string;
      lawFirmID: string;
    };
  };
}

export interface IdVFlowController {
  providerSelection: ProviderOptions | null;
  setProviderSelection: Dispatch<SetStateAction<ProviderOptions | null>>;
  refreshHandler: () => void;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
}

export interface IdVProviderListProps {
  setProviderSelection: Dispatch<SetStateAction<ProviderOptions | null>>;
}

export interface IdVProviderListUIProps {
  handleCompanyClick: (company: ProviderOptions) => void;
  companyEnum: ProviderOptions[];
}

export interface EntityListProps {
  providerSelection: ProviderOptions;
  setProviderSelection: Dispatch<SetStateAction<ProviderOptions | null>>;
}

export interface fetchedEntities {
  id: number;
  name: string;
  email: string;
  phone: string;
  type: EntityType;
}

export interface IDVLink {
  id: number;
  startDate: string;
  endDate: string;
}

export interface IDVEntityReport {
  entityId: string;
  lcsEntityId: number;
  reportStatus?: "Pending" | "Complete";
  reportResultStats?: "Pass" | "Fail";
  reportFile?: string;
  reportExpiry?: string;
  purchaseReport?: boolean;
  verificationLinks: IDVLink[];
}

export interface EntityWithReport extends fetchedEntities, IDVEntityReport {}

export interface EntityListUIProps {
  lawFirmId: string;
  fileId: string;
  entities: EntityWithReport[];
  toggleFileStatus: () => void;
  fileStatusClosed: boolean;
  providerSelection: ProviderOptions;
  setProviderSelection: Dispatch<SetStateAction<ProviderOptions | null>>;
  entityTypeIcons: Record<EntityType, JSX.Element>;
  entityListPopulated: boolean;
}

export interface EntityEngagementProps {
  entity: EntityWithReport;
  Icon: JSX.Element;
  lawFirmId: string;
  fileId: string;
  providerSelection: ProviderOptions;
}

export interface DropdownItems {
  icon: JSX.Element;
  text: (entity?: EntityWithReport) => string;
  action: () => void;
}

export interface EntityEngagementUIProps {
  showUpdateEntityModal: boolean;
  setShowUpdateEntityModal: Dispatch<SetStateAction<boolean>>;
  showPaymentModal: boolean;
  setShowPaymentModal: Dispatch<SetStateAction<boolean>>;
  lawFirmId: string;
  fileId: string;
  entity: EntityWithReport;
  Icon: JSX.Element;
  hasEmailAndPhone: string;
  getDropdownItems: any;
  expiryDates: any;
  provider: ProviderOptions;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  error: string | null;
}

export interface OptionsDropdownProps {
  entity: EntityWithReport;
  dropdownItems: any;
  toggleIcon: JSX.Element;
  toolTip: string;
  loading: boolean;
  placement?:
    | ""
    | "top"
    | "top-end"
    | "top-start"
    | "bottom-end"
    | "bottom"
    | "bottom-start"
    | "right-start"
    | "right"
    | "right-end"
    | "left-start"
    | "left"
    | "left-end";
  caret?: boolean;
}

export interface UpdateEntityInfoModalProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  entityData: EntityWithReport;
  lawFirmId: string;
  fileId: string;
}

export interface UpdateEntityInfoModalPropsUI {
  showModal: boolean;
  entityName: string;
  entityPhone: string;
  entityEmail: string;
  methods: any;
  onSubmit: any;
  closeModal: () => void;
  showError: string;
}

export interface ModalIdVPaymentProps {
  provider: string;
  entityData: EntityWithReport;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export interface ModalIdVPaymentUIProps {
  entityName: string;
  showModal: boolean;
  provider: string;
  paymentHandler: () => void;
  closeModal: () => void;
  showError: string | null;
}
