import _ from "lodash";
import HandleCreatingSolicitorObject from "./helper-functions/HandleCreatingSolicitorObject";
import HandleMappingOfCondominiumsSection from "./helper-functions/HandleMappingOfCondominiumsSection";
import HandleMappingOfCreateFileSection from "./helper-functions/HandleMappingOfCreateFileSection";
import HandleMappingOfEncumbrancesSection from "./helper-functions/HandleMappingOfEncumbrancesSection";
import HandleMappingOfManualTitleSearchesSection from "./helper-functions/HandleMappingOfManualTitleSearchesSection";
import HandleMappingOfMortgagorSection from "./helper-functions/HandleMappingOfMortgagorSection";
import HandleMappingOfNewMortgagesSection from "./helper-functions/HandleMappingOfNewMortgagesSection";
import HandleMappingOfPurchaserSection from "./helper-functions/HandleMappingOfPurchaserSection";
import HandleTransactionIds from "./helper-functions/HandleTransactionIds";
import TransformLLCEstateTypeToIntersetInLand from "./helper-functions/TransformLLCEstateTypeToIntersetInLand";

const TransformLLCDataToLCS = ({
  nameOnFile,
  fileNumber,
  typeOfFilePassedByTheUser,
  principalAmountPassedByTheUser,
  users,
  lawFirm,
  closingDate,
  LLCData,
  currentFile,
  id,
  lenderName,
  mortgageeName,
  lenderCodes,
}) => {
  if (_.isEmpty(LLCData)) return {};
  const typeOfCurrentFile =
    // eslint-disable-next-line no-nested-ternary
    currentFile?.createFile?.fileType
      ? currentFile?.createFile?.fileType
      : LLCData?.transactionType?.toLowerCase() === "refinance"
      ? "Mortgage"
      : LLCData?.transactionType?.toLowerCase() === "purchase"
      ? "Purchase"
      : typeOfFilePassedByTheUser || "";
  const solicitorObject = HandleCreatingSolicitorObject({
    lawFirm,
    users,
    LLCData,
    currentFile,
  });
  let transformedLLCDataToLCSData = {
    ...currentFile,
    id: id || currentFile?.id,
    LLCData: HandleTransactionIds({ LLCData, currentFile }),
    createFile: HandleMappingOfCreateFileSection({
      nameOnFile,
      fileNumber,
      LLCData,
      typeOfCurrentFile,
      currentFile,
      closingDate,
    }),
    fileOpen: {
      ...currentFile?.fileOpen,
      ...(typeOfCurrentFile === "Mortgage" && {
        mortgageActingFor: "mortgagor",
      }),
      ...(typeOfCurrentFile === "Purchase" && {
        purchaseActingFor: "purchaserOnly",
      }),
    },
    newMortgagesFile: HandleMappingOfNewMortgagesSection({
      LLCData,
      currentFile,
      principalAmountPassedByTheUser,
      mortgageeName: mortgageeName,
      lenderCodes: lenderCodes,
    }),
    property: {
      ...currentFile?.property,
      propertyCategory: "residential",
      ...(typeOfCurrentFile === "Mortgage" && {
        appraisalAmount: LLCData?.mortgage?.purchasePrice,
      }),
      propertyRentalProperty:
        LLCData?.properties?.[0]?.occupancyType?.toLowerCase() === "rental" ? "yes" : "no",
      propertyOwnerOccupied:
        LLCData?.properties?.[0]?.occupancyType?.toLowerCase() === "owner occupied" ? "yes" : "no",
      interestInLand: TransformLLCEstateTypeToIntersetInLand({
        LLCData,
      }),
      interestInLandDescription: LLCData?.properties?.[0]?.otherEstateTypeDescription || "",
      ...(LLCData?.properties?.[0]?.condoLevel !== null &&
        LLCData?.properties?.[0]?.condoUnitNumber !== null && {
          propertyPurchaseType: "condominium",
        }),
    },
    propertyPrice: {
      ...currentFile?.propertyPrice,
      ...(typeOfCurrentFile === "Purchase" && {
        priceInput: LLCData?.mortgage?.purchasePrice,
      }),
    },
    mortgagor:
      typeOfCurrentFile === "Mortgage"
        ? HandleMappingOfMortgagorSection({
            solicitorObject,
            LLCData,
            currentFile,
            typeOfCurrentFile,
          })
        : "",
    purchaser:
      typeOfCurrentFile === "Purchase"
        ? HandleMappingOfPurchaserSection({
            solicitorObject,
            LLCData,
            currentFile,
            typeOfCurrentFile,
          })
        : "",
    condominiums: HandleMappingOfCondominiumsSection({
      LLCData,
      currentFile,
    }),
    existingEncumbrances:
      (typeOfCurrentFile === "Mortgage" &&
        HandleMappingOfEncumbrancesSection({
          LLCData,
          currentFile,
        })) ||
      [],
    ManualTitleSearches: HandleMappingOfManualTitleSearchesSection({ LLCData, currentFile }),
  };
  return transformedLLCDataToLCSData;
};

export default TransformLLCDataToLCS;
