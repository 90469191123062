import _ from "lodash";

const TransformLLCEstateTypeToLCSInterestInLand = ({ estateType }) => {
  if (estateType === "fee simple") return "feeSimple";
  else if (estateType === "leasehold") return "leasehold";
  else if (estateType === "freehold") return "freehold";
  else if (estateType === "other") return "other";
  return "";
};
const TransformLLCEstateTypeToIntersetInLand = ({ LLCData }) => {
  if (_.isEmpty(LLCData?.properties?.[0]?.estateType)) return "";
  const LLCEstateType = LLCData?.properties?.[0]?.estateType?.toLowerCase();
  return TransformLLCEstateTypeToLCSInterestInLand({ estateType: LLCEstateType }) || "";
};

export default TransformLLCEstateTypeToIntersetInLand;
