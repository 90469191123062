import React from "react";
import { CTooltip, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from "@coreui/react";
import { OptionsDropdownProps } from "src/features/idv/models/entity.models";
import { Spinner } from "react-bootstrap";

const OptionsDropdown = ({
  entity,
  dropdownItems,
  toggleIcon,
  toolTip,
  loading,
  placement = "top",
  caret = false,
}: OptionsDropdownProps) => {
  return (
    <CTooltip content={toolTip} placement={placement}>
      <CDropdown>
        <CDropdownToggle
          color="primary"
          caret={caret}
          className="d-flex align-items-center justify-content-center"
          style={{ width: "2.5rem", height: "2.5rem" }}
        >
          {loading ? <Spinner animation="border" size="sm" /> : toggleIcon}
        </CDropdownToggle>
        <CDropdownMenu>
          {dropdownItems.map((item: Record<string, any>, index: number) => (
            <CDropdownItem key={index} onClick={item.action}>
              {item.icon}
              {item.text(entity)}
            </CDropdownItem>
          ))}
        </CDropdownMenu>
      </CDropdown>
    </CTooltip>
  );
};

export default OptionsDropdown;
