import { createSlice } from "@reduxjs/toolkit";
import {
  acceptOfferAsync,
  getDocumentListAsync,
  getSingleDocumentAsync,
  getFileLockStatusAsync,
  openLLCPortalAsync,
  refreshLLCDataAsync,
} from "./thunks";
import { SLICE_NAME } from "./constants";
import { toast } from "react-toastify";

// ================================================================
// Offer Slice
// ================================================================
export const offerSliceInitialState = {
  acceptOfferForm: {
    isSubmitting: false,
    error: null,
  },
  acceptOfferModal: {
    showModal: false,
    newOffer: null,
    error: null,
  },
  declineOfferModal: {
    showModal: false,
    newOffer: null,
    error: null,
  },
  openLLCPortalAsync: {
    isLoading: false,
    isError: false,
    error: null,
  },
  getFileLockStatusAsync: {
    isSubmitting: false,
    error: null,
  },
  lLCPortalStatus: {
    showModal: false,
    llcUrl: "",
  },
  llcUpdateData: {
    toastId: null,
  },
  getSingleDocumentAsync: {
    isSubmitting: false,
    error: null,
    documentData: null,
  },
  getDocumentListAsync: {
    documentList: [],
    isLoading: false,
    error: null,
  },
  lenderChangesToApprove: [],
};

export const offerSlice = createSlice({
  name: SLICE_NAME,
  initialState: offerSliceInitialState,
  reducers: {
    resetAcceptOfferForm: (state) => {
      state.acceptOfferForm.error = null;
    },
    updateAcceptOfferModal: (state, action) => {
      state.acceptOfferModal.showModal = action.payload.showModal;
      if (action.payload.showModal) {
        state.acceptOfferModal.newOffer = action.payload.newOffer;
        state.acceptOfferForm.error = null;
      }
    },
    updateDeclineOfferModal: (state, action) => {
      state.declineOfferModal.showModal = action.payload.showModal;
      if (action.payload.showModal) {
        state.declineOfferModal.newOffer = action.payload.newOffer;
        state.declineOfferModal.error = null;
      }
    },
    setSubmitting: (state, action) => {
      state.acceptOfferForm.isSubmitting = action.payload.isSubmitting;
    },
    setLenderChange: (state, action) => {
      const target = state.lenderChangesToApprove.find(
        (change) => change.jsonpath === action.payload.jsonpath,
      );
      if (target) {
        Object.assign(target, action.payload);
      } else {
        state.lenderChangesToApprove.push(action.payload);
      }
    },
    setLLCPortalStatus: (state, action) => {
      if (action.payload.showModal !== undefined) {
        state.lLCPortalStatus.showModal = action.payload.showModal;
      }
      if (action.payload.llcUrl !== undefined) {
        state.lLCPortalStatus.llcUrl = action.payload.llcUrl;
      }
    },
    differenceBetweenTwoFiles: (state, action) => {
      state.differenceBetweenTwoFiles = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(acceptOfferAsync.pending, (state /* , action */) => {
        // eslint-disable-next-line no-param-reassign
        state.acceptOfferForm.isSubmitting = true;
      })
      .addCase(acceptOfferAsync.fulfilled, (state /* , action */) => {
        // eslint-disable-next-line no-param-reassign
        state.acceptOfferForm.isSubmitting = false;
      })
      .addCase(acceptOfferAsync.rejected, (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.acceptOfferForm.isSubmitting = false;
        // eslint-disable-next-line no-param-reassign
        state.acceptOfferForm.error = action.error;
      })
      .addCase(getFileLockStatusAsync.pending, (state /* , action */) => {
        // eslint-disable-next-line no-param-reassign
        state.getFileLockStatusAsync = { isSubmitting: true };
      })
      .addCase(getFileLockStatusAsync.fulfilled, (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.getFileLockStatusAsync = { isSubmitting: false, ...action.payload };
      })
      .addCase(getFileLockStatusAsync.rejected, (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.getFileLockStatusAsync = { isSubmitting: false, error: action.error };
      })
      .addCase(openLLCPortalAsync.pending, (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.openLLCPortalAsync = { isLoading: true };
      })
      .addCase(openLLCPortalAsync.fulfilled, (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.openLLCPortalAsync = { isLoading: false };
        // eslint-disable-next-line no-param-reassign
        state.lLCPortalStatus = { showModal: true, llcUrl: action.payload.lLCPortalURL };
      })
      .addCase(openLLCPortalAsync.rejected, (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.openLLCPortalAsync = { isLoading: false, error: action.error, isError: true };
      })
      .addCase(refreshLLCDataAsync.pending, (state, action) => {
        state.llcUpdateData.toastId = toast.loading("Please wait...", {
          toastId: "refreshLLCData",
          position: "top-right",
          theme: "dark",
        });
      })
      .addCase(refreshLLCDataAsync.rejected, (state, action) => {
        toast.update(state.llcUpdateData.toastId, {
          render: action.error.message,
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
      })
      .addCase(refreshLLCDataAsync.fulfilled, (state) => {
        toast.update(state.llcUpdateData.toastId, {
          render: "LLC data successfully updated",
          type: "success",
          isLoading: false,
          autoClose: 2000,
        });
      })
      .addCase(getSingleDocumentAsync.pending, (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.getSingleDocumentAsync.isSubmitting = true;
        state.getSingleDocumentAsync.documentData = { ...action?.meta?.arg };
      })
      .addCase(getSingleDocumentAsync.fulfilled, (state /* , action */) => {
        // eslint-disable-next-line no-param-reassign
        state.getSingleDocumentAsync.isSubmitting = false;
        state.getSingleDocumentAsync.documentData = null;
      })
      .addCase(getSingleDocumentAsync.rejected, (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.getSingleDocumentAsync.isSubmitting = false;
        // eslint-disable-next-line no-param-reassign
        state.getSingleDocumentAsync.error = action.error;
        state.getSingleDocumentAsync.documentData = null;
      })
      .addCase(getDocumentListAsync.pending, (state /* , action */) => {
        // eslint-disable-next-line no-param-reassign
        state.getDocumentListAsync.isLoading = true;
        // state.getDocumentListAsync.documentList = []; // to clear list
      })
      .addCase(getDocumentListAsync.fulfilled, (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.getDocumentListAsync.documentList = [...action.payload.data];
        state.getDocumentListAsync.isLoading = false;
      })
      .addCase(getDocumentListAsync.rejected, (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.getDocumentListAsync.error = action.error;
        state.getDocumentListAsync.isLoading = false;
      });
  },
});

// ================================================================
// Actions from reducers
// ================================================================
export const {
  resetAcceptOfferForm,
  setLenderChange,
  setLLCPortalStatus,
  differenceBetweenTwoFiles,
  setSubmitting,
  updateAcceptOfferModal,
  updateDeclineOfferModal,
} = offerSlice.actions;
