import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { initializeFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

import { FIREBASE_FUNCTIONS_REGION, firebaseEnvVars } from "./env-var";

export const firebaseConfig = {
  apiKey: firebaseEnvVars.FIREBASE_API_KEY,
  authDomain: `${firebaseEnvVars.FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${firebaseEnvVars.FIREBASE_PROJECT_ID}.firebaseio.com`,
  projectId: firebaseEnvVars.FIREBASE_PROJECT_ID,
  storageBucket: `${firebaseEnvVars.FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: firebaseEnvVars.FIREBASE_MESSAGING_SENDER_ID,
  appId: firebaseEnvVars.FIREBASE_APP_ID,
  measurementId: firebaseEnvVars.FIREBASE_MEASUREMENT_ID,
};

export const firebaseApp = initializeApp(firebaseConfig);
// TODO: discuss with team and Hasan about this config in firestore
export const db = initializeFirestore(firebaseApp, { ignoreUndefinedProperties: true });
export const auth = getAuth(firebaseApp);
export const functions = getFunctions(firebaseApp, FIREBASE_FUNCTIONS_REGION); // "northamerica-northeast1"
export const storage = getStorage(firebaseApp);
export const analytics = getAnalytics(firebaseApp);

export default firebaseConfig;
