import { collection, query, doc, getDocs, writeBatch, where } from "firebase/firestore";

export const updateInvoicesFileNumber = async (db, lawFirmID, fileId, fileNumber) => {
  try {
    const invoiceRef = collection(db, `lawFirm/${lawFirmID}/invoices`);
    const querySnapshot = await getDocs(query(invoiceRef, where("fileId", "==", fileId)));

    if (querySnapshot.empty) return;

    const batch = writeBatch(db);
    querySnapshot.forEach((invoiceDoc) => {
      const docRef = doc(db, `lawFirm/${lawFirmID}/invoices`, invoiceDoc.id);
      batch.update(docRef, {
        lawyerFileNumber: fileNumber,
      });
    });

    await batch.commit();
  } catch (err) {
    throw err;
  }
};
