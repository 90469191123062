import _ from "lodash";

const TransformLLCPriorityToLCSPriority = ({ LLCData }) => {
  if (_.isEmpty(LLCData?.properties?.[0]?.mortgagePriority)) return "";
  const mortgagePriority = LLCData?.properties?.[0]?.mortgagePriority?.toLowerCase();
  return HandleLLCPriority({ priority: mortgagePriority }) || {};
};
const HandleLLCPriority = ({ priority }) => {
  return {
    priority: GetLCSPriority(priority)?.LCSPriorityString || "",
    priorityNumber: GetLCSPriority(priority)?.LCSPriorityNumber || "",
  };
};
const GetLCSPriority = (priority) => {
  let priorityObject = {};

  switch (priority) {
    case "first":
      priorityObject = {
        LCSPriorityString: "1st",
        LCSPriorityNumber: 1,
      };
      break;
    case "second":
      priorityObject = {
        LCSPriorityString: "2nd",
        LCSPriorityNumber: 2,
      };
      break;
    case "third":
      priorityObject = {
        LCSPriorityString: "3rd",
        LCSPriorityNumber: 3,
      };
      break;
    case "fourth":
      priorityObject = {
        LCSPriorityString: "4th",
        LCSPriorityNumber: 4,
      };
      break;
    case "fifth":
      priorityObject = {
        LCSPriorityString: "5th",
        LCSPriorityNumber: 5,
      };
      break;
    default:
      priorityObject = {
        LCSPriorityString: "1st",
        LCSPriorityNumber: 1,
      };
  }
  return priorityObject;
};
export default TransformLLCPriorityToLCSPriority;
