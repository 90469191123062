import { useFormContext } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import jp from "jsonpath";

function HnHSelectInput({ name, required, label, ...rest }) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = jp.value(errors, `$.${name}`);
  return (
    <>
      <select
        className={`form-control ${error ? "is-invalid" : ""}`}
        name={name}
        {...register(name, { required: required || false })}
        {...rest}
      />
      {error && <div className="invalid-feedback">{label} is required</div>}
    </>
  );
}

export default HnHSelectInput;
