import { CCol, CFormGroup, CLabel } from "@coreui/react";
import jp from "jsonpath";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import Select from "react-select";

function HnHReactSelectWithLabel({
  label,
  name,
  options,
  required,
  errorMessage,
  isMulti,
  modal,
  labelColumns,
  fieldColumns,
  manualFormGroup,
  disabled,
  selectAll,
  isResponseToRequisition = false,
  ...rest
}) {
  const methods = useFormContext();
  const {
    control,
    formState: { errors },
  } = methods;
  const error = jp.value(errors, `$.${name}`);
  const darkMode = useSelector((state) => state?.old?.darkMode);
  const _labelColumns = labelColumns ? labelColumns : "3";
  const _fieldColumns = fieldColumns ? fieldColumns : "9";
  options = isMulti ? [{ label: "Select All", value: "all" }, ...options] : options;

  const customOnChange = (selected) => {
    if (isMulti && selected.length && selected.find((option) => option.value === "all")) {
      return options.slice(1);
    } else if (isResponseToRequisition) {
      return selected;
    } else return selected;
  };
  return (
    <>
      {manualFormGroup ? (
        <>
          <CCol md={_labelColumns}>
            <CLabel>{label}</CLabel>
          </CCol>
          <CCol md={_fieldColumns}>
            <>
              {darkMode ? (
                <Controller
                  disabled={disabled || false}
                  name={name}
                  control={control}
                  rules={{ required }}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      isDisabled={disabled || false}
                      {...field}
                      className={`${isMulti ? "basic-multi-select" : ""} ${
                        error ? '"is-invalid red-border' : ""
                      }`}
                      isMulti={!!isMulti}
                      classNamePrefix="select"
                      options={options}
                      onChange={(selectedValue) => {
                        field.onChange(customOnChange(selectedValue));
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary: modal ? "grey" : "white", // the selected option background inside the dropdown
                          primary25: modal ? "silver" : "grey", // Highlighted option background

                          neutral0: modal ? "white" : "#34343b", // sets background color for the outside select bar, and the background of the dropdown
                          neutral10: modal ? "silver" : "grey", // MULTI: The color of the boxes of the selected options outside the dropdown
                          neutral80: modal ? "black" : "white", // Selected text color OUTSIDE the dropdown
                        },
                      })}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      {...rest}
                    />
                  )}
                />
              ) : (
                <Controller
                  disabled={disabled || false}
                  name={name}
                  control={control}
                  rules={{ required }}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      isDisabled={disabled || false}
                      {...field}
                      className={`${isMulti ? "basic-multi-select" : ""} ${
                        error ? '"is-invalid red-border' : ""
                      }`}
                      isMulti={!!isMulti}
                      classNamePrefix="select"
                      onChange={(selectedValue) => {
                        field.onChange(customOnChange(selectedValue));
                      }}
                      options={options}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      {...rest}
                    />
                  )}
                />
              )}
            </>
            {error && (
              <span className="display-invalid-feedback">
                <b>{errorMessage ? `${errorMessage}` : `${label} is required`}</b>
              </span>
            )}
          </CCol>
        </>
      ) : (
        <CFormGroup row>
          {label && (
            <CCol md={_labelColumns}>
              <CLabel>{label}</CLabel>
            </CCol>
          )}

          <CCol md={_fieldColumns}>
            <>
              {darkMode ? (
                <Controller
                  name={name}
                  control={control}
                  rules={{ required }}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      isDisabled={disabled || false}
                      {...field}
                      className={`${isMulti ? "basic-multi-select" : ""} ${
                        error ? '"is-invalid red-border' : ""
                      }`}
                      isMulti={!!isMulti}
                      classNamePrefix="select"
                      options={options}
                      onChange={(selectedValue) => {
                        field.onChange(customOnChange(selectedValue));
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary: modal ? "grey" : "white", // the selected option background inside the dropdown
                          primary25: modal ? "silver" : "grey", // Highlighted option background

                          neutral0: modal ? "white" : "#34343b", // sets background color for the outside select bar, and the background of the dropdown
                          neutral10: modal ? "silver" : "grey", // MULTI: The color of the boxes of the selected options outside the dropdown
                          neutral80: modal ? "black" : "white", // Selected text color OUTSIDE the dropdown
                        },
                      })}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      {...rest}
                    />
                  )}
                />
              ) : (
                <Controller
                  name={name}
                  control={control}
                  rules={{ required }}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      isDisabled={disabled || false}
                      {...field}
                      className={`${isMulti ? "basic-multi-select" : ""} ${
                        error ? '"is-invalid red-border' : ""
                      }`}
                      isMulti={!!isMulti}
                      onChange={(selectedValue) => {
                        field.onChange(customOnChange(selectedValue));
                      }}
                      classNamePrefix="select"
                      options={options}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      {...rest}
                    />
                  )}
                />
              )}
            </>
            {error && (
              <span className="display-invalid-feedback">
                {errorMessage
                  ? `${errorMessage}`
                  : label
                  ? `${label} is required`
                  : "This field is required"}
              </span>
            )}
          </CCol>
        </CFormGroup>
      )}
    </>
  );
}

export default HnHReactSelectWithLabel;
