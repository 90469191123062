import currency from "currency.js";
import _ from "lodash";
const TransformLLCInterestRate = ({ LLCData }) => {
  const typeOfInterestRate = LLCData?.mortgage?.interestRate?.interestRateType?.toLowerCase();
  const fixedInterestRate =
    typeOfInterestRate === "fixed" ? LLCData?.mortgage?.interestRate?.fixedRate : "";
  const variableInterestRateFields = {
    baseRate: _.isNumber(LLCData?.mortgage?.interestRate?.baseRate)
      ? LLCData?.mortgage?.interestRate?.baseRate || ""
      : LLCData?.mortgage?.interestRate?.baseRateText?.textEn || "",
    incrementAboveBelowPrime: LLCData?.mortgage?.interestRate?.incrementAboveBelowPrime || "",
    equivalentRate: LLCData?.mortgage?.interestRate?.equivalentRate || "",
    actualMortgageRate: _.isNumber(LLCData?.mortgage?.interestRate?.actualMortgageRate)
      ? LLCData?.mortgage?.interestRate?.actualMortgageRate || ""
      : LLCData?.mortgage?.interestRate?.actualMortgageRateText?.textEn || "",
    maximumChargeRate: LLCData?.mortgage?.interestRate?.maximumChargeRate || "",
    earlyPaymentAmount: currency(LLCData?.mortgage?.interestRate?.earlyPaymentAmount).value || "",
  };

  return {
    typeOfInterestRate,
    fixedInterestRate,
    variableInterestRateFields,
  };
};

export default TransformLLCInterestRate;
