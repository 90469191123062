import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLISHABLE_KEY } from "src/new-folder-structure/shared/config/stripe";

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

export default function WithStripe(component) {
  return function Wrapper() {
    return <Elements stripe={stripePromise}>{component()}</Elements>;
  };
}
