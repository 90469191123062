const GenerateListOfMortgagorsCorrespondingToLLCTransactionId = ({
  LLCTransactionId,
  LLCTransactionType,
  currentFile,
}) => {
  let listOfMortgagors = [];
  if (LLCTransactionType === "REFINANCE") {
    listOfMortgagors = currentFile?.mortgagor?.mortgagors;
  } else if (LLCTransactionType === "PURCHASE") {
    listOfMortgagors = currentFile?.purchaser?.purchasers;
  }
  return (
    listOfMortgagors?.filter((e) =>
      e?.LLCLookUp?.some((x) => x.LLCTransactionId === LLCTransactionId),
    ) || []
  );
};

export default GenerateListOfMortgagorsCorrespondingToLLCTransactionId;
