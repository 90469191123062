export default function IsAdminUser({ user }) {
  const currentUserEmail = user?.email?.toLowerCase();
  const isSupportUser = user?.role === "supportUser";
  const listOfAdminUsers = [
    "johnfazio23@gmail.com",
    "hasan.issa@hotmail.ca",
    "qa@hnhconsulting.ca",
    "haydar.iss4@gmail.com",
    "arash.noori@hnhconsulting.ca",
    "alexander.jessop@lawyerconveyance.ca",
    "berkay.colak@lawyerconveyance.ca",
    "leo.fonseca@lawyerconveyance.ca",
  ];
  return listOfAdminUsers?.includes(currentUserEmail) || isSupportUser;
}
