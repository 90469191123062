const GenerateFullAddress = ({
  addressLine1,
  addressLine2,
  city,
  province,
  postalCode,
  country,
  pobox,
  unitNumber,
}) => {
  let fullAddress = "";
  if (addressLine2?.length) {
    fullAddress = `${addressLine1 ? `${addressLine1}` : ""}${
      addressLine2 ? ` ${addressLine2}` : ""
    }${unitNumber ? ` Unit ${unitNumber}` : ""}${pobox ? ` PO Box ${pobox}` : ""}${
      city ? ` ${city},` : ""
    }${province ? ` ${province}` : ""}${country ? ` ${country}` : ""}${
      postalCode ? ` ${postalCode}` : ""
    }`;
  } else {
    fullAddress = `${addressLine1 ? `${addressLine1}` : ""}${
      unitNumber ? ` Unit ${unitNumber}` : ""
    }${pobox ? ` PO Box ${pobox}` : ""}${city ? ` ${city},` : ""}${province ? ` ${province}` : ""}${
      country ? ` ${country}` : ""
    }${postalCode ? ` ${postalCode}` : ""}`;
  }
  fullAddress = fullAddress.trim();
  if (fullAddress.slice(-1) === ",") {
    fullAddress = fullAddress.slice(0, -1).trim();
  }
  return fullAddress;
};

export default GenerateFullAddress;
