import _ from "lodash";
import HnHHandleDate from "src/reusable/HnHHandleDate";

// eslint-disable-next-line no-unused-vars
const HandleIdentificationsSection = ({ fullName, birthDate, idVerificationSection }) => {
  const firstIdSection = idVerificationSection?.firstIdType?.value
    ? {
        identificationType: idVerificationSection?.firstIdType?.value
          ? idVerificationSection?.firstIdType?.value
          : null,
        identificationNumber: idVerificationSection?.firstIdNumber
          ? idVerificationSection?.firstIdNumber
          : null,
        expiryDate: idVerificationSection?.firstIdExpiryDate
          ? HnHHandleDate(idVerificationSection?.firstIdExpiryDate, "dashes")
          : null,
        issuingJurisdiction: idVerificationSection?.firstIdPlaceOfIssue
          ? idVerificationSection?.firstIdPlaceOfIssue
          : null,
        verificationDate: idVerificationSection?.idVerificationDate
          ? HnHHandleDate(idVerificationSection?.idVerificationDate, "dashes")
          : null,
        fullName: fullName || null,
        birthdate: birthDate ? HnHHandleDate(birthDate, "dashes") : null,
        nameVerified: _.isDate(HnHHandleDate(idVerificationSection?.idVerificationDate)),
        birthdateVerified: _.isDate(HnHHandleDate(idVerificationSection?.idVerificationDate)),
        issuingCountry: "Canada",
      }
    : null;
  const secondIdSection = idVerificationSection?.secondIdType?.value
    ? {
        identificationType: idVerificationSection?.secondIdType?.value
          ? idVerificationSection?.secondIdType?.value
          : null,
        identificationNumber: idVerificationSection?.secondIdNumber
          ? idVerificationSection?.secondIdNumber
          : null,
        expiryDate: idVerificationSection?.secondIdExpiryDate
          ? HnHHandleDate(idVerificationSection?.secondIdExpiryDate, "dashes")
          : null,
        issuingJurisdiction: idVerificationSection?.secondIdPlaceOfIssue
          ? idVerificationSection?.secondIdPlaceOfIssue
          : null,
        verificationDate: idVerificationSection?.idVerificationDate
          ? HnHHandleDate(idVerificationSection?.idVerificationDate, "dashes")
          : null,
        fullName: fullName || null,
        birthdate: birthDate ? HnHHandleDate(birthDate, "dashes") : null,
        nameVerified: _.isDate(HnHHandleDate(idVerificationSection?.idVerificationDate)),
        birthdateVerified: _.isDate(HnHHandleDate(idVerificationSection?.idVerificationDate)),
        issuingCountry: "Canada",
      }
    : null;
  let returnValueForIndentification = null;
  if (firstIdSection) {
    returnValueForIndentification = [firstIdSection];
  }
  if (secondIdSection) {
    if (Array.isArray(returnValueForIndentification)) {
      returnValueForIndentification = returnValueForIndentification.concat(secondIdSection);
    } else {
      returnValueForIndentification = [secondIdSection];
    }
  }
  return returnValueForIndentification;
};

export default HandleIdentificationsSection;
