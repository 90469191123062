/* eslint-disable @typescript-eslint/no-unused-vars */
import { doc, getDoc } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { useFirestore, useSigninCheck, useUser } from "reactfire";
import LoadingSpinner from "src/new-folder-structure/shared/ui/loading-spinner";
import useTimer from "src/util/useTimer";

// eslint-disable-next-line react/prop-types
function AuthRoute({ children, ...rest }) {
  const { status, data: signInCheckResult } = useSigninCheck();
  const signedOut = useSelector((state) => state?.old?.signedOut);

  // there are two ways, one use useUser hook to get the user from firebase authentication context
  const { status: loadingUser, data: user } = useUser();

  // eslint-disable-next-line no-unused-vars
  const [loadingText, setLoadingText] = useState("Loading...");
  // eslint-disable-next-line no-unused-vars
  const [loadingProgress, setLoadingProgress] = useState(0);
  const db = useFirestore();
  const { wait } = useTimer();
  const shouldAddJitter = false;
  const jitter = 500;
  const dispatch = useDispatch();
  const [fetchingUserData, setFetchingUserData] = useState(true);
  const [fetchingComplete, setFetchingComplete] = useState(false);

  const loadUserData = useCallback(
    async (user) => {
      setFetchingUserData(true);
      setLoadingText("Loading (1/3): user authentication...");
      setLoadingProgress(10);
      if (shouldAddJitter) await wait(jitter);
      const idTokenResult = await user.getIdTokenResult();
      const claims = {
        lawFirmID: idTokenResult.claims.lawFirmID,
        role: idTokenResult.claims.role,
        occupation: idTokenResult.claims.occupation,
        userID: idTokenResult.claims.user_id,
      };
      setLoadingText("Loading (2/3): user data...");
      setLoadingProgress(40);
      if (shouldAddJitter) await wait(jitter);
      const customUserRef = doc(db, `lawFirm/${claims.lawFirmID}/users/${claims.userID}`);
      const customUser = await getDoc(customUserRef).then((doc) => doc.data());

      setLoadingText("Loading (3/3): law firm data...");
      setLoadingProgress(70);
      if (shouldAddJitter) await wait(jitter);
      const lcsUser = {
        ...claims,
        ...customUser,
      };
      const lawFirmRef = doc(db, "lawFirm", claims.lawFirmID);
      const lawFirm = await getDoc(lawFirmRef).then((doc) => doc.data());

      dispatch({
        type: "set",
        payload: { lcsUser, lawFirm },
      });
      setLoadingProgress(100);
      if (shouldAddJitter) await wait(jitter);
      setFetchingUserData(false);
      setFetchingComplete(true);
    },
    [db, dispatch, shouldAddJitter, wait],
  );

  useEffect(() => {
    if (user) loadUserData(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (status === "loading" || loadingUser === "loading") {
    return <LoadingSpinner loadingText="Checking authentication..." />;
  }
  if (signInCheckResult.signedIn === true) {
    if (fetchingComplete) {
      return <Route {...rest}>{children}</Route>;
    }
    if (fetchingUserData) {
      return (
        <LoadingSpinner
          loadingText="Loading user data..."
          // loadingText={loadingText}
          // loadingProgress={loadingProgress}
        />
      );
    }
    return <LoadingSpinner loadingText="Just loading..." />;
  }
  if (signedOut !== undefined) {
    // TODO: fix racing condition, sometimes this doesn't redirect the user
    return (
      <Route
        {...rest}
        render={({ location }) => {
          // console.log("signedOut:", signedOut, ", redirecting to:", location);
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: signedOut ? "/dashboard" : location,
                },
              }}
            />
          );
        }}
      />
    );
  }
  return <LoadingSpinner loadingText="Signing out..." />;
}

export default AuthRoute;
