/* eslint-disable no-unused-vars */
const HandleMappingOfManualTitleSearchesSection = ({ LLCData, currentFile }) => {
  const titleSearchesCurrentlyInLCS = currentFile?.ManualTitleSearches?.titleSearch || [];
  let incomingLLCTitleSearches =
    (LLCData?.properties?.[0]?.propertyIdentificationNumbers &&
      LLCData?.properties?.[0]?.propertyIdentificationNumbers?.map((e, index) => {
        let pinString = e || "";
        let pinStringFiltered = pinString?.replaceAll(" ", "")?.replaceAll("-", "");
        let result = "";
        if (pinString.length > 5) {
          const specIndex = 5;
          result =
            pinStringFiltered?.substring(0, specIndex) +
              "-" +
              pinStringFiltered?.substring(specIndex) || "";
        }
        return {
          uniqueID: Math.random()?.toString(),
          pin: result,
          LLC: {
            LLCData: true,
          },
        };
      })) ||
    [];
  incomingLLCTitleSearches = incomingLLCTitleSearches?.filter((e) => e.pin !== "");

  let finalListOfTitleSearches = [];

  finalListOfTitleSearches = incomingLLCTitleSearches?.reduce(
    (currentLCSTitleSearches, incomingLLCTitleSearch) => {
      if (
        currentLCSTitleSearches?.some(
          (LCSTitleSearch) => LCSTitleSearch?.pin === incomingLLCTitleSearch?.pin,
        ) === false
      ) {
        return currentLCSTitleSearches.concat(incomingLLCTitleSearch);
      }
      return currentLCSTitleSearches;
    },
    titleSearchesCurrentlyInLCS,
  );
  return {
    ...currentFile?.ManualTitleSearches,
    titleSearch: finalListOfTitleSearches || [],
  };
};

export default HandleMappingOfManualTitleSearchesSection;
