import lodash from "lodash-es";
import deepdash from "deepdash-es";

const _ = deepdash(lodash);

const FilterBadData = (data) => {
  let filteredData = "";
  if (data) {
    filteredData = _.filterDeep(data, (value, key, parent) => {
      if (value !== undefined && value !== null) {
        return true;
      }
    });
  }
  return filteredData;
};

export default FilterBadData;
