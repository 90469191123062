import { Suspense } from "react";
import { HashRouter } from "react-router-dom";
import LoadingSpinner from "src/new-folder-structure/shared/ui/loading-spinner";

export default function WithRouter(component) {
  return function Wrapper() {
    return (
      <HashRouter>
        <Suspense fallback={<LoadingSpinner loadingText="Loading route..." />}>
          {component()}
        </Suspense>
      </HashRouter>
    );
  };
}
