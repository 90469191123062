import { withErrorBoundary } from "react-error-boundary";
import ErrorFallback from "src/new-folder-structure/shared/ui/error-fallback";

export default function WithErrorBoundary(component) {
  return withErrorBoundary(component, {
    FallbackComponent: ErrorFallback,
    // onReset: () => setExplode(false),
    // resetKeys: [explode],
  });
}
