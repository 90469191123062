import { useSelector } from "react-redux";
import { GetIconBasedOnStatus } from "src/reusable/GetIconForNavBasedOnStatus";

const _nav = (id, type, pageStatuses) => {
  const currentFile = useSelector((state) => state?.old?.currentFile);

  const realEstateBrokersBeingUsed = currentFile?.property?.realEstateBrokersBeingUsed;
  const propertyIsCondo = currentFile?.property?.propertyIsCondo;
  const propertyPurchaseType = currentFile?.property?.propertyPurchaseType;
  const potl = currentFile?.property?.potl;
  const teranetData = currentFile?.TeranetAPIData;
  const performingTitleSearch = currentFile?.fileOpen?.performingTitleSearch;
  let nameForTitleSearchSideNav = "";
  if (performingTitleSearch === "no") {
    nameForTitleSearchSideNav = "Legal Description";
  } else nameForTitleSearchSideNav = "Perform Title Search";

  const navs = [
    {
      _tag: "CSidebarNavTitle",
      _children: ["Notes"],
    },
    {
      _tag: "CSidebarNavItem",
      name: "Memo",
      to: `/files/${type}/${id}/memos`,
      icon: "cil-bookmark",
    },

    {
      _tag: "CSidebarNavTitle",
      _children: ["Sale"],
    },

    {
      _tag: "CSidebarNavItem",
      name: "File Opening",
      to: `/files/${type}/${id}/fileOpen`,
      icon: GetIconBasedOnStatus({ page: "fileOpen", pageStatuses }),
    },
    {
      _tag: "CSidebarNavItem",
      name: "Municipality",
      to: `/files/${type}/${id}/initial/Municipality`,
      icon: GetIconBasedOnStatus({ page: "Municipality", pageStatuses }),
    },
    {
      _tag: "CSidebarNavItem",
      name: "Vendor",
      to: `/files/${type}/${id}/initial/vendors`,
      icon: GetIconBasedOnStatus({ page: "vendors", pageStatuses }),
    },
    {
      _tag: "CSidebarNavItem",
      name: "Purchaser",
      to: `/files/${type}/${id}/initial/purchasers`,
      icon: GetIconBasedOnStatus({ page: "purchasers", pageStatuses }),
    },
    ...(realEstateBrokersBeingUsed === "yes"
      ? [
          {
            _tag: "CSidebarNavItem",
            name: "Realtor Commission",
            to: `/files/${type}/${id}/initial/broker`,
            icon: GetIconBasedOnStatus({ page: "broker", pageStatuses }),
          },
        ]
      : []),
    ...(propertyIsCondo === "yes" ||
    propertyPurchaseType === "condominium" ||
    potl === "yes" ||
    propertyPurchaseType === "condominiumTownhouse"
      ? [
          {
            _tag: "CSidebarNavItem",
            name: "Condominium Details",
            to: `/files/${type}/${id}/initial/condominium`,
            icon: GetIconBasedOnStatus({ page: "condominium", pageStatuses }),
          },
        ]
      : []),

    {
      _tag: "CSidebarNavItem",
      name: "Property",
      to: `/files/${type}/${id}/initial/property`,
      icon: GetIconBasedOnStatus({ page: "property", pageStatuses }),
    },

    {
      _tag: "CSidebarNavDropdown",
      name: "Title Search",
      route: `/files/${type}/${id}/initial`,
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: nameForTitleSearchSideNav,
          to: `/files/${type}/${id}/initial/teranet`,
          icon: GetIconBasedOnStatus({ page: "teranet", pageStatuses }),
        },
      ],
    },

    {
      _tag: "CSidebarNavItem",
      name: "Existing Encumbrances",
      to: `/files/${type}/${id}/initial/existingEncumbrances`,
      icon: GetIconBasedOnStatus({ page: "existingEncumbrances", pageStatuses }),
    },
    {
      _tag: "CSidebarNavItem",
      name: "Vendor Take-Back Mortgage",
      to: `/files/${type}/${id}/initial/vtbMortgage`,
      icon: GetIconBasedOnStatus({ page: "vtbMortgage", pageStatuses }),
    },

    {
      _tag: "CSidebarNavItem",
      name: "Bills / Holdbacks",
      to: `/files/${type}/${id}/initial/BillsHoldbacks`,
      icon: GetIconBasedOnStatus({ page: "BillsHoldbacks", pageStatuses }),
    },
    {
      _tag: "CSidebarNavItem",
      name: "Executions Affidavits",
      to: `/files/${type}/${id}/closing/executionsAffidavits`,
      icon: GetIconBasedOnStatus({ page: "executionsAffidavits", pageStatuses }),
    },
    {
      _tag: "CSidebarNavItem",
      name: "Undertakings Given",
      to: `/files/${type}/${id}/closing/undertakings`,
      icon: GetIconBasedOnStatus({ page: "undertakings", pageStatuses }),
    },
    {
      _tag: "CSidebarNavItem",
      name: "Response to Requisitions",
      to: `/files/${type}/${id}/closing/responseToRequisitions`,
      icon: GetIconBasedOnStatus({ page: "responseToRequisitions", pageStatuses }),
    },
    // {
    //   _tag: "CSidebarNavItem",
    //   name: "Supplemental Tasks",
    //   to: "/files/" + type + "/" + id + "/closing/supplementalTasks",
    // },
    {
      _tag: "CSidebarNavItem",
      name: "Statement of Account",
      to: `/files/${type}/${id}/closing/statementOfAccount`,
      icon: GetIconBasedOnStatus({ page: "statementOfAccount", pageStatuses }),
    },

    {
      _tag: "CSidebarNavItem",
      name: "Statement of Adjustments",
      to: `/files/${type}/${id}/shared/statementOfAdjustments`,
      icon: GetIconBasedOnStatus({ page: "statementOfAdjustments", pageStatuses }),
    },
    {
      _tag: "CSidebarNavItem",
      name: "Trust Ledger",
      to: `/files/${type}/${id}/closing/trustLedger`,
      icon: GetIconBasedOnStatus({ page: "trustLedger", pageStatuses }),
    },
    {
      _tag: "CSidebarNavItem",
      name: "Direction RE Funds",
      to: `/files/${type}/${id}/closing/directionReFunds`,
      icon: GetIconBasedOnStatus({ page: "directionReFunds", pageStatuses }),
    },
    {
      _tag: "CSidebarNavItem",
      name: "E-Reg Drafts",
      to: `/files/${type}/${id}/documents/eRegistration`,
      icon: GetIconBasedOnStatus({ page: "eRegistration", pageStatuses }),
    },
    {
      _tag: "CSidebarNavItem",
      name: "Upload Documents",
      to: `/files/${type}/${id}/documents/uploadPDFDocs`,
      icon: GetIconBasedOnStatus({ page: "uploadPDFDocs", pageStatuses }),
    },
    {
      _tag: "CSidebarNavItem",
      name: "Generate Documents",
      to: `/files/${type}/${id}/documents/generateDocuments`,
      icon: GetIconBasedOnStatus({ page: "generateDocuments", pageStatuses }),
    },
    {
      _tag: "CSidebarNavItem",
      name: "Finalized Documents",
      to: `/files/${type}/${id}/documents/fileDocumentsList`,
      icon: GetIconBasedOnStatus({ page: "fileDocumentsList", pageStatuses }),
    },
    // {
    //   _tag: "CSidebarNavItem",
    //   name: "Funds Summary",
    //   to: "/files/" + type + "/" + id + "/fundsSummary",
    //   icon: "cib-buffer",
    // },
  ];
  // if (realEstateBrokersBeingUsed === "yes")
  //   navs[3]._children.splice(5, 0, {
  //     _tag: "CSidebarNavItem",
  //     name: "Realtor Commission",
  //     to: "/files/" + type + "/" + id + "/initial/broker",
  //   });
  // if (propertyIsCondo === "yes" && realEstateBrokersBeingUsed === "yes")
  //   navs[3]._children.splice(6, 0, {
  //     _tag: "CSidebarNavItem",
  //     name: "Condominium Details",
  //     to: "/files/" + type + "/" + id + "/initial/condominium",
  //   });
  // if (propertyIsCondo === "yes" && realEstateBrokersBeingUsed !== "yes")
  //   navs[3]._children.splice(5, 0, {
  //     _tag: "CSidebarNavItem",
  //     name: "Condominium Details",
  //     to: "/files/" + type + "/" + id + "/initial/condominium",
  //   });

  const titleSearch = navs.find((e) => e.name === "Title Search");
  titleSearch._children.push({
    _tag: "CSidebarNavItem",
    name: "Perform Writ Search",
    to: `/files/${type}/${id}/initial/teranetWrits`,
    icon: GetIconBasedOnStatus({ page: "teranetWrits", pageStatuses }),
  });
  if (teranetData && Object.keys(teranetData).length) {
    const keys = Object.keys(teranetData);
    keys.forEach((pin) => {
      titleSearch._children.push({
        _tag: "CSidebarNavItem",
        name: `Pin: ${pin}`,
        to: `/files/${type}/${id}/initial/teranetParcelRegister/${pin}`,
        icon: GetIconBasedOnStatus({ page: `teranetParcelRegister${pin}`, pageStatuses }),
      });
    });
  }

  return navs;
};

export default _nav;
