import _ from "lodash";
import GenerateFullNameFromPartOfName from "src/reusable/GenerateFullNameFromPartOfName";
import HnHHandleDate from "src/reusable/HnHHandleDate";

import GenerateListOfMortgagorsCorrespondingToLLCTransactionId from "./GenerateListOfMortgagorsCorrespondingToLLCTransactionId";
import getMortgagorId from "./getMortgagorId";
import HandleIdentificationsSection from "./HandleIdentificationSection";
import HandleIncorporationSection from "./HandleIncorporationSection";
import HandleSignatoriesSection from "./HandleSignatoriesSection";
// import HandleSpouseName from "./HandleSpouseName";
import TransformLCSAddressToLLCAddress from "./TransformLCSAddressToLLCAddress";

// eslint-disable-next-line no-unused-vars
const HandleMappingOfMortgagorSection = ({
  currentFile,
  LLCTransactionType,
  LLCData,
  LLCTransactionId,
}) => {
  if (_.isEmpty(currentFile)) return [];
  const filteredListOfMortgagorsRelatedToCurrentTransactionId =
    GenerateListOfMortgagorsCorrespondingToLLCTransactionId({
      LLCTransactionId,
      LLCTransactionType,
      currentFile,
    });
  return (
    filteredListOfMortgagorsRelatedToCurrentTransactionId?.map((mortgagor) => {
      const mortgagorId = getMortgagorId({
        mortgagor: mortgagor,
        LLCTransactionId: LLCTransactionId,
      });
      const typeOfClient = mortgagor?.clientType === "corporation" ? "BUSINESS" : "PERSON";
      // const { spouseFirstName, spouseMiddleName, spouseLastName } = HandleSpouseName({
      //   mortgagor,
      // });
      const fullName =
        GenerateFullNameFromPartOfName({
          firstName: mortgagor?.firstName,
          middleName: mortgagor?.middleName,
          lastName: mortgagor?.lastName,
        }) || "";
      return {
        id: mortgagorId || "",
        mortgagorType: typeOfClient,
        companyName: typeOfClient?.toLowerCase() === "business" ? mortgagor?.name || "" : null,
        firstName: typeOfClient?.toLowerCase() === "person" ? mortgagor?.firstName || "" : null,
        middleName: typeOfClient?.toLowerCase() === "person" ? mortgagor?.middleName || "" : null,
        lastName: typeOfClient?.toLowerCase() === "person" ? mortgagor?.lastName || "" : null,
        phone: mortgagor?.primaryPhoneNumber?.slice(0, 15) || "",
        businessPhone:
          typeOfClient?.toLowerCase() === "person"
            ? mortgagor?.numberFields?.[0]?.numberFieldsAdditionalNumber?.slice(0, 15) || null
            : mortgagor?.primaryPhoneNumber?.slice(0, 15) || "",
        birthDate:
          typeOfClient?.toLowerCase() === "person"
            ? HnHHandleDate(mortgagor?.birthDate, "dashes") || ""
            : null,
        occupation:
          mortgagor?.clientType === "person" ? mortgagor?.idVerification?.occupation || "" : null,
        // spouseFirstName: typeOfClient?.toLowerCase() === "person" ? spouseFirstName || "" : null,
        // spouseMiddleName: typeOfClient?.toLowerCase() === "person" ? spouseMiddleName || "" : null,
        // spouseLastName: typeOfClient?.toLowerCase() === "person" ? spouseLastName || "" : null,
        address:
          mortgagor?.clientType !== "estate"
            ? TransformLCSAddressToLLCAddress({ address: mortgagor?.addressInfo }) || null
            : null,
        provinceOfIncorporation: typeOfClient?.toLowerCase() === "business" ? "ON" : null,
        incorporation:
          typeOfClient?.toLowerCase() === "business" &&
          (LLCData?.lenderCode === "004" ||
            LLCData?.lenderCode === "-44" ||
            LLCData?.lenderCode === "444")
            ? HandleIncorporationSection({ mortgagor })
            : null,
        identifications:
          mortgagor?.clientType === "person"
            ? HandleIdentificationsSection({
                fullName,
                birthDate: mortgagor?.birthDate,
                idVerificationSection: mortgagor?.idVerification,
              })
            : null,
        signatories:
          mortgagor?.clientType === "corporation"
            ? HandleSignatoriesSection({ entity: mortgagor })
            : null,
      };
    }) || []
  );
};

export default HandleMappingOfMortgagorSection;
