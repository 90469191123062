const HandleTemplateDocumentName = (documentName) => {
  if (!documentName) return "";
  let processedDocumentName = documentName.trim();

  // Remove the last element of the name if it is '/'
  if (processedDocumentName[processedDocumentName.length - 1] === "/")
    processedDocumentName = processedDocumentName.slice(0, -1);

  return processedDocumentName;
};

export default HandleTemplateDocumentName;
