import React, { useState } from "react";
import { useForm, FieldValues } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "src/features/idv/models/verification-update-entity-info.shema";
import { UpdateEntityInfoModalProps } from "src/features/idv/models/entity.models";
import { trpc } from "src/shared/utils/trpc";

import ModalUpdateEntityInfoUI from "./modal-update-entity-info.ui";

const UpdateEntityInfoModal = ({
  showModal,
  setShowModal,
  entityData,
  lawFirmId,
  fileId,
}: UpdateEntityInfoModalProps): JSX.Element => {
  const methods = useForm({ resolver: yupResolver(schema as any) });
  const [showError, setShowError] = useState("");
  const { name: entityName, phone: entityPhone, email: entityEmail, id: entityId } = entityData;

  const util = trpc.useContext();

  const { mutateAsync: updateEntity } = trpc.entityIdv.updateEntity.useMutation({
    onSuccess: () => {
      util.entityIdv.findEntities.invalidate({ lawFirmId, fileId });
    },
  });

  const onSubmit = async (formData: FieldValues) => {
    const { email, phone } = formData;
    const contactInfo = {
      email: email || "",
      phone: phone || "",
    };

    try {
      await updateEntity({ lawFirmId, fileId, entityId, contactInfo });
      setShowModal(false);
    } catch (error: any) {
      setShowError(error.message);
    }
  };

  return (
    <ModalUpdateEntityInfoUI
      showModal={showModal}
      entityName={entityName}
      entityEmail={entityEmail}
      entityPhone={entityPhone}
      methods={methods}
      onSubmit={methods.handleSubmit(onSubmit)}
      closeModal={() => setShowModal(false)}
      showError={showError}
    />
  );
};

export default UpdateEntityInfoModal;
