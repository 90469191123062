import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import FilterBadData from "src/reusable/FilterBadData";

const initialState = {
  sidebarShow: "responsive",
  currentFile: {},
  lawFirm: {},
  isSavingFile: false,
  isSaved: false,
  isLoadingFile: false,
  darkMode: false,
  showCheckList: false,
  signedOut: false,
};

const reduxReducer = (state = initialState, action) => {
  switch (action.type) {
    case "set":
      return { ...state, ...action.payload };
    case "file/loading":
      return { ...state, isLoadingFile: true };
    case "file/loaded":
      return { ...state, currentFile: action.payload, isLoadingFile: false };
    case "file/saved":
      return { ...state, isSavingFile: false };
    case "file/saving":
      return { ...state, isSavingFile: true };
    default:
      return state;
  }
};

// TODO: add combineReducers function to support sub-states/multi-states
export default reduxReducer;

const loadFile = (id, modal) => async (dispatch, getState) => {
  const { lawFirmID } = getState().old.lcsUser;
  if (!modal) {
    dispatch({ type: "file/loading" });
  }
  const ref = doc(getFirestore(), `lawFirm/${lawFirmID}/files/${id}`);
  const file = await getDoc(ref);
  dispatch({ type: "file/loaded", payload: { id: file.id, ...file.data() } });
  // console.log("Loading file : ");
  // console.log(id);
};

const saveFile = (id, fileSection, formData, modal) => async (dispatch, getState) => {
  const { lawFirmID } = getState().old.lcsUser;

  const filteredFormData = FilterBadData(formData);
  // if this doesnt work use getState function and put the user object on redux store
  dispatch({ type: "file/saving" });
  if (!modal) {
    dispatch({
      type: "set",
      payload: { isSaved: false },
    });
  }
  const ref = doc(getFirestore(), `lawFirm/${lawFirmID}/files/${id}`);
  try {
    Object.keys(formData).forEach((key) => {
      if (formData[key] === undefined) formData[key] = "";
    });
    await updateDoc(ref, fileSection, filteredFormData);
    dispatch({ type: "file/saved" });
    if (!modal) {
      dispatch({
        type: "set",
        payload: { isSaved: true },
      });
    }
    dispatch(loadFile(id, modal));
  } catch (e) {
    console.error(e);
    dispatch({
      type: "set",
      payload: { isSavingFile: false, isSaved: false },
    });
    // TODO: add errors to global redux or move to useLoadFile and useSaveFile hooks and abandon Redux
  }
};

export { loadFile, saveFile };
