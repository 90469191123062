/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import _ from "lodash";
import GenerateFullNameFromPartOfName from "src/reusable/GenerateFullNameFromPartOfName";
import HnHHandleDate from "src/reusable/HnHHandleDate";

import GenerateListOfGuarantorsCorrespondingToLLCTransactionId from "./GenerateListOfGuarantorsCorrespondingToLLCTransactionId";
import HandleIdentificationsSection from "./HandleIdentificationSection";
import HandleSignatoriesSection from "./HandleSignatoriesSection";
import TransformLCSAddressToLLCAddress from "./TransformLCSAddressToLLCAddress";

// import TransformLLCAddressObjectToLCSAddressObject from "./TransformLLCAddressObjectToLCSAddressObject";

const HandleMappingOfGuarantorsSection = ({ currentFile, LLCTransactionId }) => {
  if (_.isEmpty(currentFile) || _.isEmpty(currentFile?.newMortgagesFile)) return [];
  const guarantorsTable = GenerateListOfGuarantorsCorrespondingToLLCTransactionId({
    currentFile,
    LLCTransactionId,
  });
  let finalLLCGuarantorsTable = [];
  if (!_.isEmpty(guarantorsTable)) {
    // eslint-disable-next-line no-use-before-define
    finalLLCGuarantorsTable = HandleGuarantorTable({ guarantorsTable });
  }
  return finalLLCGuarantorsTable;
};

const HandleGuarantorTable = ({ guarantorsTable }) => {
  if (_.isEmpty(guarantorsTable)) return [];
  return guarantorsTable?.map((guarantor) => {
    let guarantorType = guarantor?.clientType === "corporation" ? "BUSINESS" : "PERSON";
    const fullName =
      GenerateFullNameFromPartOfName({
        firstName: guarantor?.firstName,
        middleName: guarantor?.middleName,
        lastName: guarantor?.lastName,
      }) || "";
    return {
      id: guarantor?.LLC?.LLC_ID,
      guarantorType,
      companyName: guarantorType?.toLowerCase() === "business" ? guarantor?.name || "" : null,
      firstName: guarantorType?.toLowerCase() === "person" ? guarantor?.firstName || "" : null,
      middleName: guarantorType?.toLowerCase() === "person" ? guarantor?.middleName || "" : null,
      lastName: guarantorType?.toLowerCase() === "person" ? guarantor?.lastName || "" : null,

      phone: guarantor?.primaryPhoneNumber || "",
      businessPhone:
        guarantorType?.toLowerCase() === "person"
          ? guarantor?.numberFields?.[0]?.numberFieldsAdditionalNumber?.slice(0, 15) || null
          : guarantor?.primaryPhoneNumber?.slice(0, 15) || "",
      birthDate:
        guarantorType?.toLowerCase() === "person" && guarantor?.birthDate
          ? HnHHandleDate(guarantor?.birthDate, "dashes") || ""
          : null,
      occupation:
        guarantor?.clientType === "person" ? guarantor?.idVerification?.occupation || "" : null,
      address:
        guarantor?.clientType !== "estate"
          ? TransformLCSAddressToLLCAddress({ address: guarantor?.addressInfo })
          : null,
      identifications:
        guarantor?.clientType === "person"
          ? HandleIdentificationsSection({
              fullName,
              birthDate: guarantor?.birthDate,
              idVerificationSection: guarantor?.idVerification,
            })
          : null,
      signatories:
        guarantor?.clientType === "corporation"
          ? HandleSignatoriesSection({ entity: guarantor })
          : null,
    };
  });
};
export default HandleMappingOfGuarantorsSection;
