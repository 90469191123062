import { collection, doc, setDoc } from "firebase/firestore";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFirestore } from "reactfire";
import FilterBadData from "src/reusable/FilterBadData.js";

const useSaveDocumentToCollection = (nameOfCollection) => {
  const db = useFirestore();
  const { id: urlParamFileID } = useParams();
  const fileID = urlParamFileID;
  const lawFirmID = useSelector((state) => state?.old?.lcsUser?.lawFirmID);
  const [savingFile, setSavingFile] = useState(false);
  const saveFileToCollection = async ({ formData, documentId, saveToFirm, currentFileId }) => {
    // formData: The data to save into the collection as a document
    // documentId: If provided, this will be the ID of the document inside the collection, that will be saved ontop of, if not provided, we will add a new document
    // saveToFirm: If it is true, we will save to a collection at the lawfirm level, if its false or not provided, we will save at the files level inside the firm currently in
    const filteredFormData = FilterBadData(formData);
    const lawfirmReference = `lawFirm/${lawFirmID}`;
    const fileReference = `files/${currentFileId || fileID}`;
    const reference =
      !!saveToFirm && !!documentId
        ? doc(db, `${lawfirmReference}/${nameOfCollection}/${documentId}`)
        : saveToFirm
        ? doc(collection(db, `${lawfirmReference}/${nameOfCollection}`))
        : documentId
        ? doc(db, `${lawfirmReference}/${fileReference}/${nameOfCollection}/${documentId}`)
        : doc(collection(db, `${lawfirmReference}/${fileReference}/${nameOfCollection}`));

    await setDoc(reference, filteredFormData).catch((e) =>
      console.log("Error saving document to collection"),
    );
    setSavingFile(false);
  };
  return { saveFileToCollection, savingFile };
};
export default useSaveDocumentToCollection;
