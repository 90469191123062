const HandleNumberOfUnits = ({ currentFile }) => {
  let numberOfUnits = "";
  if (
    currentFile &&
    (currentFile?.property?.propertyPurchaseType === "multiUnitResidence" ||
      currentFile?.property?.propertyPurchaseType === "Rooming House") &&
    (currentFile?.property?.numberOfUnit === 1 ||
      currentFile?.property?.numberOfUnit === 2 ||
      currentFile?.property?.numberOfUnit === 3 ||
      currentFile?.property?.numberOfUnit === 4 ||
      currentFile?.property?.numberOfUnit === 5 ||
      currentFile?.property?.numberOfUnit === 6)
  ) {
    numberOfUnits = currentFile?.property?.numberOfUnit;
  }
  return numberOfUnits;
};

export default HandleNumberOfUnits;
