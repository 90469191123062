import _ from "lodash";
import GenerateFullNameFromPartOfName from "src/reusable/GenerateFullNameFromPartOfName";
import HnHHandleDate from "src/reusable/HnHHandleDate";
import getMortgagorId from "./getMortgagorId";

import HandleIdentificationsSection from "./HandleIdentificationSection";

const GenerateArrayOfLLCAttorneyObjectsFromLCSEstateEntity = ({ entity, LLCTransactionId }) => {
  if (_.isEmpty(entity)) return [];

  const filteredEstateTrustees = entity?.estateTrustee?.filter((e) => e.isCorporation === "no");

  const finalArrayOfLLCAttorneyObjects = filteredEstateTrustees?.map((trustee) => {
    const fullName = GenerateFullNameFromPartOfName({
      firstName: trustee?.firstName || "",
      middleName: trustee?.middleName || "",
      lastName: trustee?.lastName || "",
    });
    const birthDate = HnHHandleDate(trustee?.birthDate, "dashes");
    const mortgagorId = getMortgagorId({ mortgagor: entity, LLCTransactionId: LLCTransactionId });
    return {
      mortgagorId: mortgagorId,
      fullName: fullName || "",
      birthdate: birthDate || null,
      occupation: trustee?.idVerification?.occupation || null,
      identifications: HandleIdentificationsSection({
        fullName,
        birthDate,
        idVerificationSection: trustee?.idVerification,
      }),
    };
  });
  return finalArrayOfLLCAttorneyObjects || [];
};

export default GenerateArrayOfLLCAttorneyObjectsFromLCSEstateEntity;
