import TransformLLCAddressObjectToLCSAddressObject from "./TransformLLCAddressObjectToLCSAddressObject";
import _ from "lodash";
const HandleMappingOfNewMortgageTable = ({
  LLCData,
  mortgageeName,
  currentLLCMortgageOfThisTransactionId,
  lenderCodes,
}) => {
  let currentMortgagee = currentLLCMortgageOfThisTransactionId?.newMortgageTable?.find(
    (e) => e?.LLC?.LLCTransactionId === LLCData?.transactionId,
  );
  // let mortgageeNameToShow = lenderCodes[LLCData?.mortgage?.mortgagee]?.nameEn || "";
  let mortgageeNameToShow = _.find(
    lenderCodes,
    (e) => e?.lenderCode === LLCData?.mortgage?.mortgagee,
  )?.nameEn;
  const arrayToReturn = [
    currentMortgagee
      ? currentMortgagee
      : {
          // name: "",
          // nameOnFile: "",
          ...(mortgageeNameToShow && { name: mortgageeNameToShow }),
          ...(mortgageeNameToShow && { nameOnFile: mortgageeNameToShow }),
          email: LLCData?.mortgageOwningBranch?.email || "",
          faxNumber: LLCData?.mortgageOwningBranch?.fax || "",
          primaryPhoneNumber: LLCData?.mortgageOwningBranch?.phone || "",
          addressInfo: TransformLLCAddressObjectToLCSAddressObject({
            LLCAddressObject: LLCData?.mortgageOwningBranch?.address,
          }),
          category: "Bank",
          id: Math.random(),
          contactId: Math.random(),
          LLC: { LLCData: true, LLCTransactionId: LLCData?.transactionId },
        },
  ];
  return arrayToReturn;
};

export default HandleMappingOfNewMortgageTable;
