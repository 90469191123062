import _ from "lodash";
export function GetIconBasedOnStatus({ page, pageStatuses }) {
  const status = _.find(pageStatuses, (e) => e?.page === page)?.status || "None";
  switch (status) {
    case "None":
      return "";
    case "Complete":
      return "cilCheck";
    case "Incomplete":
      return "cilX";
    case "Attention":
      return "cilWarning";
    default:
      return "";
  }
}
