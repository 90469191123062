/* eslint-disable no-nested-ternary */
import HandleMappingOfGuarantorsSection from "./HandleMappingOfGuarantorsSection";
import HandleMappingOfNewMortgageTable from "./HandleMappingOfNewMortgageTable";
import HandleMappingOfPrincipalAmount from "./HandleMappingOfPrincipalAmount";
import HandleMultipleDateFields from "./HandleMultipleDateFields";
import TransformLLCInterestRate from "./TransformLLCInterestRate";
import TransformLLCPaymentArray from "./TransformLLCPaymentArray";
import TransformLLCPriorityToLCSPriority from "./TransformLLCPriorityToLCSPriority";
import _ from "lodash";
const HandleMappingOfNewMortgagesSection = ({
  currentFile,
  LLCData,
  principalAmountPassedByTheUser,
  mortgageeName,
  lenderCodes,
}) => {
  let currentArrayOfMortgages = currentFile?.newMortgagesFile?.newMortgages || [];
  let filteredCurrentArrayOfMortgages =
    currentArrayOfMortgages?.filter((e) => {
      return e?.LLC?.LLCTransactionId !== LLCData?.transactionId;
    }) || [];
  // eslint-disable-next-line no-use-before-define
  const currentLLCMortgageOfThisTransactionId = currentArrayOfMortgages?.find(
    (e) => e?.LLC?.LLCTransactionId === LLCData?.transactionId,
  );

  const newLLCMortgageToAdd = CreateLCSMortgageFromLLCData({
    LLCData,
    currentLLCMortgageOfThisTransactionId,
    principalAmountPassedByTheUser,
    mortgageeName: mortgageeName,
    lenderCodes: lenderCodes,
  });

  let finalArrayOfMortgages = [];

  finalArrayOfMortgages = filteredCurrentArrayOfMortgages.concat(newLLCMortgageToAdd);

  return {
    ...(currentFile?.newMortgagesFile || ""),
    newMortgages: finalArrayOfMortgages,
  };
};

const CreateLCSMortgageFromLLCData = ({
  LLCData,
  currentLLCMortgageOfThisTransactionId,
  principalAmountPassedByTheUser,
  mortgageeName,
  lenderCodes,
}) => {
  return {
    ...currentLLCMortgageOfThisTransactionId,
    LLC: {
      LLCData: true,
      LLCTransactionId: LLCData?.transactionId,
      LLC_ID: LLCData?.properties?.[0]?.id,
    },

    ...(!_.isEmpty(LLCData?.bridgeLoan) && {
      bridgeLoan: { ...LLCData?.bridgeLoan, showBridgeLoan: "yes" },
    }),
    ...(_.isEmpty(LLCData?.bridgeLoan) && {
      bridgeLoan: { showBridgeLoan: "no" },
    }),
    newMortgageTable: HandleMappingOfNewMortgageTable({
      LLCData,
      mortgageeName: mortgageeName,
      lenderCodes: lenderCodes,
      currentLLCMortgageOfThisTransactionId,
    }),
    loanNumber: LLCData?.mortgage?.mortgageNumber || "",
    capacity: "Institutional",
    category: "new mortgage",
    amortizationPeriod: LLCData?.mortgage?.amortizationPeriod
      ? `${LLCData?.mortgage?.amortizationPeriod} months`
      : "",
    calculationPeriod: LLCData?.mortgage?.calculationPeriod?.calculationPeriodText?.textEn
      ? LLCData?.mortgage?.calculationPeriod?.calculationPeriodText?.textEn
      : "",
    assignmentofRents:
      LLCData?.mortgage?.assignmentOfRents === true
        ? "yes"
        : LLCData?.mortgage?.assignmentOfRents === false
        ? "no"
        : "",
    principalAmount: HandleMappingOfPrincipalAmount({
      LLCData,
      principalAmountPassedByTheUser,
      currentLLCMortgageOfThisTransactionId,
    }),
    standardChargeTermNo: LLCData?.mortgage?.standardChargeTermText?.textEn
      ? LLCData?.mortgage?.standardChargeTermText?.textEn
      : "",
    lenderFee: LLCData?.mortgageLoanFees?.lenderFee ? LLCData?.mortgageLoanFees?.lenderFee : "",
    ...((LLCData?.lenderCode === "004" ||
      LLCData?.lenderCode === "-44" ||
      LLCData?.lenderCode === "444") && {
      netAdvanceOfFunds: LLCData?.mortgage?.mortgageAmountAdvanced || "",
      calculationMethod: "",
    }),
    ...(LLCData?.lenderCode !== "004" && {
      netAdvanceOfFunds: LLCData?.mortgageLoanFees?.netMortgageAdvance || "",
      calculationMethod: "",
    }),

    ...(HandleMappingOfGuarantorsSection({ LLCData, currentLLCMortgageOfThisTransactionId }) || {}),
    ...(TransformLLCInterestRate({ LLCData }) || {}),
    ...(TransformLLCPaymentArray({ LLCData }) || {}),
    maturityDate: HandleMultipleDateFields({
      LLCData: LLCData?.mortgage,
      nameOfFirstDateField: "maturityDate",
      nameOfSecondDateField: "maturityDateText",
    }),
    balanceDueDate: HandleMultipleDateFields({
      LLCData: LLCData?.mortgage,
      nameOfFirstDateField: "maturityDate",
      nameOfSecondDateField: "maturityDateText",
    }),
    interestAdjustmentDate: HandleMultipleDateFields({
      LLCData: LLCData?.mortgage,
      nameOfFirstDateField: "interestAdjustmentDate",
      nameOfSecondDateField: "interestAdjustmentDateText",
    }),
    correspondencePreference: {
      ...currentLLCMortgageOfThisTransactionId?.correspondencePreference,
      preference:
        currentLLCMortgageOfThisTransactionId?.correspondencePreference?.preference === "" ||
        currentLLCMortgageOfThisTransactionId?.correspondencePreference?.preference === undefined
          ? "directly"
          : currentLLCMortgageOfThisTransactionId?.correspondencePreference?.preference,
    },
    ...(TransformLLCPriorityToLCSPriority({ LLCData }) || ""),
  };
};

export default HandleMappingOfNewMortgagesSection;
