import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { trpc } from "src/shared/utils/trpc";
import EntityListUI from "./entity-list.ui";
import {
  EntityWithReport,
  EntityListProps,
  UseSelectorState,
  IDVEntityReport,
  EntityType,
} from "src/features/idv/models/entity.models";
import { FaUser, FaUserFriends, FaScroll, FaBuilding } from "react-icons/fa";

const EntityList = ({ providerSelection, setProviderSelection }: EntityListProps): JSX.Element => {
  const fileId = useSelector((state: UseSelectorState) => state?.old?.checkListFileId);
  const lawFirmId = useSelector((state: UseSelectorState) => state?.old?.lcsUser?.lawFirmID);
  const [entityListPopulated, setEntityListPopulated] = useState(false);

  const { data: fetchedEntities, isSuccess: isEntitiesSuccess } =
    trpc.entityIdv.findEntities.useQuery({
      lawFirmId,
      fileId,
    });

  const { data: entitiesReportData, isSuccess: isReportsSuccess } =
    trpc.entityIdv.getIDVStatus.useQuery({
      lawFirmId,
      provider: providerSelection,
    });

  const [fileStatusClosed, setFileStatusClosed] = useState(false);
  const [entities, setEntities] = useState<EntityWithReport[]>([]);

  const toggleFileStatus = () => {
    setFileStatusClosed((prev) => !prev);
  };

  const entityTypeIcons: Record<EntityType, JSX.Element> = {
    person: <FaUser className="mr-2" />,
    "estate trustee": <FaScroll style={{ transform: "scaleX(-1)" }} className="mr-2" />,
    "power of attorney": <FaUserFriends className="mr-2" />,
    "signing officer": <FaBuilding className="mr-2" />,
  };

  useEffect(() => {
    const fetchEntityData = async () => {
      const defaultEntityValues: Partial<IDVEntityReport> = {
        entityId: "",
        lcsEntityId: 0,
        reportStatus: undefined,
        reportResultStats: undefined,
        reportFile: undefined,
        reportExpiry: undefined,
        purchaseReport: false,
        verificationLinks: [],
      };

      if (isEntitiesSuccess && isReportsSuccess && fetchedEntities) {
        const merged = fetchedEntities.map((entity) => {
          const existingReport = entitiesReportData
            ? entitiesReportData.currentReportRequests.find(
                (report) => report.lcsEntityId === entity.id,
              )
            : null;

          return {
            ...entity,
            ...(existingReport || defaultEntityValues),
            lcsEntityId: entity.id,
          };
        });
        setEntities(merged as unknown as EntityWithReport[]);
        setEntityListPopulated(true);
      }
    };

    fetchEntityData();
  }, [fetchedEntities, entitiesReportData, providerSelection, isReportsSuccess, isEntitiesSuccess]);

  return (
    <EntityListUI
      lawFirmId={lawFirmId}
      fileId={fileId}
      entities={entities}
      toggleFileStatus={toggleFileStatus}
      fileStatusClosed={fileStatusClosed}
      providerSelection={providerSelection}
      setProviderSelection={setProviderSelection}
      entityTypeIcons={entityTypeIcons}
      entityListPopulated={entityListPopulated}
    />
  );
};

export default EntityList;
