import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { lLCAPISlice } from "src/new-folder-structure/shared/api/llc";
import { offerAPISlice } from "./api";
import {
  lenderCodesAdapter,
  lenderCodesInitialState,
  LLCOffersAdapter,
  LLCOffersInitialState,
} from "./entity-adapter";

// ================================================================
// Selectors
// ================================================================

/**
 * Returns the state that represents the accept offer modal
 * @param {*} state
 * @returns
 */
// we can create custom hooks for our selectors, however this isn't flexible because we won't be able to use it inside of thunk or outside react context
// export const useAcceptOfferModal = () => useSelector((state) => state.offer.acceptOfferModal);
const selectOffer = (state) => state.offer;

export const useSelectAcceptOfferModalState = () =>
  useSelector(createSelector([selectOffer], (offer) => offer.acceptOfferModal));

// Calling `someEndpoint.select(someArg)` generates a new selector that will return
// the query result object for a query with those parameters.
// To generate a selector for a specific query argument, call `select(theQueryArg)`.
// In this case, the users query has no params, so we don't pass anything to select()
const selectLLCOffersResult = lLCAPISlice.endpoints.getLLCOffers.select();
const selectLLCLookupTableResult = lLCAPISlice.endpoints.getLLCLookupTables.select();

export const selectTransactionStatus = (state, transactionId) =>
  lLCAPISlice.endpoints.getTransactionStatus.select({ transactionId })(state)?.data ?? {};

export const selectDocumentsList = (state, transactionId) =>
  lLCAPISlice.endpoints.getDocumentList.select({ transactionId })(state)?.data ?? {};

export const selectUnassignedOffers = (state) =>
  offerAPISlice.endpoints.getUnassignedOffer.select()(state)?.data ?? [];

const selectLenderCodesData = createSelector(
  [selectLLCLookupTableResult],
  (lenderCodesResult) => lenderCodesResult.data,
);

export const selectLenderChangesStatus = createSelector(
  [(state, transactionID) => selectTransactionStatus(state, transactionID)],
  (transactionStatus) => transactionStatus.lenderChanges || false,
);

export const selectLLCPortalStatus = (state) => state?.offer?.lLCPortalStatus;

export const selectLockStatusFromDB = (state) => state?.offer?.getFileLockStatusAsync;

export const selectSameUser = (state) => {
  const { old, offer } = state;

  if (
    offer?.getFileLockStatusAsync?.isLocked &&
    old?.lcsUser?.userID === state?.offer?.getFileLockStatusAsync?.userId
  ) {
    return true;
  } else {
    return false;
  }
};

export const selectLockStatus = createSelector(
  [
    selectLockStatusFromDB,
    (state, transactionID) => selectLenderChangesStatus(state, transactionID),
  ],
  (lockStatus, isLenderChanges) => {
    return lockStatus?.isLocked || isLenderChanges;
  },
);

/*
 * SelectAll returns an array while selectEntitites return an object with key equal to the ID
 */
export const {
  selectAll: selectAllLLCOffers,
  selectById: selectNewMortgageInstructionById,
  selectEntities: selectLLCOffersEntities,
} = LLCOffersAdapter.getSelectors((state) => {
  return selectLLCOffersResult(state)?.data ?? LLCOffersInitialState;
});

export const {
  selectAll: selectAllLenderCodes,
  selectById: selectLenderCodesById,
  selectEntities: selectLenderCodesEntities,
} = lenderCodesAdapter.getSelectors(
  (state) => selectLenderCodesData(state) ?? lenderCodesInitialState,
);

// export const selectAllLLCOffersWithLenderName = createSelector(
//   [selectLLCOffersResult, selectLenderCodesEntities],
//   (LLCOffersResult, lenderCodes) => {
//     const LLCOffers = LLCOffersResult.data;
//     console.log("selectAllLLCOffersWithLenderName", LLCOffers);
//     return LLCOffers?.map((newMortgageInstruction) => ({
//       ...newMortgageInstruction,
//       lawyerFirstName: newMortgageInstruction.lawyer?.firstName,
//       lawyerLastName: newMortgageInstruction.lawyer?.lastName,
//       lenderName: lenderCodes[newMortgageInstruction.lenderCode]?.nameEn || "",
//     }));
//   },
// );

// Back up for above selector
export const selectAllLLCOffersWithLenderName = createSelector(
  [selectAllLLCOffers, selectUnassignedOffers, selectLenderCodesEntities],
  (LLCOffers, unassignedOffers, lenderCodes) => {
    const newOffers = LLCOffers.map((offer1) => {
      return {
        transactionId: offer1.transactionId,
        lenderReferenceNumber: offer1.lenderReferenceNumber,
        closingDate: offer1.closingDate,
        lawyerFirstName: offer1.lawyer?.firstName,
        lawyerLastName: offer1.lawyer?.lastName,
        lenderName: lenderCodes[offer1.lenderCode]?.nameEn || "",
        llcUserName: offer1.lawyer?.userName,
        mortgagorsNames: `${offer1.mortgagor?.firstName} ${offer1.mortgagor?.lastName}`,
        offerAlreadyAccepted: false,
        mortgageeName: lenderCodes[offer1.mortgage?.mortgagee]?.nameEn || "",
        lenderCodes: lenderCodes,
      };
    });
    // const processMortgagorsIntoSingleString = (mortgagors) => {
    //   const mortgagorsNames = mortgagors.map((mortgagor) => {
    //     if (mortgagor.firstName && mortgagor.lastName)
    //       return `${mortgagor.firstName} ${mortgagor.lastName}`;
    //     else return `${mortgagor.companyName}`;
    //   });
    //   let string = "";
    //   if (mortgagorsNames.length === 1) {
    //     string = mortgagorsNames[0];
    //   } else if (mortgagorsNames.length === 2) {
    //     return `${mortgagorsNames[0]} and ${mortgagorsNames[1]}`;
    //   } else {
    //     // if more than two
    //     for (let i = 0; i < mortgagorsNames.length - 1; i++) {
    //       string += `${mortgagorsNames[i]}, `;
    //     }
    //     string += ` and ${mortgagorsNames[mortgagorsNames.length - 1]}`;
    //   }
    //   return string;
    // };

    const _unassignedOffers = unassignedOffers.map((offer2) => {
      return {
        transactionId: offer2.transactionId,
        lenderReferenceNumber: offer2.lenderReferenceNumber,
        closingDate: offer2.mortgage?.closingDate,
        lawyerFirstName: offer2.lawyer?.firstName,
        lawyerLastName: offer2.lawyer?.lastName,
        lenderName: lenderCodes[offer2.lenderCode]?.nameEn || "",
        mortgagorsNames: `${offer2.mortgagors?.[0].firstName} ${offer2.mortgagors?.[0].lastName}`,
        mortgageeName: lenderCodes[offer2.mortgage?.mortgagee]?.nameEn || "",
        llcUserName: offer2.lawyer?.userName,
        offerAlreadyAccepted: true,
        lenderCodes: lenderCodes,
      };
    });
    // const _unassignedOffers = unassignedOffers.map((offer2) => ({
    //   transactionId: offer2.transactionId,
    //   lenderReferenceNumber: offer2.lenderReferenceNumber,
    //   closingDate: offer2.mortgage?.closingDate,
    //   lawyerFirstName: offer2.lawyer?.firstName,
    //   lawyerLastName: offer2.lawyer?.lastName,
    //   lenderName: lenderCodes[offer2.lenderCode]?.nameEn || "",
    //   mortgagorsNames: `${offer2.mortgagors?.[0].firstName} ${offer2.mortgagors?.[0].lastName}`,
    //   mortgageeName: lenderCodes[offer2.mortgage?.mortgagee]?.nameEn || "",
    //   llcUserName: offer2.lawyer?.userName,
    //   offerAlreadyAccepted: true,
    // }));
    return [...newOffers, ..._unassignedOffers];
  },
);
