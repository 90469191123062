import ReactDatePicker from "react-datepicker";
import { useController, useFormContext } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import jp from "jsonpath";
import HnHHandleDate from "./HnHHandleDate";

function DateInput({
  name,
  defaultValue = "",
  disabled = false,
  required = false,
  placeholder = "mm-dd-yyyy",
  label = "",
  className = "",
  ...rest
}) {
  const finalYear = 2038;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = jp.value(errors, `$.${name}`);
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
    defaultValue: defaultValue || "",
    rules: {
      required: required || false,
      shouldUnregister: true,
      validate: (value) => {
        const modifiedValueAsString = HnHHandleDate(value, "string");
        const currentYear = modifiedValueAsString?.split(",")[1]?.replaceAll(" ", "");
        if (currentYear && currentYear > finalYear) {
          return false;
        }
        return true;
      },
    },
  });
  let modifiedValue = "";
  if (value) {
    modifiedValue = HnHHandleDate(value);
  }
  return (
    <>
      <ReactDatePicker
        {...rest}
        className={className || `form-control ${error ? "is-invalid" : ""}`}
        name={name}
        todayButton="Today"
        selected={modifiedValue}
        onChange={onChange}
        placeholderText={placeholder || "mm-dd-yyyy"}
        disabled={disabled}
        autoComplete="off"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
      {error && error.type === "required" && (
        <div className="display-invalid-feedback">{label} is required</div>
      )}
      {error && error.type === "validate" && (
        <div className="display-invalid-feedback">
          Invalid date. Please double check your input.
        </div>
      )}
    </>
  );
}

export default DateInput;
