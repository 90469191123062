import {
  CCreateElement,
  CImg,
  CSidebar,
  CSidebarBrand,
  CSidebarMinimizer,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CSidebarNavTitle,
} from "@coreui/react";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { Star, StarFill } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
// sidebar nav config
import { useLocation } from "react-router-dom";
import { useFirestore, useFirestoreDocData } from "reactfire";

import { useQuery } from "react-query";
import navMortgage from "./navMortgage";
import navPurchase from "./navPurchase";
import navSale from "./navSale";

export default function TheSidebar() {
  // hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const db = useFirestore();

  // local variables
  const queryParams = location.pathname.match(/files\/([a-zA-Z0-9]+)\/([a-zA-Z0-9]+)\/*.*/, "");
  const id = queryParams && queryParams[2];
  const type = queryParams && queryParams[1];

  // selectors
  const show = useSelector((state) => state?.old?.sidebarShow);
  const lawFirmID = useSelector((state) => state?.old?.lcsUser?.lawFirmID);

  // TODO: refactor this to always subscribe to any page editing file, or using redux...
  // firestore
  const fileDoc = doc(db, `lawFirm/${lawFirmID}/files/${id}`);
  const { data: file } = useFirestoreDocData(fileDoc);

  const getPageStatuses = async () => {
    const documentSnapshot = await getDocs(
      collection(db, `lawFirm/${lawFirmID}/files/${id}/pageStatuses`),
    );
    const pageStatuses = documentSnapshot.docs.map((documentSnapshot) => {
      return { ...documentSnapshot.data(), id: documentSnapshot.id };
    });
    return pageStatuses;
  };

  const { data: pageStatuses } = useQuery({
    queryKey: "pageStatuses",
    queryFn: getPageStatuses,
  });
  let navItems = null;

  switch (type) {
    case "Mortgage": {
      navItems = navMortgage(id, type, pageStatuses);
      break;
    }
    case "Sale": {
      navItems = navSale(id, type, pageStatuses);
      break;
    }
    case "Purchase": {
      navItems = navPurchase(id, type, pageStatuses);
      break;
    }
    default:
      break;
  }

  // local functions
  const toggleFileBookmark = () => {
    if (!lawFirmID || !file) return;
    const ref = doc(db, `lawFirm/${lawFirmID}/files/${id}`);
    updateDoc(ref, { bookmarked: !file.bookmarked });
  };

  return (
    <CSidebar
      show={show}
      onShowChange={(val) => dispatch({ type: "set", payload: { sidebarShow: val } })}
      dropdownMode="noAction"
    >
      <CSidebarBrand className="d-md-down-none" to="/">
        <CImg src="logo.svg" alt="lawyerConveyance" height={35} />
      </CSidebarBrand>
      <CSidebarNav>
        <CSidebarNavItem icon="cil-home" name="Home" to="/" />
        <CSidebarNavItem
          icon={
            file?.bookmarked ? (
              <span className="mr-4">
                <StarFill className="mr-4" />
              </span>
            ) : (
              <Star className="mr-4" />
            )
          }
          name={file?.bookmarked ? "Bookmarked" : "Add bookmark"}
          onClick={toggleFileBookmark}
        />
        <CCreateElement
          items={navItems}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
}
