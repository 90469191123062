/* eslint-disable no-nested-ternary */
import TransformLLCMortgagorToLCSMortgagor from "./TransformLLCMortgagorToLCSMortgagor";
const HandleMappingOfMortgagorSection = ({
  LLCData,
  solicitorObject,
  currentFile,
  typeOfCurrentFile,
}) => {
  const isMortgageFile = typeOfCurrentFile === "Mortgage";
  const resultOfHandleMappingOfMortgagorSection = {
    ...(currentFile?.mortgagor || ""),
    solicitor: isMortgageFile === true ? solicitorObject || "" : "",
    lawyerOnFile:
      isMortgageFile === true ? `${solicitorObject?.firstName}${solicitorObject?.lastName}` : "",
    mortgagors: HandleMappingOfMortgagorsTable({
      currentFile,
      LLCData,
      isMortgageFile,
    }),
  };
  return resultOfHandleMappingOfMortgagorSection;
};
const HandleMappingOfMortgagorsTable = ({ currentFile, LLCData, isMortgageFile }) => {
  const currentMortgagorsTable = currentFile?.mortgagor?.mortgagors || [];
  if (isMortgageFile === false) return currentMortgagorsTable;
  const currentMortgagorTableWithoutLLCMortgagors =
    currentMortgagorsTable?.filter((mortgagor) => !mortgagor?.LLC) || [];
  let finalMortgagorsTable = currentMortgagorTableWithoutLLCMortgagors || [];
  let LLC_Mortgagors =
    Array.isArray(LLCData?.mortgagors) &&
    LLCData?.mortgagors?.map((mortgagorObject) => {
      let currentMortgagorData =
        currentMortgagorsTable?.find((e) => {
          return e?.LLCLookUp?.some((x) => {
            return (
              String(x?.LLC_ID) === String(mortgagorObject?.id) &&
              String(x?.LLCTransactionId) === String(LLCData?.transactionId)
            );
          });
        }) || {};
      return TransformLLCMortgagorToLCSMortgagor({
        mortgagorObject,
        LLCData,
        currentMortgagorData,
      });
    });
  finalMortgagorsTable = finalMortgagorsTable?.concat(LLC_Mortgagors) || [];
  return finalMortgagorsTable;
};

export default HandleMappingOfMortgagorSection;
