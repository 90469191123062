const GetMortgagePriority = ({ LLCMortgage }) => {
  const mortgagePriority = LLCMortgage?.priorityNumber || "";
  let LLCPriority = "";
  switch (mortgagePriority) {
    case 1:
      LLCPriority = "FIRST";
      break;
    case 2:
      LLCPriority = "SECOND";
      break;
    case 3:
      LLCPriority = "THIRD";
      break;
    case 4:
      LLCPriority = "FOURTH";
      break;
    case 5:
      LLCPriority = "FIFTH";
      break;
    default:
      LLCPriority = "FIRST";
  }
  return LLCPriority;
};

export default GetMortgagePriority;
