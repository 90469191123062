const getTeranetPINS = (teranetAPIData) => {
  let pins = [];
  if (teranetAPIData && Object.keys(teranetAPIData)?.length > 0) {
    Object.keys(teranetAPIData)?.forEach((item) => {
      if (item) pins = pins.concat(item);
    });
  }
  return pins;
};
const getManualPINS = (manualTitleSearches) => {
  let pins = [];
  if (
    manualTitleSearches &&
    Array.isArray(manualTitleSearches) &&
    manualTitleSearches?.length > 0
  ) {
    manualTitleSearches?.forEach((entry) => {
      if (entry?.pin?.length > 0) pins = pins.concat(entry?.pin);
    });
  }
  return pins;
};
const HandlePropertyIdentificationNumbers = ({ currentFile }) => {
  const performingTitleSearch = currentFile?.fileOpen?.performingTitleSearch;
  let pins = [];
  if (performingTitleSearch === "yes") {
    pins = getTeranetPINS(currentFile?.TeranetAPIData);
  } else {
    pins = getManualPINS(currentFile?.ManualTitleSearches?.titleSearch);
  }
  return pins;
};

export default HandlePropertyIdentificationNumbers;
