/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
import _ from "lodash";

const HandleMappingOfEncumbrancesSection = ({ currentFile, LLCData }) => {
  const currentArrayOfEncumbrances = currentFile?.existingEncumbrances?.encumbrances || [];

  let filteredCurrentArrayOfEncumbrances =
    currentArrayOfEncumbrances?.filter((e) => {
      return e?.LLC?.LLCTransactionId !== LLCData?.transactionId;
    }) || [];
  // eslint-disable-next-line no-use-before-define
  const currentLLCEncumbranceOfThisTransactionId = currentArrayOfEncumbrances?.find(
    (e) => e?.LLC?.LLCTransactionId === LLCData?.transactionId,
  );
  const newLLCEncumbranceToAdd = CreateLCSEncumbranceFromLLCData({
    LLCData,
    currentLLCEncumbranceOfThisTransactionId,
  });

  let finalArrayOfEncumbrances = [];
  finalArrayOfEncumbrances = filteredCurrentArrayOfEncumbrances.concat(newLLCEncumbranceToAdd);

  return {
    ...(currentFile?.existingEncumbrances || ""),
    encumbrances: finalArrayOfEncumbrances,
  };
};

const CreateLCSEncumbranceFromLLCData = ({ LLCData, currentLLCEncumbranceOfThisTransactionId }) => {
  if (_.isEmpty(LLCData?.properties?.[0]?.existingMortgages)) return [];
  const LLCTransactionId = LLCData?.transactionId;
  return LLCData?.properties?.[0]?.existingMortgages?.map((encumbrance) => {
    let currentEncumbranceEntityInTableFromLLC =
      currentLLCEncumbranceOfThisTransactionId?.encumbrancesTable?.[0] || {};
    return {
      ...currentLLCEncumbranceOfThisTransactionId,
      LLC: {
        LLCData: true,
        LLCTransactionId,
      },
      category: {
        label: "Charge / Mortgage",
        value: "Mortgage",
      },
      capacity: "institution",
      encumbranceIsTo:
        encumbrance?.action?.toLowerCase() === "discharge" ? "be discharged" : "remain",
      priority: encumbrance?.action?.toLowerCase() === "postponed" ? "2nd" : "",
      amountDueForPayout: encumbrance?.amount || 0,
      encumbrancesTable: [
        {
          ...currentEncumbranceEntityInTableFromLLC,
          LLC: { LLCData: true, LLCTransactionId },
          contactId: Math.random(),
          category: "Bank",
          nameOnFile: encumbrance?.mortgagee || "",
          name: encumbrance?.mortgagee || "",
        },
      ],
    };
  });
};

export default HandleMappingOfEncumbrancesSection;
