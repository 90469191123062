import { collection } from "firebase/firestore";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

const useGetCollection = ({ nameOfCollection, saveToFirm, providedLawFirmId }) => {
  const db = useFirestore();
  const { id: urlParamFileID } = useParams();
  const fileID = urlParamFileID;
  const lawFirmID = useSelector((state) => state?.old?.lcsUser?.lawFirmID);
  const lawFirmIDToUse = providedLawFirmId ? providedLawFirmId : lawFirmID;
  const lawFirmCollection = saveToFirm
    ? collection(db, `lawFirm/${lawFirmIDToUse}/${nameOfCollection}`)
    : collection(db, `lawFirm/${lawFirmIDToUse}/files/${fileID}/${nameOfCollection}`);
  const { status, data: collectionData, error } = useFirestoreCollectionData(lawFirmCollection);
  return { error, collectionData, status };
};

export default useGetCollection;
