import { useController, useFormContext } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import jp from "jsonpath";

import { CButton, CButtonToolbar, CTooltip } from "@coreui/react";
import { useSelector } from "react-redux";

function HnHToggleWithoutLabel({
  name,
  buttons,
  required = false,
  errorMessage = "",
  defaultValue = "",
  disabled = false,
  modal = false,
  selectedBtnColor = "primary",
}) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    field: { value: buttonSelected, onChange: setButtonSelected },
  } = useController({
    name,
    control,
    defaultValue: defaultValue || "",
    rules: { required: required || false, shouldUnregister: true },
  });
  const error = jp.value(errors, `$.${name}`);
  const darkMode = useSelector((state) => state?.old?.darkMode);

  return (
    <>
      <CButtonToolbar className="is-invalid">
        {buttons.map((aButton) => {
          return (
            <div key={aButton.value}>
              {aButton.toolTip ? (
                <CTooltip content={`${aButton.toolTip}`} placement="top">
                  <CButton
                    className={`${
                      error ? "border border-danger" : modal ? "toggle-inside-modal" : ""
                    }`}
                    value={aButton.value}
                    onClick={() => {
                      setButtonSelected(aButton.value);
                    }}
                    color={buttonSelected === aButton.value ? `${selectedBtnColor}` : ""}
                    disabled={disabled}
                  >
                    {modal && buttonSelected === aButton.value ? (
                      <span style={{ color: "white" }}>{aButton.name}</span>
                    ) : modal && buttonSelected !== aButton.value ? (
                      <span style={{ color: "black" }}>{aButton.name}</span>
                    ) : darkMode ? (
                      <span style={{ color: "white" }}>{aButton.name}</span>
                    ) : (
                      <span style={{ color: "" }}>{aButton.name}</span>
                    )}
                  </CButton>
                </CTooltip>
              ) : (
                <CButton
                  className={`${
                    error ? "border border-danger" : modal ? "toggle-inside-modal" : ""
                  }`}
                  value={aButton.value}
                  onClick={() => {
                    setButtonSelected(aButton.value);
                  }}
                  color={buttonSelected === aButton.value ? `${selectedBtnColor}` : ""}
                  disabled={disabled}
                >
                  {modal && buttonSelected === aButton.value ? (
                    <span style={{ color: "white" }}>{aButton.name}</span>
                  ) : modal && buttonSelected !== aButton.value ? (
                    <span style={{ color: "black" }}>{aButton.name}</span>
                  ) : darkMode ? (
                    <span style={{ color: "white" }}>{aButton.name}</span>
                  ) : (
                    <span style={{ color: "" }}>{aButton.name}</span>
                  )}
                </CButton>
              )}
            </div>
          );
        })}
      </CButtonToolbar>
      {error && (
        <div className="invalid-feedback">{errorMessage || "Please select one of the options"}</div>
      )}
    </>
  );
}

export default HnHToggleWithoutLabel;
