import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  LCSFilePrevData: null,
  currentFileID: null,
};

export const LCSFileSlice = createSlice({
  name: "LCSFileSlice",
  initialState,
  reducers: {
    updateLCSPrevData: (state, action) => {
      state.LCSFilePrevData = action.payload;
    },
    setCurrentFileID: (state, action) => {
      state.currentFileID = action.payload;
    },
  },
});

export const { updateLCSPrevData, setCurrentFileID } = LCSFileSlice.actions;

export const selectCurrentFileID = (state) => state.LCSFileSlice.currentFileID;
