import { createApi } from "@reduxjs/toolkit/query/react";
import { lLCAPISlice } from "src/new-folder-structure/shared/api/llc";
import FirebaseFirestoreBaseQuery from "src/new-folder-structure/shared/lib/redux-base-query/redux-firestore-base-query";
import TransformLCSDataToLLC from "src/new-folder-structure/shared/lib/LLC/mapping/TransformLCSDataToLLC/TransformLCSDataToLLC";
import { updateLCSPrevData } from "src/new-folder-structure/entities/lcs-file/api/slice";
import { detailedDiff } from "deep-object-diff";
import _ from "lodash";
import { selectLCSFileByID } from "src/new-folder-structure/entities/lcs-file/api";
import { db } from "src/new-folder-structure/shared/config/firebase";
import { collection, getDocs, query, updateDoc, where, doc } from "firebase/firestore";
import { selectLawFirmID } from "../../law-firm/model/api";

// This is an LCS API to call firestore or any other Firebase service that is specific to offers
// TODO: analyze if this should be moved to shared/api/ folder

export const offerAPISlice = createApi({
  reducerPath: "offerAPI",
  baseQuery: FirebaseFirestoreBaseQuery(),
  // tagTypes: [""],
  endpoints: (builder) => ({
    setFileLock: builder.mutation({
      query: ({ lawFirmID, fileID, lockData }) => ({
        path: `lawFirm/${lawFirmID}/files/${fileID}`,
        method: "updateDoc",
        isDocument: true,
        data: { fileLockStatus: { ...lockData } },
      }),
    }),
    updateLLCData: builder.mutation({
      query: ({ lawFirmID, fileID, LLCData }) => ({
        path: `lawFirm/${lawFirmID}/files/${fileID}`,
        method: "updateDoc",
        isDocument: true,
        data: { LLCData },
      }),
    }),
    sendLLCData: builder.query({
      queryFn: async ({ fileID, transactionId }, { getState, dispatch }) => {
        try {
          const state = getState();
          const fileData = selectLCSFileByID(state, fileID);
          const LLCDataForThisTransactionId = await dispatch(
            lLCAPISlice.endpoints.getTransactionData.initiate(
              { transactionId: transactionId },
              { forceRefetch: true },
            ),
          ).unwrap();
          const mappedLLCData = TransformLCSDataToLLC({
            currentFile: fileData,
            LLCData: LLCDataForThisTransactionId,
          });
          console.log("About to send update with:", mappedLLCData);

          const prevMappedLLCData = state.LCSFileSlice.LCSFilePrevData;
          if (!prevMappedLLCData) {
            dispatch(updateLCSPrevData(mappedLLCData));
            await dispatch(
              lLCAPISlice.endpoints.updateTransactionData.initiate({
                transactionData: mappedLLCData,
              }),
            ).unwrap();
            console.log("Dispatched update LLC data with:", mappedLLCData);
          } else {
            const diff = detailedDiff(mappedLLCData, prevMappedLLCData);
            // console.log(
            //   "deep comparison result",
            //   !_.isEmpty(diff.added) || !_.isEmpty(diff.updated) || !_.isEmpty(diff.deleted),
            // );
            if (!_.isEmpty(diff.added) || !_.isEmpty(diff.updated) || !_.isEmpty(diff.deleted)) {
              await dispatch(
                lLCAPISlice.endpoints.updateTransactionData.initiate(
                  { transactionData: mappedLLCData },
                  { forceRefetch: true },
                ),
              ).unwrap();
              console.log("Dispatched update LLC data with:", mappedLLCData);
              dispatch(updateLCSPrevData(mappedLLCData));
            }
          }
          return {
            data: {},
          };
        } catch (error) {
          console.log("Error during SendLLCData Thunk", error);
          return {
            error,
          };
        }
      },
    }),
    assignLLCOfferToLCSFile: builder.mutation({
      query: ({ lawFirmID, fileID, transactionId }) => ({
        path: `lawFirm/${lawFirmID}/llc-offers/${transactionId}`,
        method: "updateDoc",
        isDocument: true,
        data: { LCSFileID: fileID },
      }),
    }),
    unassignLLCOffersFromLCSFile: builder.mutation({
      queryFn: async ({ lawFirmID, fileID }) => {
        const llcOffersRef = collection(db, `lawFirm/${lawFirmID}/llc-offers`);
        const q = query(llcOffersRef, where("LCSFileID", "==", fileID));
        const querySnapshot = await getDocs(q);

        querySnapshot?.docs?.forEach((docSnapshot) => {
          const docId = docSnapshot.id;
          const updatedData = { ...docSnapshot.data(), LCSFileID: null };
          const docToDeleteRef = doc(db, `lawFirm/${lawFirmID}/llc-offers/${docId}`);
          updateDoc(docToDeleteRef, updatedData);
        });
        return { data: {} };
      },
    }),
    getUnassignedOffer: builder.query({
      queryFn: async (_args, { getState }) => {
        try {
          const state = getState();
          const lawFirmID = selectLawFirmID(state);
          const llcOffersRef = collection(db, `lawFirm/${lawFirmID}/llc-offers`);
          const q = query(
            llcOffersRef,
            where("LCSFileID", "==", null),
            where("dealStatus", "==", "ACTIVE"),
          );
          const querySnapshot = await getDocs(q);
          const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

          return { data };
        } catch (error) {
          console.error("error getUnassignedOffer:", error);
          return { error };
        }
      },
    }),
  }),
});

export const {
  useGetLenderChangesQuery,
  useLazyGetLenderChangesQuery,
  useGetLLCOffersQuery,
  useLazyGetLLCOffersQuery,
  useGetTransactionStatusQuery,
  useLazyGetTransactionStatusQuery,
  useGetLLCLookupTablesQuery,
  useLazyGetLLCLookupTablesQuery,
  useGetTransactionDataQuery,
  useLazyGetTransactionDataQuery,
  useGetLLCPortalDealURLQuery,
  useLazyGetLLCPortalDealURLQuery,
  useGetDocumentListQuery,
  useGetSingleDocumentQuery,
  // no need to make lazy mutations as they return a trigger function
  useUpdateLenderChangesMutation,
  useDeclineOfferMutation,
  useUpdateTransactionDataMutation,
  useGetAllTransactionsLenderChangeStatusesQuery,
  useLazyGetAllTransactionsLenderChangeStatusesQuery,
  useGetReturnsPlatformMetadataQuery,
  useLazyGetLLCProfileUrlQuery,
  useGetLLCProfileUrlQuery,
} = lLCAPISlice;

export const {
  useSetFileLockMutation,
  useAssignLLCOfferToLCSFileMutation,
  useUnassignLLCOffersFromLCSFileMutation,
  useGetUnassignedOfferQuery,
} = offerAPISlice;
