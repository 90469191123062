import _ from "lodash";
import HnHHandleDate from "src/reusable/HnHHandleDate";
import Money from "src/reusable/Money";

const TransformLLCPaymentArray = ({ LLCData }) => {
  let finalObjectToReturn = {};
  const LLCPaymentArray = LLCData?.mortgage?.mortgagePayments;
  const LLCPaymentObject = LLCPaymentArray?.[0];
  const LLCAlternatePaymentObject = LLCPaymentArray?.[1];
  const LLCRegistrationPaymentFreq = LLCData?.mortgage?.registrationPaymentFreq?.toLowerCase();
  let paymentFrequency = "";
  let firstPaymentDate = "";
  let paymentsDue = "";
  let propertyTaxesPaidThroughMortgageToggle = "";
  let propertyTaxesPaidThroughMortgageTaxPortion = "";

  let alternateFrequencyToggleValue = "";
  // let alternatepaymentFrequency = "";
  let alternatefirstPaymentDate = "";
  let alternatepaymentsDue = "";
  let alternatepropertyTaxesPaidThroughMortgageToggle = "";
  let alternatepropertyTaxesPaidThroughMortgageTaxPortion = "";
  if (!_.isEmpty(LLCPaymentObject)) {
    if (LLCRegistrationPaymentFreq === "monthly") {
      paymentFrequency = "Monthly";
    } else if (LLCRegistrationPaymentFreq === "more frequent") {
      paymentFrequency = "";
    } else if (LLCRegistrationPaymentFreq === "weekly") {
      paymentFrequency = "Weekly";
    } else if (LLCRegistrationPaymentFreq === "biweekly") {
      paymentFrequency = "Bi-weekly";
    } else if (LLCRegistrationPaymentFreq === "semimonthly") {
      paymentFrequency = "Semi-monthly";
    } else if (LLCRegistrationPaymentFreq === "see schedule") {
      paymentFrequency = "seeSchedule";
    }

    if (LLCPaymentObject?.firstPaymentDate) {
      firstPaymentDate = HnHHandleDate(LLCPaymentObject?.firstPaymentDate) || "";
    } else if (LLCPaymentObject?.firstPaymentDateText?.textEn) {
      firstPaymentDate = HnHHandleDate(LLCPaymentObject?.firstPaymentDateText?.textEn) || "";
    }
    if (LLCPaymentObject?.paymentDateAndPeriodText?.textEn)
      paymentsDue = LLCPaymentObject?.paymentDateAndPeriodText?.textEn;

    if (LLCPaymentObject?.propertyTaxAmount) {
      propertyTaxesPaidThroughMortgageToggle = "yes";
      propertyTaxesPaidThroughMortgageTaxPortion = Money(
        LLCPaymentObject?.propertyTaxAmount,
        "none",
      );
    }
  }

  if (!_.isEmpty(LLCAlternatePaymentObject)) {
    alternateFrequencyToggleValue = "yes";
    // if (LLCAlternatePaymentObject?.MortgagePaymentType?.toLowerCase() === "monthly") {
    //   alternatepaymentFrequency = "Monthly";
    // } else if (LLCAlternatePaymentObject?.MortgagePaymentType?.toLowerCase() === "more frequent") {
    //   if (LLCAlternatePaymentObject?.paymentFrequency?.toLowerCase() === "weekly") {
    //     alternatepaymentFrequency = "Weekly";
    //   } else if (LLCAlternatePaymentObject?.paymentFrequency?.toLowerCase() === "bi_weekly") {
    //     alternatepaymentFrequency = "Bi-weekly";
    //   } else if (LLCAlternatePaymentObject?.paymentFrequency?.toLowerCase() === "semi_monthly") {
    //     alternatepaymentFrequency = "Semi-monthly";
    //   } else if (LLCAlternatePaymentObject?.paymentFrequency?.toLowerCase() === "other") {
    //     alternatepaymentFrequency = "seeSchedule";
    //   }
    // }
    if (LLCAlternatePaymentObject?.firstPaymentDate) {
      alternatefirstPaymentDate = HnHHandleDate(LLCAlternatePaymentObject?.firstPaymentDate) || "";
    } else if (LLCAlternatePaymentObject?.firstPaymentDateText?.textEn) {
      alternatefirstPaymentDate =
        HnHHandleDate(LLCAlternatePaymentObject?.firstPaymentDateText?.textEn) || "";
    }
    if (LLCAlternatePaymentObject?.paymentDateAndPeriodText?.textEn)
      alternatepaymentsDue = LLCAlternatePaymentObject?.paymentDateAndPeriodText?.textEn;
    if (LLCAlternatePaymentObject?.propertyTaxAmount) {
      alternatepropertyTaxesPaidThroughMortgageToggle = "yes";
      alternatepropertyTaxesPaidThroughMortgageTaxPortion = LLCPaymentObject?.propertyTaxAmount;
    }
  }
  finalObjectToReturn = {
    paymentFrequency,
    regularPayments: LLCPaymentObject?.paymentAmount || "",
    firstPaymentDate,
    paymentsDue,
    propertyTaxesPaidThroughMortgage: propertyTaxesPaidThroughMortgageToggle,
    taxPortion: propertyTaxesPaidThroughMortgageTaxPortion,

    alternateFrequency: alternateFrequencyToggleValue,
    // alternatepaymentFrequency,
    alternatefirstPaymentDate,
    alternatepaymentsDue,
    alternateregularPayments: LLCAlternatePaymentObject?.paymentAmount || "",
    alternatepropertyTaxesPaidThroughMortgage: alternatepropertyTaxesPaidThroughMortgageToggle,
    alternatetaxPortion: alternatepropertyTaxesPaidThroughMortgageTaxPortion,
  };
  return finalObjectToReturn;
};
export default TransformLLCPaymentArray;
