import currency from "currency.js";
import _ from "lodash";
import HnHHandleDate from "src/reusable/HnHHandleDate";

import HandleMappingOfAttorneysSection from "./helper-functions/HandleMappingOfAttorneysSection";
import HandleMappingOfGuarantorsSection from "./helper-functions/HandleMappingOfGuarantorsSection";
import HandleMappingOfMortgagorSection from "./helper-functions/HandleMappingOfMortgagorSection";
import HandleMappingOfPropertiesSection from "./helper-functions/HandleMappingOfPropertiesSection";
import HandleTitleInsurancePoliciesSection from "./helper-functions/HandleTitleInsurancePoliciesSection";
import HandleVendorSolicitorSection from "./helper-functions/HandleVendorSolicitorSection";
import handleDataTrimming from "./helper-functions/handleDataTrimming";

const TransformLCSDataToLLC = ({ LLCData, currentFile }) => {
  if (_.isEmpty(currentFile)) return {};
  const LLCTransactionId = LLCData?.transactionId;
  const LLCPropertyId = LLCData?.properties?.[0]?.id;
  const LLCTransactionType =
    currentFile?.createFile?.fileType === "Purchase" ? "PURCHASE" : "REFINANCE";
  let finalLLCDataToReturn = {
    transactionId: LLCTransactionId || "",
    transactionType: LLCTransactionType,
    closingDate: HnHHandleDate(currentFile?.createFile?.closingDate, "dashes") || null,
    lawyerMatterNumber: currentFile?.createFile?.fileNumber || null,
    purchasePrice:
      currentFile?.createFile?.fileType === "Purchase"
        ? currency(currentFile?.propertyPrice?.priceInput)?.value || null
        : currency(currentFile?.property?.appraisalAmount)?.value || null,

    mortgagors: HandleMappingOfMortgagorSection({
      currentFile,
      LLCTransactionType,
      LLCData,
      LLCTransactionId,
    }),
    guarantors: HandleMappingOfGuarantorsSection({ currentFile, LLCTransactionId }) || null,
    attorneys:
      HandleMappingOfAttorneysSection({ currentFile, LLCTransactionId, LLCTransactionType }) ||
      null,
    vendorSolicitor: HandleVendorSolicitorSection({ currentFile }) || null,
    titleInsurancePolicies:
      HandleTitleInsurancePoliciesSection({ currentFile, LLCTransactionId }) || null,

    properties: HandleMappingOfPropertiesSection({ currentFile, LLCTransactionId, LLCPropertyId }),
  };
  finalLLCDataToReturn = JSON.parse(JSON.stringify(finalLLCDataToReturn), (key, value) => {
    if (
      (Array.isArray(value) && value.length === 0) ||
      value === undefined ||
      value === "" ||
      value === [undefined] ||
      value === [null]
    )
      return null;
    return value;
  });
  const trimmedFinalLLCDataToRetun = handleDataTrimming({ transformedData: finalLLCDataToReturn });
  return trimmedFinalLLCDataToRetun;
};
export default TransformLCSDataToLLC;
