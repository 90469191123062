import GenerateFullNameFromPartOfName from "src/reusable/GenerateFullNameFromPartOfName";
import TransformLLCAddressObjectToLCSAddressObject from "./TransformLLCAddressObjectToLCSAddressObject";

export default function TransformLLCMortgagorToLCSMortgagor({
  mortgagorObject,
  LLCData,
  currentMortgagorData,
}) {
  const clientType =
    mortgagorObject?.mortgagorType?.toLowerCase() === "person"
      ? "person"
      : mortgagorObject?.mortgagorType?.toLowerCase() === "business"
      ? "corporation"
      : "";
  const clientFullName = GenerateFullNameFromPartOfName({
    firstName: mortgagorObject?.firstName,
    middleName: mortgagorObject?.middleName,
    lastName: mortgagorObject?.lastName,
  });
  const currentLookUpArray = currentMortgagorData?.LLCLookUp || [];
  const finalLLCLookUpArray = [
    ...currentLookUpArray,
    {
      LLCTransactionId: LLCData?.transactionId,
      LLC_ID: mortgagorObject?.id,
    },
  ];
  return {
    ...currentMortgagorData,
    clientType: clientType || "",

    firstName: clientType === "person" ? mortgagorObject?.firstName || "" : "",
    lastName: clientType === "person" ? mortgagorObject?.lastName || "" : "",
    middleName: clientType === "person" ? mortgagorObject?.middleName || "" : "",
    name: clientType === "corporation" ? mortgagorObject?.companyName || "" : "",
    nameOnFile:
      clientType === "person"
        ? clientFullName || ""
        : clientType === "corporation"
        ? mortgagorObject?.companyName || ""
        : "",
    primaryPhoneNumber:
      clientType === "person"
        ? mortgagorObject?.phone || ""
        : clientType === "corporation"
        ? mortgagorObject?.businessPhone || ""
        : "",
    ...(clientType === "person" && {
      numberFields: [
        {
          numberFieldsAdditionalNumber: mortgagorObject?.businessPhone,
          numberFieldsAdditionalNumberExtension: "",
        },
      ],
    }),
    addressInfo: TransformLLCAddressObjectToLCSAddressObject({
      LLCAddressObject: mortgagorObject?.address,
    }),

    id: Math.random(),
    contactId: Math.random(),
    LLC: {
      LLCData: true,
      LLCTransactionId: LLCData?.transactionId,
      LLC_ID: mortgagorObject?.id,
      lenderCode: LLCData?.lenderCode || "",
    },
    LLCLookUp: finalLLCLookUpArray,
  };
}
