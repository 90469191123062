import React, { useState, useMemo } from "react";
import {
  FaEnvelope,
  FaMobileAlt,
  FaDownload,
  FaPaperPlane,
  FaRedoAlt,
  FaFileAlt,
  FaExclamationCircle,
  FaExclamationTriangle,
} from "react-icons/fa";
import { EntityEngagementProps, EntityWithReport } from "src/features/idv/models/entity.models";
import EntityEngagementUI from "./entity-engagement.ui";
import { trpc } from "src/shared/utils/trpc";
import { useSelector } from "react-redux";
import { UseSelectorState } from "src/features/idv/models/entity.models";

interface MutationMethodType<ParamType> {
  mutate: (
    params: ParamType,
    options?: {
      onSuccess: () => void;
    },
  ) => void;
}

const EntityEngagement = ({
  entity,
  Icon,
  lawFirmId,
  fileId,
  providerSelection,
}: EntityEngagementProps): JSX.Element => {
  const [showUpdateEntityModal, setShowUpdateEntityModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [requestWith, setRequestWith] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const lcsUser = useSelector((state: UseSelectorState) => state?.old?.lcsUser);
  const hasEmailAndPhone = entity.email && entity.phone;

  const entityRequest = useMemo(
    () => ({
      entityId: entity.id,
      email: entity.email,
      phone: entity.phone,
      requestWith,
    }),
    [entity, requestWith],
  );

  const initiatorRequest = useMemo(
    () => ({
      organization: "Lando-LCS",
      name: lcsUser.nameOnFile || lcsUser.firstName,
      email: lcsUser.email,
    }),
    [lcsUser],
  );

  const idVRequestParams = useMemo(
    () => ({
      lawFirmId,
      fileId,
      provider: providerSelection,
      entity: entityRequest,
      initiator: initiatorRequest,
    }),
    [lawFirmId, fileId, providerSelection, entityRequest, initiatorRequest],
  );

  const updateLinkParams = useMemo(
    () => ({
      lawFirmId,
      fileId,
      provider: providerSelection,
      idvId: entity.entityId,
      lcsEntityId: entity.id,
      requestWith: requestWith,
    }),
    [lawFirmId, fileId, providerSelection, entity, requestWith],
  );

  const fetchReportParams = useMemo(
    () => ({
      clientRequestId: entity.entityId,
    }),
    [entity],
  );

  const downloadReport = trpc.entityIdv.downloadReport.useMutation();
  const createIdVRequest = trpc.entityIdv.createIDVRequest.useMutation();
  const resendIDVLink = trpc.entityIdv.resendIDVLink.useMutation();
  const reinitiateIDVRequest = trpc.entityIdv.reinitiateIDVRequest.useMutation();

  const util = trpc.useContext();
  const invalidate = () => {
    util.entityIdv.findEntities.invalidate({ lawFirmId, fileId });
  };

  const handleMutation: <ParamType>(
    mutationMethod: MutationMethodType<ParamType>,
    params: ParamType,
    withValue: string,
  ) => void = (mutationMethod, params, withValue) => {
    setError(null);
    try {
      setLoading(true);
      setRequestWith(withValue);
      mutationMethod.mutate(params, {
        onSuccess: () => {
          invalidate();
        },
      });
    } catch (e) {
      e instanceof ErrorEvent
        ? setError(e.message)
        : setError("An unexpected error occured. Please try again");
    }
    setLoading(false);
  };

  const convertBase64File = (base64Data: string, fileName: string) => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: "application/pdf" });
    const blobUrl = URL.createObjectURL(blob);

    const virtualLink = document.createElement("a");
    virtualLink.href = blobUrl;
    virtualLink.download = fileName;
    virtualLink.click();
    URL.revokeObjectURL(blobUrl);
  };

  const DEFAULT_ITEMS = [
    {
      icon: <FaDownload className="mr-2" />,
      text: () => "Import the existing report",
      action: () => {
        console.log("hello there");
        invalidate();
      },
    },
    {
      icon: <FaEnvelope className="mr-2" />,
      text: (entity: EntityWithReport) => `Send to ${entity.email}`,
      action: () => handleMutation(createIdVRequest, idVRequestParams, "email"),
    },
    {
      icon: <FaMobileAlt className="mr-2" />,
      text: (entity: EntityWithReport) => `Send text to ${entity.phone}`,
      action: () => handleMutation(createIdVRequest, idVRequestParams, "phone"),
    },
  ];

  const PENDING_ITEMS = [
    {
      icon: <FaPaperPlane className="mr-2" />,
      text: () => "Re-send link via email",
      action: () => handleMutation(resendIDVLink, updateLinkParams, "email"),
    },
    {
      icon: <FaPaperPlane className="mr-2" />,
      text: () => "Re-send link via SMS",
      action: () => handleMutation(resendIDVLink, updateLinkParams, "phone"),
    },
    // Hopefully treefort will add this to their API soon
    // {
    //   icon: <FaRedoAlt className="mr-2" />,
    //   text: () => "Refresh Expiry time",
    //   action: () => {
    //     setLoading(true);
    //     invalidate();
    //     setLoading(false);
    //   },
    // },
  ];

  const EXPIRED_ITEMS = [
    {
      icon: <FaDownload className="mr-2" />,
      text: () => "Import the existing report",
      action: () => {
        console.log("hello there");
        invalidate();
      },
    },
    {
      icon: <FaRedoAlt className="mr-2" />,
      text: (entity: EntityWithReport) => `Re-initiate via ${entity.email}`,
      action: () => handleMutation(reinitiateIDVRequest, updateLinkParams, "email"),
    },
    {
      icon: <FaRedoAlt className="mr-2" />,
      text: (entity: EntityWithReport) => `Re-initiate via ${entity.phone}`,
      action: () => handleMutation(reinitiateIDVRequest, updateLinkParams, "phone"),
    },
  ];

  // eslint-disable-next-line
  const COMPLETED_ITEMS = [
    {
      icon: <FaFileAlt className="mr-2" />,
      text: () => "Buy report to unlock",
      action: () => {
        setShowPaymentModal(true);
      },
    },
    {
      icon: <FaRedoAlt className="mr-2" />,
      text: (entity: EntityWithReport) => `Re-initiate via ${entity.email}`,
      action: () => handleMutation(reinitiateIDVRequest, updateLinkParams, "email"),
    },
    {
      icon: <FaRedoAlt className="mr-2" />,
      text: (entity: EntityWithReport) => `Re-initiate via ${entity.phone}`,
      action: () => handleMutation(reinitiateIDVRequest, updateLinkParams, "phone"),
    },
  ];

  const PURCHASE_REPORT_ITEMS = [
    {
      icon: <FaFileAlt className="mr-2" />,
      text: () => "Download report",
      action: () => {
        downloadReport.mutate(fetchReportParams, {
          onSuccess: (base64Report) => {
            if (!base64Report) return;
            const formattedName = entity.name.replace(/ /g, "_");
            const downloadName = `${formattedName}_verification_report.pdf`;

            convertBase64File(base64Report, downloadName);
          },
        });
      },
    },
    {
      icon: <FaRedoAlt className="mr-2" />,
      text: (entity: EntityWithReport) => `Re-initiate via ${entity.email}`,
      action: () => {
        setLoading(true);
        setRequestWith("email");
        reinitiateIDVRequest.mutate(updateLinkParams, {
          onSuccess: () => {
            invalidate();
            setLoading(false);
          },
        });
      },
    },
    {
      icon: <FaRedoAlt className="mr-2" />,
      text: (entity: EntityWithReport) => `Re-initiate via ${entity.phone}`,
      action: () => {
        setLoading(true);
        setRequestWith("phone");
        reinitiateIDVRequest.mutate(updateLinkParams, {
          onSuccess: () => {
            invalidate();
            setLoading(false);
          },
        });
      },
    },
  ];

  const getDropdownItems = (entity: EntityWithReport) => {
    // Enable this for when we have the purchase functionality
    // if (entity.purchaseReport) return PURCHASE_REPORT_ITEMS;
    // Remove this when we have the purchase functionality v
    if (entity.reportStatus === "Complete") return PURCHASE_REPORT_ITEMS;

    switch (entity.reportStatus) {
      case "Pending":
        const endDate = new Date(
          entity.verificationLinks[entity.verificationLinks.length - 1].endDate,
        );
        const currentDate = new Date();
        const timeDifference = endDate.getTime() - currentDate.getTime();

        if (timeDifference <= 0) {
          return EXPIRED_ITEMS;
        } else {
          return PENDING_ITEMS;
        }
      //  Enable when we have the purchase functionality
      // case "Complete":
      //   return COMPLETED_ITEMS;
      default:
        return DEFAULT_ITEMS;
    }
  };

  const expiryDates = (entity: EntityWithReport): JSX.Element | null => {
    let statusText = "";
    let statusColor = "";

    switch (entity.reportStatus) {
      case "Pending":
        const endDate = new Date(
          entity.verificationLinks[entity.verificationLinks.length - 1].endDate,
        );
        const currentDate = new Date();
        const timeDifference = endDate.getTime() - currentDate.getTime();

        if (timeDifference <= 0) {
          statusText = "Link expired";
          statusColor = "text-danger";
        } else {
          const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
          const hoursDifference = Math.floor(
            (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
          );
          statusText = `Link sent - Expires in ${daysDifference}d ${hoursDifference}h`;
          statusColor = "text-secondary";
        }
        break;

      case "Complete":
        if (entity.reportExpiry) {
          statusText = `Report received - ${entity.reportExpiry.split("T")[0]}`;
          statusColor = "text-primary";
        } else {
          statusText = "Report received - Date unavailable";
          statusColor = "text-secondary";
        }
        break;

      default:
        return null;
    }

    // const shouldDisplayIcons = entity.reportStatus === "Complete" && entity.purchaseReport;
    const shouldDisplayIcons = entity.reportStatus === "Complete";

    return (
      <span className={`mr-2 small ${statusColor}`} style={{ textDecoration: "underline" }}>
        {shouldDisplayIcons && (
          <>
            {entity.reportResultStats === "Pass" ? (
              <FaExclamationCircle className="mr-1 text-success" />
            ) : (
              <FaExclamationTriangle className="mr-1 text-danger" />
            )}
          </>
        )}
        {statusText}
      </span>
    );
  };

  return (
    <EntityEngagementUI
      showUpdateEntityModal={showUpdateEntityModal}
      setShowUpdateEntityModal={setShowUpdateEntityModal}
      showPaymentModal={showPaymentModal}
      setShowPaymentModal={setShowPaymentModal}
      lawFirmId={lawFirmId}
      fileId={fileId}
      entity={entity}
      Icon={Icon}
      hasEmailAndPhone={hasEmailAndPhone}
      getDropdownItems={getDropdownItems}
      expiryDates={expiryDates}
      provider={providerSelection}
      loading={loading}
      setLoading={setLoading}
      error={error}
    />
  );
};

export default EntityEngagement;
