import { CCol, CFormGroup } from "@coreui/react";
import jp from "jsonpath";
import { useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useController, useFormContext } from "react-hook-form";
import { AiOutlineCloudUpload } from "react-icons/ai";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function FileUploadDropzone({
  name,
  required,
  accept = ".pdf, .doc, .docx, .png, .jpg, .jpeg",
  fileUploaded = false,
  acceptedTypes,
  outsideContainer = false
}) {
  const {
    control,
    formState: { errors, isSubmitted },
  } = useFormContext();
  const error = jp.value(errors, `$.${name}`);
  const {
    field: { onChange: setFileToUpload },
  } = useController({
    name,
    rules: { required },
    control,
    defaultValue: "",
  });
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    fileRejections,
  } = useDropzone({
    accept,
    maxFiles: 1,
    maxSize: 100000000,
    multiple: false,
  });
  const files = acceptedFiles.map((file) => <li key={file.path}>{file.path}</li>);
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  useEffect(() => {
    setFileToUpload(acceptedFiles[0] || "");
    fileUploaded && fileUploaded(acceptedFiles[0] || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);
  return (
    <div
      className={`${outsideContainer ? "" : "container"}`}
    >
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <CFormGroup row>
          <CCol md="6">
            <AiOutlineCloudUpload size={"150px"} />
          </CCol>
          <CCol md="6">
            <h2>
              <b>Drop or Select file</b>
            </h2>
            Drop files here or click to select a file
            <br />
            <b>{acceptedTypes ? acceptedTypes : ".docx or Word files only"}</b>
          </CCol>
        </CFormGroup>
      </div>
      {Array.isArray(files) && files?.length > 0 && (
        <CCol className={"mt-3"}>
          <h4>Files</h4>
          <ul>{files}</ul>
        </CCol>
      )}
      {/* <p>
        <strong>Document selected:</strong>
      </p>
      {fileToUpload ? (
        <CListGroup>
          <CListGroupItem key={Math.random()}>{fileToUpload.name}</CListGroupItem>
        </CListGroup>
      ) : null} */}
      {(errors.fileToUpload?.type === "required" || error?.type === "required") && isSubmitted && (
        <div className="display-invalid-feedback">You must select a file to upload</div>
      )}
      {fileRejections && fileRejections?.[0]?.errors?.[0]?.message && (
        <div className="display-invalid-feedback">
          <h5>Error: {fileRejections?.[0]?.errors?.[0]?.message}</h5>
        </div>
      )}
    </div>
  );
}

export default FileUploadDropzone;
