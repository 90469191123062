import classNames from "classnames";

import { useSelector } from "react-redux";
import { TheContent, TheFooter, TheHeaderNoSideBar } from "./index";

export default function TheLayoutWithoutSideBar() {
  const darkMode = useSelector((state) => state?.old?.darkMode);
  const classes = classNames("c-app c-default-layout", darkMode && "c-dark-theme");
  return (
    <div className={classes}>
      <div className="c-wrapper">
        <TheHeaderNoSideBar />
        {/* <TheAside /> */}
        <div className="c-body">
          <TheContent fluidContainer />
        </div>
        <TheFooter />
      </div>
    </div>
  );
}
