/* eslint-disable react/destructuring-assignment */
import _ from "lodash";

function HandleStreetNumber(address) {
  if (!_.isString(address)) return null;
  return address?.split(" ")?.[0];
}
function HandleStreetAddress(address) {
  if (!_.isString(address)) return null;
  const addressArray = address?.split(" ");
  addressArray.shift();
  return addressArray?.join(" ");
}
const TransformLCSAddressToLLCAddress = ({ address }) => {
  if (_.isEmpty(address)) return {};
  const LLCAddress = {
    streetNumber: HandleStreetNumber(address?.AddressLine1)?.trim() || null,
    streetAddress1: HandleStreetAddress(address?.AddressLine1)?.trim() || null,
    streetAddress2: address?.AddressLine2?.trim() || null,
    city: address?.City?.trim() || null,
    province: "ON",
    postalCode: address?.PostalCode?.replaceAll(" ", "") || null,
    country: address?.Country?.toLowerCase() === "canada" ? "CA" : address?.Country?.trim() || null,
    unitNumber: address?.UnitNumber?.trim() || null,
  };
  return LLCAddress;
};

export default TransformLCSAddressToLLCAddress;
