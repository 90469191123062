/* eslint-disable no-use-before-define */
/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */

import currency from "currency.js";
import HnHHandleDate from "src/reusable/HnHHandleDate";

/* eslint-disable no-unused-vars */
const HandleTitleInsurancePoliciesSection = ({ currentFile, LLCTransactionId }) => {
  let finalInsuranceArray = [];
  const typeOfFile = currentFile?.createFile?.fileType;
  const listOfNamesToUse =
    typeOfFile === "Purchase"
      ? currentFile?.purchaser?.listOfPurchasersFullNames || ""
      : typeOfFile === "Mortgage"
      ? currentFile?.mortgagor?.listOfMortgagorsFullNames || ""
      : "";
  const insuranceCompany = currentFile?.ChicagoAPIData
    ? "Chicago Title Insurance Canada"
    : currentFile?.StewartAPIData
    ? "Stewart Title Guaranty Company"
    : currentFile?.FCTData
    ? "FCT"
    : currentFile?.otherInsuranceAPIData
    ? "otherInsuranceCompany"
    : "";
  if (insuranceCompany === "Chicago Title Insurance Canada") {
    finalInsuranceArray = HandleChicagoInsurance({
      currentFile,
      insuranceCompany,
      listOfNamesToUse,
      LLCTransactionId,
    });
  } else if (insuranceCompany === "Stewart Title Guaranty Company") {
    finalInsuranceArray = HandleStewartInsurance({
      currentFile,
      insuranceCompany,
      listOfNamesToUse,
      LLCTransactionId,
    });
  } else if (insuranceCompany === "FCT") {
    finalInsuranceArray = HandleFCTInsurance({
      currentFile,
      insuranceCompany,
      listOfNamesToUse,
      LLCTransactionId,
    });
  } else if (insuranceCompany === "otherInsuranceCompany") {
    finalInsuranceArray = HandleOtherInsuranceCompanies({
      currentFile,
      insuranceCompany: currentFile?.otherInsuranceAPIData?.insuranceProvider,
      listOfNamesToUse,
      LLCTransactionId,
    });
  }
  return finalInsuranceArray;
};

function HandleChicagoInsurance({
  currentFile,
  insuranceCompany,
  listOfNamesToUse,
  LLCTransactionId,
}) {
  let finalInsuranceArray = [];
  const lenderPolicies = currentFile?.ChicagoAPIData?.FileDetails?.Policies?.LenderPolicies;
  const ownerPolicies = currentFile?.ChicagoAPIData?.FileDetails?.Policies?.OwnerPolicies;
  ownerPolicies?.forEach(
    (policy) =>
      (finalInsuranceArray = finalInsuranceArray.concat({
        insuranceCompany,
        policyType: "OWNER",
        policyNumber: policy?.PolicyNumber || "",
        policyDate: HnHHandleDate(currentFile?.createFile?.closingDate, "dashes") || "",
        nameOfInsured: listOfNamesToUse || "",
        insuredAmount: policy?.InsuredAmount || "",
      })),
  );
  lenderPolicies?.forEach((policy) => {
    let nameOfInsured = policy?.LenderName || "";
    let insuredAmount = policy?.InsuredAmount || "";
    let listOfMortgages = currentFile?.newMortgagesFile?.newMortgages;
    let mortgageRelatedToTransactionId = listOfMortgages?.find((e) => {
      return e?.LLC?.LLCTransactionId === LLCTransactionId;
    });
    if (mortgageRelatedToTransactionId?.priorityNumber === policy?.MortgagePriority)
      finalInsuranceArray = finalInsuranceArray.concat({
        insuranceCompany,
        policyType: "LENDER",
        policyNumber: policy?.PolicyNumber || "",
        policyDate: HnHHandleDate(currentFile?.createFile?.closingDate, "dashes") || "",
        nameOfInsured: nameOfInsured,
        insuredAmount: insuredAmount,
      });
  });

  return finalInsuranceArray;
}
function HandleOtherInsuranceCompanies({
  currentFile,
  insuranceCompany,
  listOfNamesToUse,
  LLCTransactionId,
}) {
  let finalInsuranceArray = [];
  const lenderPolicies = currentFile?.otherInsuranceAPIData?.lenderPolicies;
  const ownerPolicies = currentFile?.otherInsuranceAPIData?.ownerPolicy;

  ownerPolicies?.forEach(
    (policy) =>
      (finalInsuranceArray = finalInsuranceArray.concat({
        insuranceCompany,
        policyType: "OWNER",
        policyNumber: policy?.policyNumber || "",
        policyDate: HnHHandleDate(currentFile?.createFile?.closingDate, "dashes") || "",
        nameOfInsured: listOfNamesToUse || "",
        insuredAmount: policy?.insuredAmount || "",
      })),
  );
  lenderPolicies?.forEach((policy) => {
    if (LLCTransactionId === policy?.LLCTransactionId?.value)
      finalInsuranceArray = finalInsuranceArray.concat({
        insuranceCompany,
        policyType: "LENDER",
        policyNumber: policy?.policyNumber || "",
        policyDate: HnHHandleDate(currentFile?.createFile?.closingDate, "dashes") || "",
        nameOfInsured: policy?.nameOfInsured || "",
        insuredAmount: policy?.insuredAmount,
      });
  });

  return finalInsuranceArray;
}
function HandleStewartInsurance({
  currentFile,
  insuranceCompany,
  listOfNamesToUse,
  LLCTransactionId,
}) {
  let finalInsuranceArray = [];
  const lenderPolicies = currentFile?.StewartPolicies?.filter(
    (policy) => policy?.PolicyNumber?.[0]?.toLowerCase() === "m",
  );
  const ownerPolicies = currentFile?.StewartPolicies?.filter(
    (policy) => policy?.PolicyNumber?.[0]?.toLowerCase() === "o",
  );
  ownerPolicies?.forEach(
    (policy) =>
      (finalInsuranceArray = finalInsuranceArray.concat({
        insuranceCompany,
        policyType: "OWNER",
        policyNumber: policy?.PolicyNumber || "",
        policyDate: HnHHandleDate(currentFile?.createFile?.closingDate, "dashes") || "",
        nameOfInsured: listOfNamesToUse || "",
        insuredAmount: policy?.InsuredAmount || "",
      })),
  );
  lenderPolicies?.forEach((policy) => {
    const { LenderName, Amount } = getOwnerObject({ policy, currentFile });
    let listOfMortgages = currentFile?.newMortgagesFile?.newMortgages;
    let mortgageRelatedToTransactionId = listOfMortgages?.find((e) => {
      return e?.LLC?.LLCTransactionId === LLCTransactionId;
    });
    let lenderNameFromPolicyExistsInMortgageRelatedToTransactionIdFlag =
      mortgageRelatedToTransactionId?.newMortgageTable?.some((e) => e?.name === LenderName);
    let AmountFromPolicyMatchesMortgageRelatedToTransactionIdFlag =
      currency(mortgageRelatedToTransactionId?.principalAmount).value === Amount;

    if (
      AmountFromPolicyMatchesMortgageRelatedToTransactionIdFlag &&
      lenderNameFromPolicyExistsInMortgageRelatedToTransactionIdFlag
    )
      finalInsuranceArray = finalInsuranceArray.concat({
        insuranceCompany,
        policyType: "LENDER",
        policyNumber: policy?.PolicyNumber || "",
        policyDate: HnHHandleDate(currentFile?.createFile?.closingDate, "dashes") || "",
        nameOfInsured: Owner({ policy: policy, currentFile, listOfNamesToUse }) || "",
        insuredAmount: policy?.InsuredAmount || "",
      });
  });
  return finalInsuranceArray;
}
function HandleFCTInsurance({ currentFile, insuranceCompany, listOfNamesToUse, LLCTransactionId }) {
  let finalInsuranceArray = [];
  let policies = currentFile?.FCTData?.fctFileStatus?.policies;
  const lenderPolicies = policies?.filter((e) => e?.policyType?.toLowerCase() === "lender");
  const ownerPolicies = policies?.filter((e) => e?.policyType?.toLowerCase() === "owner");
  const orderDate = currentFile?.createFile?.closingDate;
  let listOfMortgages = currentFile?.newMortgagesFile?.newMortgages;
  ownerPolicies?.forEach(
    (policy) =>
      (finalInsuranceArray = finalInsuranceArray.concat({
        insuranceCompany,
        policyType: "OWNER",
        policyNumber: policy?.policyNumber || "",
        policyDate: HnHHandleDate(orderDate, "dashes") || "",
        nameOfInsured: listOfNamesToUse || "",
        insuredAmount: policy?.insuredAmount || "",
      })),
  );
  lenderPolicies?.forEach((policy) => {
    let mortgageRelatedToThisPolicy = listOfMortgages?.find(
      (e) => e.loanNumber === policy?.mortgageNumber,
    );
    let llcIdOfMortgageRelatedToThisPolicy = mortgageRelatedToThisPolicy?.LLC?.LLCTransactionId;

    if (LLCTransactionId === llcIdOfMortgageRelatedToThisPolicy)
      finalInsuranceArray = finalInsuranceArray.concat({
        insuranceCompany,
        policyType: "LENDER",
        policyNumber: policy?.policyNumber || "",
        policyDate: HnHHandleDate(orderDate, "dashes") || "",
        nameOfInsured: policy?.lenderName || "",
        insuredAmount: policy?.insuredAmount || "",
      });
  });

  return finalInsuranceArray;
}
function Owner({ policy, currentFile, listOfNamesToUse }) {
  let owner = "";
  const StewartReqData = currentFile?.StewartReqData;
  const MortgageUniqueId = policy?.MortgageUniqueId;
  const PropertyUniqueId = policy?.PropertyUniqueId;
  if (MortgageUniqueId && StewartReqData?.Mortgages && StewartReqData?.Mortgages.length > 0) {
    const mortgageObj = StewartReqData.Mortgages.find(
      (mortgage) => mortgage.UniqueId.toUpperCase() === MortgageUniqueId,
    );
    owner = mortgageObj?.LenderName;
  }

  if (PropertyUniqueId) {
    owner = listOfNamesToUse || "";
  }
  return owner;
}
function getOwnerObject({ policy, currentFile }) {
  let ownerObject = "";
  const StewartReqData = currentFile?.StewartReqData;
  const MortgageUniqueId = policy?.MortgageUniqueId;
  if (MortgageUniqueId && StewartReqData?.Mortgages && StewartReqData?.Mortgages.length > 0) {
    const mortgageObj = StewartReqData.Mortgages.find(
      (mortgage) => mortgage.UniqueId.toUpperCase() === MortgageUniqueId,
    );
    ownerObject = mortgageObj;
  }
  return ownerObject;
}
export default HandleTitleInsurancePoliciesSection;
