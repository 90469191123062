/* eslint-disable prefer-const */
import GenerateArrayOfLLCAttorneyObjectsFromLCSEstateEntity from "./GenerateArrayOfLLCAttorneyObjectsFromLCSEstateEntity";
import GenerateArrayOfLLCAttorneyObjectsFromLCSPowerOfAttorneyEntity from "./GenerateArrayOfLLCAttorneyObjectsFromLCSPowerOfAttorneyEntity";
import GenerateListOfGuarantorsCorrespondingToLLCTransactionId from "./GenerateListOfGuarantorsCorrespondingToLLCTransactionId";
import GenerateListOfMortgagorsCorrespondingToLLCTransactionId from "./GenerateListOfMortgagorsCorrespondingToLLCTransactionId";

/* eslint-disable no-unused-vars */
const HandleMappingOfAttorneysSection = ({ currentFile, LLCTransactionId, LLCTransactionType }) => {
  let listOfEntities = [];
  let attorneyArray = [];
  listOfEntities = listOfEntities.concat(
    GenerateListOfMortgagorsCorrespondingToLLCTransactionId({
      LLCTransactionId,
      LLCTransactionType,
      currentFile,
    }) || [],
  );
  listOfEntities = listOfEntities.concat(
    GenerateListOfGuarantorsCorrespondingToLLCTransactionId({
      currentFile,
      LLCTransactionId,
    }) || [],
  );

  let listOfEntitiesThatHaveAttorneys =
    listOfEntities?.filter(
      (e) =>
        e.clientType === "personPowerOfAttorney" &&
        Array.isArray(e?.attorney) &&
        e?.attorney?.length > 0,
    ) || [];

  let listOfEntitiesThatHaveTrustees =
    listOfEntities?.filter(
      (e) =>
        e.clientType === "estate" &&
        Array.isArray(e?.estateTrustee) &&
        e?.estateTrustee?.length > 0,
    ) || [];
  listOfEntitiesThatHaveAttorneys?.forEach((entity) => {
    attorneyArray = attorneyArray.concat(
      GenerateArrayOfLLCAttorneyObjectsFromLCSPowerOfAttorneyEntity({ entity, LLCTransactionId }),
    );
  });
  listOfEntitiesThatHaveTrustees?.forEach((entity) => {
    attorneyArray = attorneyArray.concat(
      GenerateArrayOfLLCAttorneyObjectsFromLCSEstateEntity({ entity, LLCTransactionId }),
    );
  });
  return attorneyArray;
};

export default HandleMappingOfAttorneysSection;
