const getMortgagorId = ({ mortgagor, LLCTransactionId }) => {
  let mortgagorId = "";

  if (mortgagor?.LLCLookUp) {
    mortgagorId = mortgagor?.LLCLookUp?.find(
      (e) => e?.LLCTransactionId === LLCTransactionId,
    )?.LLC_ID;
  } else {
    mortgagorId = mortgagor?.LLC?.LLC_ID;
  }
  return mortgagorId;
};

export default getMortgagorId;
