// TODO: implement this entity fully
import { createApi } from "@reduxjs/toolkit/query/react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "src/new-folder-structure/shared/config/firebase";
import FirebaseFirestoreBaseQuery from "src/new-folder-structure/shared/lib/redux-base-query/redux-firestore-base-query";

// TODO: add file entity adapter

export const lawFirmInitialState = {};

export const lawFirmAPISlice = createApi({
  reducerPath: "lawFirmAPI",
  baseQuery: FirebaseFirestoreBaseQuery(),
  endpoints: (builder) => ({
    getLawFirm: builder.query({
      query: ({ lawFirmID }) => ({
        providesTags: ["file"],
        path: `lawFirm/${lawFirmID}`,
        method: "getDoc",
        isDocument: true,
      }),
      async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;
          const docReference = doc(db, `lawFirm/${arg.lawFirmID}`);
          const unsub = onSnapshot(docReference, (doc) => {
            updateCachedData(() => ({ id: doc.id, ...doc.data() }));
          });
          // cacheEntryRemoved will resolve when the cache subscription is no longer active
          await cacheEntryRemoved;
          // perform cleanup steps once the `cacheEntryRemoved` promise resolves
          unsub();
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
      },
    }),
    updateLawFirm: builder.query({
      mutation: ({ lawFirmID, lawFirmData }) => ({
        path: `lawFirm/${lawFirmID}`,
        method: "updateDoc",
        isDocument: true,
        data: lawFirmData,
      }),
    }),
  }),
});

export const { useGetLCSFileQuery } = lawFirmAPISlice;

export const selectLawFirm = (state) => {
  const lawFirmID = state?.old?.lcsUser?.lawFirmID;
  const apiResultSelector = lawFirmAPISlice.endpoints.getLCSFile.select({
    lawFirmID,
  });
  const apiResult = apiResultSelector(state);
  return apiResult?.data ?? {};
};

export const selectLawFirmID = (state) => state?.old?.lcsUser?.lawFirmID;
