const HandleTransactionIds = ({ LLCData, currentFile }) => {
  let finalTransactionIdsArray = currentFile?.LLCData || [];
  if (
    !currentFile?.LLCData?.some((e) => {
      return e?.LLCTransactionId === LLCData?.transactionId;
    })
  ) {
    finalTransactionIdsArray = finalTransactionIdsArray.concat({
      LLCTransactionId: LLCData?.transactionId,
      isMMS: LLCData?.workflowModelId?.toLowerCase() === "mms",
      sourceFctUrn: LLCData?.sourceFctUrn,
    });
  }
  return finalTransactionIdsArray;
};

export default HandleTransactionIds;
