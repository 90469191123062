import _ from "lodash";
import HnHHandleDate from "src/reusable/HnHHandleDate";

const HandleMultipleDateFields = ({ LLCData, nameOfFirstDateField, nameOfSecondDateField }) => {
  let finalDateValue = "";
  if (!_.isEmpty(LLCData?.[nameOfFirstDateField])) {
    finalDateValue = HnHHandleDate(LLCData?.[nameOfFirstDateField]) || "";
  } else if (!_.isEmpty(LLCData?.[nameOfSecondDateField]?.textEn)) {
    finalDateValue = HnHHandleDate(LLCData?.[nameOfSecondDateField]?.textEn) || "";
  }
  return finalDateValue;
};

export default HandleMultipleDateFields;
