import { connectAuthEmulator } from "firebase/auth";
import { connectFirestoreEmulator } from "firebase/firestore";
import { connectFunctionsEmulator } from "firebase/functions";
import {
  AnalyticsProvider,
  AuthProvider,
  FirebaseAppProvider,
  FirestoreProvider,
  FunctionsProvider,
  StorageProvider,
} from "reactfire";
import {
  analytics,
  auth,
  db,
  firebaseApp,
  functions,
  storage,
} from "src/new-folder-structure/shared/config/firebase";

// TODO: refactor by removing the need for hooks and replace with appropriate component in entities folder
export default function WithFirebaseHooks(component) {
  return function Wrapper() {
    // keeping this code here in case later we want to keep everything in one place
    // const firebaseApp = initializeApp(firebaseConfig);
    // const db = getFirestore(firebaseApp);
    // const auth = getAuth(firebaseApp);
    // const functions = getFunctions(firebaseApp, process.env.REACT_APP_FIREBASE_FUNCTIONS_REGION); // "northamerica-northeast1"
    // const storage = getStorage(firebaseApp);
    // const analytics = getAnalytics(firebaseApp);

    if (process.env.NODE_ENV === "development") {
      // ATTENTION: with file purchase, you can't run only functions emulator, it needs firestore emulated in order to work for stripe integration
      // ALTERNATIVE: turn off functions emulator
      if (process.env.REACT_APP_FIRESTORE_EMULATOR === "true") {
        // eslint-disable-next-line no-console
        console.log("Emulating firebase firestore");
        connectFirestoreEmulator(db, "localhost", 8080);
      }
      if (process.env.REACT_APP_AUTH_EMULATOR === "true") {
        // eslint-disable-next-line no-console
        console.log("Emulating firebase Auth");
        connectAuthEmulator(auth, "http://localhost:9099");
      }
      if (process.env.REACT_APP_FUNCTIONS_EMULATOR === "true") {
        // eslint-disable-next-line no-console
        console.log("Enabling functions emulator!");
        connectFunctionsEmulator(functions, "localhost", 5001);
      }
    }

    return (
      <FirebaseAppProvider firebaseApp={firebaseApp}>
        <AuthProvider sdk={auth}>
          <FirestoreProvider sdk={db}>
            <AnalyticsProvider sdk={analytics}>
              <StorageProvider sdk={storage}>
                <FunctionsProvider sdk={functions}>{component()}</FunctionsProvider>
              </StorageProvider>
            </AnalyticsProvider>
          </FirestoreProvider>
        </AuthProvider>
      </FirebaseAppProvider>
    );
  };
}
