/* eslint-disable no-unused-vars */
const getTeranetDescription = ({ data: teranetAPIData, asArray }) => {
  let description = "";
  let descriptionArray = [];

  if (teranetAPIData && Object.keys(teranetAPIData)?.length > 0) {
    Object.keys(teranetAPIData)?.forEach((item) => {
      if (teranetAPIData[item] && teranetAPIData[item]?.length > 0) {
        teranetAPIData[item]?.forEach((subItem) => {
          if (subItem?.PIN === item) {
            description += `${subItem?.description?.propertyDescription || ""}. `;
            descriptionArray = descriptionArray?.concat(
              subItem?.description?.propertyDescription || "",
            );
          }
        });
      }
    });
  }
  if (asArray) return descriptionArray;
  else return description?.trim();
};
const getManualDescription = ({ data: manualTitleSearches, asArray }) => {
  let description = "";
  let descriptionArray = [];
  if (
    manualTitleSearches &&
    Array.isArray(manualTitleSearches) &&
    manualTitleSearches?.length > 0
  ) {
    manualTitleSearches?.forEach((entry) => {
      description += `${entry?.propertyDescription || ""} `;
      descriptionArray = descriptionArray?.concat(entry?.propertyDescription || "");
    });
  }
  description = description?.trim();
  description = description?.slice(0, 3996);
  if (asArray) return descriptionArray;
  else return description;
};
const HandleLegalDescription = ({ currentFile, asArray }) => {
  const performingTitleSearch = currentFile?.fileOpen?.performingTitleSearch;
  let legalDescription = "";
  if (performingTitleSearch === "yes") {
    legalDescription = getTeranetDescription({
      data: currentFile?.TeranetAPIData,
      asArray: asArray,
    });
  } else {
    legalDescription = getManualDescription({
      data: currentFile?.ManualTitleSearches?.titleSearch,
      asArray: asArray,
    });
  }
  return legalDescription;
};

export default HandleLegalDescription;
