import React from "react";
import { CFooter, CImg } from "@coreui/react";
import { useSelector } from "react-redux";

function TheFooter() {
  const darkMode = useSelector((state) => state?.old?.darkMode);

  return (
    <CFooter fixed={false}>
      <div>
        <span>
          <CImg
            src={darkMode ? "LCS-logo-footer-white.svg" : "LCS-logo-footer-black.svg"}
            alt="lawyerConveyance"
            height={35}
          />
        </span>
      </div>
      <div>
        <span className="ml-4 font-xl">
          Lawyer Conveyance System&trade; - Report bugs or give feedback at{" "}
        </span>
        <span className="font-xl " style={{ color: darkMode ? "white" : "blue" }}>
          <a href="mailto:support@lawyerconveyance.ca">support@lawyerconveyance.ca</a>
        </span>
      </div>
      <div className="mfs-auto">
        <span className="mr-1">
          v{process.env.REACT_APP_VERSION}+{process.env.NODE_ENV}_build
        </span>
      </div>
    </CFooter>
  );
}

export default React.memo(TheFooter);
