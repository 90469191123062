import React from "react";
import { CCol, CRow, CButton, CAlert } from "@coreui/react";
import { FaSyncAlt } from "react-icons/fa";
import { IdVFlowController } from "src/features/idv/models/entity.models";

import IdVProviderList from "src/features/idv/components/idv-provider-selection/idv-provider-list";
import EntityList from "../entity-list/entity-list";

const IdVFlowControllerUI = ({
  providerSelection,
  setProviderSelection,
  refreshHandler,
  isLoading,
  isError,
  isSuccess,
}: IdVFlowController) => {
  return (
    <CCol>
      <br />
      <CCol>
        <CRow className="d-flex justify-content-between align-items-center">
          <h2 className="font-weight-bold">ID Verification</h2>
          {providerSelection && (
            <CButton
              type="button"
              className={`d-flex justify-content-center align-items-center ${
                isLoading ? "bg-secondary" : "bg-primary"
              } refresh-button`}
              style={{ height: "2.5rem", width: "2.5rem" }}
              onClick={refreshHandler}
            >
              <FaSyncAlt className="text-white" />
            </CButton>
          )}
        </CRow>
      </CCol>
      {isError && !isSuccess && (
        <CAlert color="danger" className="mt-2 text-center">
          Something went wrong. Please try again
        </CAlert>
      )}
      {!providerSelection ? (
        <IdVProviderList setProviderSelection={setProviderSelection} />
      ) : (
        <EntityList
          setProviderSelection={setProviderSelection}
          providerSelection={providerSelection}
        />
      )}
    </CCol>
  );
};

export default IdVFlowControllerUI;
