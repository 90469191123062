import compose from "compose-function";

import withErrorBoundary from "./with-error-boundary";
import withFirebaseHooks from "./with-firebase-hook";
import WithReactQuery from "./with-react-query";
import withRouter from "./with-router";
import withStore from "./with-store";
import WithStripe from "./with-stripe";
import WithTRPC from "./with-trpc";

export default compose(
  withErrorBoundary,
  withRouter,
  withStore,
  withFirebaseHooks,
  WithStripe,
  WithTRPC,
  WithReactQuery,
);
