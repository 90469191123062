import currency from "currency.js";

const HandleMappingOfPrincipalAmount = ({
  LLCData,
  principalAmountPassedByTheUser,
  currentLLCMortgageOfThisTransactionId,
}) => {
  if (currency(currentLLCMortgageOfThisTransactionId?.principalAmount).value > 0) {
    return currentLLCMortgageOfThisTransactionId?.principalAmount;
  }
  let finalPrincipalAmount = currency(LLCData?.mortgage?.registeredAmount)?.value;
  if (finalPrincipalAmount > 0) {
    return finalPrincipalAmount;
  } else if (principalAmountPassedByTheUser > 0) {
    return principalAmountPassedByTheUser;
  } else return "";
};
// LLCData?.mortgage?.registeredAmount ? LLCData?.mortgage?.registeredAmount : ""
export default HandleMappingOfPrincipalAmount;
