import { Controller, useFormContext } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import jp from "jsonpath";
import { CCol, CFormGroup, CLabel } from "@coreui/react";
import { useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";

const HnHReactCreatableReactSelectWithLabel = ({
  label,
  name,
  options,
  required,
  errorMessage,
  isMulti,
  modal,
  labelColumns,
  fieldColumns,
  manualFormGroup,
  onCreateOption,
  underlyingText,
  ...rest
}) => {
  const methods = useFormContext();
  const {
    control,
    formState: { errors },
  } = methods;
  const error = jp.value(errors, `$.${name}`);
  const darkMode = useSelector((state) => state?.old?.darkMode);
  const _labelColumns = labelColumns ? labelColumns : "3";
  const _fieldColumns = fieldColumns ? fieldColumns : "9";
  return (
    <>
      {manualFormGroup ? (
        <>
          {label && <CCol md={_labelColumns}>
            <CLabel>{label}</CLabel>
          </CCol>}
          <CCol md={_fieldColumns}>
            <>
              {darkMode ? (
                <Controller
                  name={name}
                  control={control}
                  rules={{ required: required }}
                  defaultValue={[]}
                  render={({ field }) => (
                    <CreatableSelect
                      {...field}
                      className={`${isMulti ? "basic-multi-select" : ""} ${
                        error ? `"is-invalid red-border` : ``
                      }`}
                      isMulti={isMulti ? true : false}
                      classNamePrefix="select"
                      options={options}
                      onCreateOption={onCreateOption}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary: modal ? "grey" : "white", // the selected option background inside the dropdown
                          primary25: modal ? "silver" : "grey", // Highlighted option background

                          neutral0: modal ? "white" : "#34343b", // sets background color for the outside select bar, and the background of the dropdown
                          neutral10: modal ? "silver" : "grey", // MULTI: The color of the boxes of the selected options outside the dropdown
                          neutral80: modal ? "black" : "white", // Selected text color OUTSIDE the dropdown
                        },
                      })}
                      {...rest}
                    />
                  )}
                />
              ) : (
                <Controller
                  name={name}
                  control={control}
                  rules={{ required: required }}
                  defaultValue={[]}
                  render={({ field }) => (
                    <CreatableSelect
                      {...field}
                      className={`${isMulti ? "basic-multi-select" : ""} ${
                        error ? `"is-invalid red-border` : ``
                      }`}
                      isMulti={isMulti ? true : false}
                      classNamePrefix="select"
                      options={options}
                      onCreateOption={onCreateOption}
                      {...rest}
                    />
                  )}
                />
              )}
            </>
            {underlyingText ? <span style={{ color: "grey" }}>{underlyingText}</span> : ""}
            {error && (
              <span className="display-invalid-feedback">
                <b>{errorMessage ? `${errorMessage}` : `${label} is required`}</b>
              </span>
            )}
          </CCol>
        </>
      ) : (
        <>
          <CFormGroup row>
            {label &&<CCol md={_labelColumns}>
              <CLabel>{label}</CLabel>
            </CCol>}
            <CCol md={_fieldColumns}>
              <>
                {darkMode ? (
                  <Controller
                    name={name}
                    control={control}
                    rules={{ required: required }}
                    defaultValue={[]}
                    render={({ field }) => (
                      <CreatableSelect
                        {...field}
                        className={`${isMulti ? "basic-multi-select" : ""} ${
                          error ? `"is-invalid red-border` : ``
                        }`}
                        isMulti={isMulti ? true : false}
                        classNamePrefix="select"
                        options={options}
                        onCreateOption={onCreateOption}
                        {...rest}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary: modal ? "grey" : "white", // the selected option background inside the dropdown
                            primary25: modal ? "silver" : "grey", // Highlighted option background

                            neutral0: modal ? "white" : "#34343b", // sets background color for the outside select bar, and the background of the dropdown
                            neutral10: modal ? "silver" : "grey", // MULTI: The color of the boxes of the selected options outside the dropdown
                            neutral80: modal ? "black" : "white", // Selected text color OUTSIDE the dropdown
                          },
                        })}
                      />
                    )}
                  />
                ) : (
                  <Controller
                    name={name}
                    control={control}
                    rules={{ required: required }}
                    defaultValue={[]}
                    render={({ field }) => (
                      <CreatableSelect
                        {...field}
                        className={`${isMulti ? "basic-multi-select" : ""} ${
                          error ? `"is-invalid red-border` : ``
                        }`}
                        isMulti={isMulti ? true : false}
                        classNamePrefix="select"
                        options={options}
                        onCreateOption={onCreateOption}
                        {...rest}
                      />
                    )}
                  />
                )}
              </>
              {underlyingText ? <span style={{ color: "grey" }}>{underlyingText}</span> : ""}
              {error && (
                <span className="display-invalid-feedback">
                  <b>{errorMessage ? `${errorMessage}` : `${label} is required`}</b>
                </span>
              )}
            </CCol>
          </CFormGroup>
        </>
      )}
    </>
  );
};

export default HnHReactCreatableReactSelectWithLabel;
