import { createApi } from "@reduxjs/toolkit/dist/query/react";
import FirebaseFirestoreBaseQuery from "src/new-folder-structure/shared/lib/redux-base-query/redux-firestore-base-query";
import { deleteField } from "firebase/firestore";

export const FctOrderAPISlice = createApi({
  reducerPath: "FctOrderAPISlice",
  baseQuery: FirebaseFirestoreBaseQuery(),
  endpoints: (builder) => ({
    resetFctOrder: builder.mutation({
      query: ({ lawFirmID, fileID }) => ({
        path: `lawFirm/${lawFirmID}/files/${fileID}`,
        method: "updateDoc",
        isDocument: true,
        data: {
          FCTData: deleteField(),
          otherInsuranceAPIData: deleteField(),
        },
      }),
    }),
  }),
});

export const { useResetFctOrderMutation } = FctOrderAPISlice;
