// eslint-disable-next-line no-unused-vars
const HandleIncorporationSection = ({ mortgagor }) => {
  return {
    registrationNumber: mortgagor?.hstNumber || null,
    entityType: mortgagor?.corporationIsBuilder === "yes" ? "Builder" : "Corporation",
    recordType: mortgagor?.signingOfficer?.[0]?.idVerification?.firstIdType?.value || "",
    electronicRecordSource:
      mortgagor?.signingOfficer?.[0]?.idVerification?.firstIdPlaceOfIssue || "",
  };
};

export default HandleIncorporationSection;
