import { Provider } from "react-redux";

// I don't like that we pass store here but whatever
import store from "../store";

export default function WithStore(component) {
  return function Wrapper() {
    return <Provider store={store}>{component()}</Provider>;
  };
}
