// ================================================================
// Entity Adapters (to be used with selectors later)
// This can be thought of as our entity objects where the entity
// can be represented by multiple objects. Each adapter can manages
// the entity object similar to a database table. This means that 
// the selectors generated from it can retrieve object by ID 
// ================================================================

import { createEntityAdapter } from "@reduxjs/toolkit";

// TODO: maybe refactor this into its own file called "entity"
export const LLCOffersAdapter = createEntityAdapter({
  selectId: (x) => x.transactionId,
});
export const LLCOffersInitialState = LLCOffersAdapter.getInitialState([]);

// this is not considered an entity object but part of the offer model, kind of like subentity
export const lenderCodesAdapter = createEntityAdapter({
  selectId: (x) => x.lenderCode,
});
export const lenderCodesInitialState = lenderCodesAdapter.getInitialState([]);
