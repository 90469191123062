import GenerateFullAddress from "src/reusable/GenerateFullAddress";
import HnHHandleDate from "src/reusable/HnHHandleDate";

const HandleMappingOfCreateFileSection = ({
  nameOnFile,
  fileNumber,
  LLCData,
  typeOfCurrentFile,
  currentFile,
  closingDate,
}) => {
  const addressLine1 =
    `${LLCData?.properties?.[0]?.address?.streetNumber || ""} ${
      LLCData?.properties?.[0]?.address?.streetAddress1 || ""
    }` || "";
  const addressLine2 = LLCData?.properties?.[0]?.address?.streetAddress2 || "";
  const city = LLCData?.properties?.[0]?.address?.city || "";
  const country = LLCData?.properties?.[0]?.address?.country || "";
  const postalCode = LLCData?.properties?.[0]?.address?.postalCode || "";
  const province = LLCData?.properties?.[0]?.address?.province || "";
  const unitNumber = LLCData?.properties?.[0]?.address?.unitNumber || "";

  const poBox = currentFile?.createFile?.filePOBox || "";

  const fileFullAddress =
    GenerateFullAddress({
      addressLine1,
      addressLine2,
      city,
      province,
      country,
      postalCode,
      pobox: "",
    }) || "";

  const createFileData = {
    closingDate:
      closingDate &&
      (LLCData?.mortgage?.closingDate === "" || LLCData?.mortgage?.closingDate === null)
        ? HnHHandleDate(closingDate)
        : HnHHandleDate(LLCData?.mortgage?.closingDate),
    dateFileCreated: currentFile?.createFile?.dateFileCreated
      ? HnHHandleDate(currentFile?.createFile?.dateFileCreated)
      : HnHHandleDate(new Date()) || "",
    fileType: currentFile?.createFile?.fileType
      ? currentFile?.createFile?.fileType
      : typeOfCurrentFile || "",
    nameOfFile: currentFile?.createFile?.nameOfFile
      ? currentFile?.createFile?.nameOfFile
      : nameOnFile || "",
    fileNumber: currentFile?.createFile?.fileNumber
      ? currentFile?.createFile?.fileNumber
      : fileNumber || "",
    status: "Pending",

    fileAddressLine1: addressLine1,
    fileAddressLine2: addressLine2,
    fileCity: city,
    fileCountry: country,
    filePostalCode: postalCode,
    fileProvince: province,
    filePOBox: poBox,
    fileUnitNumber: unitNumber,
    fileFullAddress,
  };
  return createFileData;
};

export default HandleMappingOfCreateFileSection;
