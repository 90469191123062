import { useController, useFormContext } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import jp from "jsonpath";

import { CButton, CButtonToolbar, CCol, CFormGroup, CLabel, CTooltip } from "@coreui/react";
import { useSelector } from "react-redux";

function HnHToggleWithLabel({
  label,
  name,
  buttons,
  required,
  errorMessage,
  defaultValue,
  modal,
  disabled,
  selectedBtnColor = "primary",
}) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    field: { value: buttonSelected, onChange: setButtonSelected },
  } = useController({
    name,
    control,
    defaultValue: defaultValue || "",
    rules: { required: required || false, shouldUnregister: true },
  });
  const error = jp.value(errors, `$.${name}`);
  const darkMode = useSelector((state) => state?.old?.darkMode);
  return (
    <CFormGroup row>
      <CCol md="3">
        <CLabel>{label}</CLabel>
      </CCol>
      <CCol md="9">
        <>
          <CButtonToolbar className="is-invalid">
            {buttons.map((aButton) => {
              return (
                <div key={aButton.value}>
                  {aButton.toolTip ? (
                    <CTooltip content={`${aButton.toolTip}`} placement="top">
                      <CButton
                        className={`${error ? "border border-danger" : modal ? "toggle-inside-modal" : ""
                          }`}
                        value={aButton.value}
                        onClick={() => {
                          setButtonSelected(aButton.value);
                        }}
                        color={buttonSelected === aButton.value ? `${selectedBtnColor}` : ""}
                        disabled={disabled === true}
                      >
                        {modal && buttonSelected === aButton.value ? (
                          <span style={{ color: "white" }}>{aButton.name}</span>
                        ) : modal && buttonSelected !== aButton.value ? (
                          <span style={{ color: "black" }}>{aButton.name}</span>
                        ) : darkMode ? (
                          <span style={{ color: "white" }}>{aButton.name}</span>
                        ) : (
                          <span style={{ color: "" }}>{aButton.name}</span>
                        )}
                      </CButton>
                    </CTooltip>
                  ) : (
                    <CButton
                      className={`${error ? "border border-danger" : modal ? "toggle-inside-modal" : ""
                        }`}
                      name={aButton.name}
                      value={aButton.value}
                      onClick={() => {
                        setButtonSelected(aButton.value);
                      }}
                      color={buttonSelected === aButton.value ? `${selectedBtnColor}` : ""}
                      disabled={disabled === true}
                    >
                      {modal && buttonSelected === aButton.value ? (
                        <span style={{ color: "white" }}>{aButton.name}</span>
                      ) : modal && buttonSelected !== aButton.value ? (
                        <span style={{ color: "black" }}>{aButton.name}</span>
                      ) : darkMode ? (
                        <span style={{ color: "white" }}>{aButton.name}</span>
                      ) : (
                        <span style={{ color: "" }}>{aButton.name}</span>
                      )}
                    </CButton>
                  )}
                </div>
              );
            })}
          </CButtonToolbar>
          {error && (
            <div className="invalid-feedback">{errorMessage || "This field is required"}</div>
          )}
        </>
      </CCol>
    </CFormGroup>
  );
}

export default HnHToggleWithLabel;
