const HandleCreatingSolicitorObject = ({ lawFirm, users, LLCData, currentFile }) => {
  if (!lawFirm || !users || !LLCData) return {};
  const LLCLawyerUserName = LLCData?.lawyer?.userName;

  const currentFileOffice = lawFirm?.offices?.find(
    (office) => currentFile?.createFile?.office?.value === office.id,
  );
  if (!LLCLawyerUserName) return {};

  const LCSUserMatchingLLCUserName = users?.find(
    (e) => e?.LLCUserName?.toLowerCase() === LLCLawyerUserName?.toLowerCase(),
  );

  return {
    LLCUserName: LCSUserMatchingLLCUserName?.LLCUserName || "",
    firstName: LCSUserMatchingLLCUserName?.firstName || "",
    middleName: LCSUserMatchingLLCUserName?.middleName || "",
    lastName: LCSUserMatchingLLCUserName?.lastName || "",
    initials: LCSUserMatchingLLCUserName?.initials || "",
    email: LCSUserMatchingLLCUserName?.email || "",
    phoneNumberExtension: LCSUserMatchingLLCUserName?.phoneNumberExtension || "",
    id: LCSUserMatchingLLCUserName?.id || "",
    lawFirm: lawFirm?.name || "",
    phoneNumber: currentFileOffice?.phoneNumber || "",
    faxNumber: currentFileOffice?.faxNumber || "",
    addressInfo: {
      AddressLine1: currentFileOffice?.AddressLine1 || "",
      AddressLine2: currentFileOffice?.AddressLine2 || "",
      City: currentFileOffice?.City || "",
      PostalCode: currentFileOffice?.PostalCode || "",
      Province: currentFileOffice?.Province || "",
      Country: "Canada",
    },
  };
};

export default HandleCreatingSolicitorObject;
