// TODO: implement this entity fully
import { createApi } from "@reduxjs/toolkit/query/react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "src/new-folder-structure/shared/config/firebase";
import FirebaseFirestoreBaseQuery from "src/new-folder-structure/shared/lib/redux-base-query/redux-firestore-base-query";

// TODO: add file entity adapter

export const UsersInitialState = {};

export const usersAPISlice = createApi({
  reducerPath: "usersAPI",
  baseQuery: FirebaseFirestoreBaseQuery(),
  tagTypes: ["Users"],
  endpoints: (builder) => ({
    getUsersByLawFirmId: builder.query({
      query: ({ lawFirmID }) => ({
        path: `lawFirm/${lawFirmID}/users`,
        method: "getDocs",
        isCollection: true,
      }),
      providesTags: ["Users"],
      async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;
          const collectionRef = collection(db, `lawFirm/${arg.lawFirmID}/users`);

          const unsub = onSnapshot(collectionRef, (querySnapshot) =>
            updateCachedData(() =>
              querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
            ),
          );
          // cacheEntryRemoved will resolve when the cache subscription is no longer active
          await cacheEntryRemoved;
          // perform cleanup steps once the `cacheEntryRemoved` promise resolves
          unsub();
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
      },
    }),
  }),
});

export const selectUsers = (state) => {
  const lawFirmID = state?.old?.lcsUser?.lawFirmID;
  const apiResultSelector = usersAPISlice.endpoints.getUsersByLawFirmId.select({
    lawFirmID,
  });
  const apiResult = apiResultSelector(state);
  return apiResult?.data ?? {};
};
