import React from "react";
import { CCol, CForm, CFormGroup, CLabel } from "@coreui/react";
import { FormProvider } from "react-hook-form";
import DateInput from "src/reusable/DateInput";
import HnHToggleWithoutLabel from "src/reusable/HnHToggleWithoutLabel";
import SidebarContainer from "src/shared/ui/right-sidebar/container-sidebar-shared-ui";

interface ChecklistBodyUiProps {
  fileType?: string;
  methods: any;
  saveData: () => void;
}

const ChecklistBodyUi = ({ fileType, methods, saveData }: ChecklistBodyUiProps): JSX.Element => {
  const { handleSubmit } = methods;

  const renderToggleOptionWithDate = (label: string, toggleName: string, dateName: string) => (
    <>
      <CFormGroup>
        <CCol md="12">
          <CLabel>{label}</CLabel>
        </CCol>
        <CCol md="12">
          <HnHToggleWithoutLabel
            name={toggleName}
            buttons={[
              { name: "Yes", value: "yes" },
              { name: "No", value: "no" },
            ]}
          />
        </CCol>
      </CFormGroup>
      {((methods.getValues(toggleName) === "yes" && toggleName !== "fileStillActive") ||
        (methods.getValues(toggleName) === "no" && toggleName === "fileStillActive")) && (
        <CFormGroup>
          <CCol md="12">
            <CLabel>Date done</CLabel>
          </CCol>
          <CCol md="12">
            <DateInput name={dateName} />
          </CCol>
        </CFormGroup>
      )}
      <hr />
    </>
  );

  return (
    <SidebarContainer>
      <br />
      <h2>File Closing Checklist</h2>
      <br />
      <FormProvider {...methods}>
        <CForm
          className="form-horizontal needs-validation"
          onSubmit={handleSubmit(saveData)}
          noValidate
        >
          {renderToggleOptionWithDate("Deal closed?", "dealClosed", "dealClosedDate")}
          {fileType === "Sale" ? (
            <>
              {renderToggleOptionWithDate(
                "Change of Ownership sent for Tax Dept?",
                "changeOfOwnership",
                "changeOfOwnershipDate",
              )}
              {renderToggleOptionWithDate(
                "Title Insurance Policy Paid?",
                "titleInsurancePolicy",
                "titleInsurancePolicyDate",
              )}
              {renderToggleOptionWithDate(
                "Lender Report Completed?",
                "lenderReport",
                "lenderReportDate",
              )}
            </>
          ) : null}
          {renderToggleOptionWithDate(
            "Client Report Completed?",
            "clientReport",
            "clientReportDate",
          )}
          {renderToggleOptionWithDate(
            "Undertakings Fulfilled?",
            "undertakings",
            "undertakingsDate",
          )}
          {renderToggleOptionWithDate("Is the file active?", "fileStillActive", "fileInactiveDate")}
        </CForm>
      </FormProvider>
    </SidebarContainer>
  );
};

export default ChecklistBodyUi;
