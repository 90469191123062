import { lazy } from "react";
import {Route, Switch} from "react-router-dom";
import TemplatedRoute from "src/containers/TemplatedRoute";
import AuthRoute from "../../app/auth-route";


// const SplashScreenPage = lazy(() => import("./splash-screen"));
const LoginPage = lazy(() => import("src/new-folder-structure/pages/login"));
// TODO: refactor these pages
const Register = lazy(() => import("../../views/pages/register/Register"));
const Page404 = lazy(() => import("../../views/pages/page404/Page404"));
const Page500 = lazy(() => import("../../views/pages/page500/Page500"));


export function Routing() {
  return (
    <Switch>
      {/* <Route
      exact
      path="/splash"
      name="Splash Screen"
      render={(props) => <SplashScreen {...props} />}
    /> */}

      <Route exact path="/login" name="Login Page" render={(props) => <LoginPage {...props} />} />
      <Route
        exact
        path="/register"
        name="Register Page"
        render={(props) => <Register {...props} />}
      />
      <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
      <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
      <AuthRoute>
        <TemplatedRoute />
      </AuthRoute>
    </Switch>
  );
}
