import countOccurenceInString from "./countOccurenceInString";

const EnsureCurlyBracketsAreClosedForDocumentTemplateName = (
  documentNameToCheck
) => {
  let allBracketsClosed = true;
  if (documentNameToCheck.includes("{{")) {
    allBracketsClosed = false;
    let numberOfOpeningBrackets = countOccurenceInString(
      documentNameToCheck,
      "{{"
    );
    let numberOfClosingBrackets = countOccurenceInString(
      documentNameToCheck,
      "}}"
    );
    if (numberOfOpeningBrackets === numberOfClosingBrackets)
      allBracketsClosed = true;
  }
  return allBracketsClosed;
};

export default EnsureCurlyBracketsAreClosedForDocumentTemplateName;
