import React from "react";
import { CButton, CCol, CRow } from "@coreui/react";
import { FaUser } from "react-icons/fa";
import SidebarContainer from "src/shared/ui/right-sidebar/container-sidebar-shared-ui";
import { EntityListUIProps } from "src/features/idv/models/entity.models";
import EntityEngagement from "src/features/idv/components/entity-engagement/entity-engagement";
import { Spinner } from "react-bootstrap";

const EntityListUI = ({
  entities,
  toggleFileStatus,
  fileStatusClosed,
  providerSelection,
  setProviderSelection,
  lawFirmId,
  fileId,
  entityTypeIcons,
  entityListPopulated,
}: EntityListUIProps) => {
  return (
    <SidebarContainer>
      <CCol>
        <CRow className="d-flex justify-content-between align-items-center">
          <h2>Purchasers</h2>
          <span
            className={`mr-2 ${!fileStatusClosed ? "text-primary" : "text-secondary"}`}
            onClick={toggleFileStatus}
            style={{
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            {providerSelection} File is {fileStatusClosed ? "Closed" : "Open"}
          </span>
        </CRow>
      </CCol>
      <br />

      {entityListPopulated ? (
        entities.length === 0 ? (
          <CCol className="mb-4">
            <CRow>No current purchasers.</CRow>
          </CCol>
        ) : (
          entities.map((entity) => {
            const IconComponent = entity.type ? (
              entityTypeIcons[entity.type]
            ) : (
              <FaUser className="mr-2" />
            );
            return (
              <EntityEngagement
                key={entity.lcsEntityId}
                entity={entity}
                Icon={IconComponent}
                lawFirmId={lawFirmId}
                fileId={fileId}
                providerSelection={providerSelection}
              />
            );
          })
        )
      ) : (
        <div className="d-flex justify-content-center h-4 mb-4">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      <CCol>
        <CButton
          className="btn btn-lg btn-block"
          color="secondary"
          onClick={() => setProviderSelection(null)}
        >
          Back
        </CButton>
      </CCol>
    </SidebarContainer>
  );
};

export default EntityListUI;
