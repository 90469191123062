import React from "react";
import {
  CAlert,
  CButton,
  CButtonGroup,
  CForm,
  CInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { FormProvider } from "react-hook-form";
import { Controller } from "react-hook-form";
import { UpdateEntityInfoModalPropsUI } from "../../models/entity.models";

const ModalUpdateEntityInfoUI = ({
  showModal,
  entityName,
  entityEmail,
  entityPhone,
  methods,
  onSubmit,
  closeModal,
  showError,
}: UpdateEntityInfoModalPropsUI) => {
  const { control, formState } = methods;
  const { errors } = formState;

  return (
    <FormProvider {...methods}>
      <CForm onSubmit={onSubmit}>
        <CModal
          show={showModal}
          onClose={closeModal}
          closeOnBackdrop={false}
          size="lg"
          centered
          backdrop={true}
        >
          <CModalHeader className="bg-primary">
            <CModalTitle>Add required information for {entityName}</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <Controller
                name="email"
                control={control}
                defaultValue={entityEmail || ""}
                render={({ field }) => <CInput type="email" id="email" {...field} />}
              />
              {errors.email && (
                <CAlert className="mt-2" color="danger">
                  {errors.email.message}
                </CAlert>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">
                Phone
              </label>
              <Controller
                name="phone"
                control={control}
                defaultValue={entityPhone || ""}
                render={({ field }) => <CInput type="tel" id="phone" {...field} />}
              />
              {errors.phone && (
                <CAlert className="mt-2" color="danger">
                  {errors.phone.message}
                </CAlert>
              )}
            </div>
          </CModalBody>
          {showError && (
            <CAlert className="mt-2" color="danger">
              {showError}
            </CAlert>
          )}
          <CModalFooter>
            <CButtonGroup>
              <CButton type="submit" color="primary">
                Save
              </CButton>
              <CButton color="secondary" onClick={closeModal}>
                Cancel
              </CButton>
            </CButtonGroup>
          </CModalFooter>
        </CModal>
      </CForm>
    </FormProvider>
  );
};

export default ModalUpdateEntityInfoUI;
